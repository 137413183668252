const getPromoActive = () => {
	const date = new Date().getTime();
	return date > new Date('2023-05-15T00:00:00').getTime() && date < new Date('2023-06-20T00:00:00').getTime();
};
const getPromoBonoActive = () => {
	const date = new Date().getTime();
	return date > new Date('2023-08-01T00:00:00').getTime() && date < new Date('2023-09-01T00:00:00').getTime();
};

export { getPromoActive, getPromoBonoActive };
