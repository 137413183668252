import { createHash } from 'crypto';

declare const dataLayer;
declare const gtag;

function dataLayerPush(event: any): void {
	try {
		dataLayer.push(event);
	} catch (err) {
		console.log('err', err);
	}
}

export function pageViewEvent(page_title: string, page_location: string): void {
	dataLayerPush({
		event: 'page_view',
		page_title,
		page_location,
	});
}

export function pageEvent(flow: string, location: string, action: string) {
	dataLayerPush({
		event: 'user_interaction',
		flow,
		location,
		action,
	});
}
export function pageEventFunnelStep(funnel_name: string, product_name: string, funnel_step_name: string) {
	dataLayerPush({
		event: 'funnel_step_viewed',
		product_name,
		funnel_step_name,
	});
}
export function pageEventDimensionPlate(value: string) {
	dataLayerPush({
		event: 'dimension',
		plate: encodeData(value),
	});
}
export function pageEventDimensionUserId(value: string) {
	dataLayerPush({
		userId: encodeData(value),
	});
}

export function encodeData(value: string) {
	return createHash('sha256').update(value, 'utf8').digest('hex');
}
