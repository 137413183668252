import React, { useState } from 'react';
import uploadIcon from 'images/svg/upload.svg';
import './Input-file.scss';

const InputFile = (props: any) => {
	const [nameFile, setNameFile] = useState('');
	const onFileChange = (e) => {
		const file = e.target.files[0];
		if (!file) return;
		props.setFileURLs(e.target.files);
		setNameFile(e.target.files[0].name);
	};
	return (
		<div className={`input__file__container ${props.errorlabel ? 'error' : ''}`}>
			<label htmlFor="input-upload">
				{nameFile ? nameFile : 'Carnet de vacunación'}
				<img className="input__file__icon" src={uploadIcon} alt="subir archivo" />
				<input type="file" id="input-upload" onChange={onFileChange} />
			</label>
			{props.errorlabel ? <div className="textfield-error-label">{props.errorlabel}</div> : null}
		</div>
	);
};

export default InputFile;
