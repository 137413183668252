import React, { useContext, useEffect } from 'react';
import './services.scss';

import ServiceSlide from 'components/services/slide/slide';
import ServiceItems from 'components/services/items/items';
import ServiceNuestraGarantia from 'components/services/nuestra-garantia/nuestra-garantia';
import ServiceProductos from 'components/services/productos/productos';
import ServiceNuestrosClientes from 'components/services/nuestros-clientes/nuestros-clientes';
import ServiceSection3 from 'components/services/section-3/section-3';
import img_auto from 'images/services/service-seguridad.png';
import { AppContext } from 'helpers/Context';
import { getFunnelUrl } from 'helpers/FunnelRedirection';
import { Helmet } from 'react-helmet';
export default function Services() {
	const title1 = 'Connect te asiste donde y cuando quieras';

	const nuestrosClienteJson = {};
	const { branch, identify, setIdentifyStatus } = useContext(AppContext);
	const linkTo = getFunnelUrl();

	useEffect(() => {
		if (!identify) {
			
			setIdentifyStatus(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="App">
			<Helmet>
				<title> {title1} - Connect Assistance</title>
				<meta
					name="description"
					content="Connect Assistance es la compañía #1 en Asistencia en Carretera y en el Hogar en Puerto Rico, Panamá, Costa Rica y Colombia"
				/>
				<meta
					property="og:description"
					content="Connect Assistance es la compañía #1 en Asistencia en Carretera y en el Hogar en Puerto Rico, Panamá, Costa Rica y Colombia"
				/>
			</Helmet>
			<div className="App-header">
				<ServiceSlide title={title1} />
				<ServiceItems branch={branch} linkTo={linkTo} />

				<div className="mobile">
					<img className="icon full-width" src={img_auto} alt="" />
				</div>
				<ServiceSection3 />

				<ServiceNuestraGarantia />
				<ServiceNuestrosClientes template='primary' data={nuestrosClienteJson} />
				<ServiceProductos branch={branch} linkTo={linkTo} />
			</div>
		</div>
	);
}
