import React from 'react';
import './hero-products.scss';
import AutoImg from 'images/services/service-auto.png';
import MascotaImg from 'images/services/service-mascota.png';
import AutoImgMobile from 'images/services/service-auto-mobile.png';
import MascotaImgMobile from 'images/services/service-mascota-mobile.png';
interface Props {
	type: 'auto' | 'mascota';
}

const ServicesHeroProducts: React.FC<Props> = ({ type }) => {
	return (
		<div className={`service-hero-products-container`}>
			<picture className="service-hero-products-image">
				<source className='img' srcSet={type === 'auto' ? AutoImg : MascotaImg} media="(min-width: 400px)" />
				<img className='img' src={type === 'auto' ? AutoImgMobile : MascotaImgMobile} alt="MDN" />
			</picture>
			<div className={`service-hero-products-content`}></div>
		</div>
	);
};

export default ServicesHeroProducts;
