import React, { useContext, useEffect, useState } from 'react';
import './perfil.scss';

import { AppContext } from 'helpers/Context';
import LoadingDialog from 'helpers/LoadingDialog/loadingDialog';
import FunnelPerfilVehiculo from 'views/funnel/perfil-vehiculo/perfil-vehiculo';
import FunnelPerfilHogar from 'views/funnel/perfil-hogar/perfil-hogar';
import { GlobalContext } from 'context/GlobalState';
import { useHistory } from 'react-router-dom';
import { buildPerfilArray } from 'helpers/Peril';
import { getSubscription } from 'services/SubscriptionService';
import { useParams } from 'react-router';
import { pageEvent, pageEventDimensionPlate } from 'helpers/dataLaye';
import FunnelPerfiMascota from '../perfil-mascota/perfil-mascota';

export default function FunnelPerfil() {
	const history = useHistory();
	const { setShowStatus, branch } = useContext(AppContext);
	const [isLoading, setIsLoading] = useState(false);
	const { funnel, setFunnel } = useContext(GlobalContext);
	const [component, setComponent] = useState(funnel.perfilData);
	const [perfilCount, setPerfilCount] = useState(0);
	const [nextScreen, setNextScreen] = useState(1);
	const [countAuto, setCountAuto] = useState(1);
	const [countHogar, setCountHogar] = useState(1);
	const [countMotora, setCountMotora] = useState(1);
	const [countPet, setCountPet] = useState(1);
	const [chevron, setChevron] = useState(true);
	const [messagePayment, setMessagePayment] = useState(true);

	let sub = useParams();

	const getSub = async (id: string) => {
		const { data } = await getSubscription(id);
		return data.data;
	};

	useEffect(() => {
		getSub(sub['id']).then((data) => {
			funnel.subscriptionData.amount = data['amount'];
			funnel.subscriptionData.mongoId = sub['id'];
			funnel.step1.cobertura.auto = data['autoSelected'];
			funnel.step1.cobertura.hogar = data['hogarSelected'];
			funnel.step1.cobertura.pet = data['petsSelected'];
			funnel.step1.productType = data['autoSelected'] ? 'autos' : 'mascotas';
			funnel.perfilData = buildPerfilArray(
				{
					auto: data['autoSelected'],
					hogar: data['hogarSelected'],
					pet: data['petsSelected'],
				},
				branch
			);
			//AUTO
			funnel.subscriptionData.autoSelected = data['autoSelected'];
			funnel.subscriptionData.autoColor1 = data['autoColor1'];
			funnel.subscriptionData.autoColor2 = data['autoColor2'];
			funnel.subscriptionData.autoColor3 = data['autoColor3'];
			funnel.subscriptionData.autoColor4 = data['autoColor4'];
			funnel.subscriptionData.autoColor5 = data['autoColor5'];
			funnel.subscriptionData.autoMake1 = data['autoMake1'];
			funnel.subscriptionData.autoMake2 = data['autoMake2'];
			funnel.subscriptionData.autoMake3 = data['autoMake3'];
			funnel.subscriptionData.autoMake4 = data['autoMake4'];
			funnel.subscriptionData.autoMake5 = data['autoMake5'];
			funnel.subscriptionData.autoModel1 = data['autoModel1'];
			funnel.subscriptionData.autoModel2 = data['autoModel2'];
			funnel.subscriptionData.autoModel3 = data['autoModel3'];
			funnel.subscriptionData.autoModel4 = data['autoModel4'];
			funnel.subscriptionData.autoModel5 = data['autoModel5'];
			funnel.subscriptionData.autoPlate1 = data['autoPlate1'];
			funnel.subscriptionData.autoPlate2 = data['autoPlate2'];
			funnel.subscriptionData.autoPlate3 = data['autoPlate3'];
			funnel.subscriptionData.autoPlate4 = data['autoPlate4'];
			funnel.subscriptionData.autoPlate5 = data['autoPlate5'];
			funnel.subscriptionData.autoYear1 = data['autoYear1'];
			funnel.subscriptionData.autoYear2 = data['autoYear2'];
			funnel.subscriptionData.autoYear3 = data['autoYear3'];
			funnel.subscriptionData.autoYear4 = data['autoYear4'];
			funnel.subscriptionData.autoYear5 = data['autoYear5'];
			//MOTORA
			funnel.subscriptionData.motoraSelected = data['motoraSelected'];
			funnel.subscriptionData.motoraColor1 = data['motoraColor1'];
			funnel.subscriptionData.motoraColor2 = data['motoraColor2'];
			funnel.subscriptionData.motoraColor3 = data['motoraColor3'];
			funnel.subscriptionData.motoraColor4 = data['motoraColor4'];
			funnel.subscriptionData.motoraColor5 = data['motoraColor5'];
			funnel.subscriptionData.motoraMake1 = data['motoraMake1'];
			funnel.subscriptionData.motoraMake2 = data['motoraMake2'];
			funnel.subscriptionData.motoraMake3 = data['motoraMake3'];
			funnel.subscriptionData.motoraMake4 = data['motoraMake4'];
			funnel.subscriptionData.motoraMake5 = data['motoraMake5'];
			funnel.subscriptionData.motoraModel1 = data['motoraModel1'];
			funnel.subscriptionData.motoraModel2 = data['motoraModel2'];
			funnel.subscriptionData.motoraModel3 = data['motoraModel3'];
			funnel.subscriptionData.motoraModel4 = data['motoraModel4'];
			funnel.subscriptionData.motoraModel5 = data['motoraModel5'];
			funnel.subscriptionData.motoraYear1 = data['motoraYear1'];
			funnel.subscriptionData.motoraYear2 = data['motoraYear2'];
			funnel.subscriptionData.motoraYear3 = data['motoraYear3'];
			funnel.subscriptionData.motoraYear4 = data['motoraYear4'];
			funnel.subscriptionData.motoraYear5 = data['motoraYear5'];
			funnel.subscriptionData.motoraPlate1 = data['motoraPlate1'];
			funnel.subscriptionData.motoraPlate2 = data['motoraPlate2'];
			funnel.subscriptionData.motoraPlate3 = data['motoraPlate3'];
			funnel.subscriptionData.motoraPlate4 = data['motoraPlate4'];
			funnel.subscriptionData.motoraPlate5 = data['motoraPlate5'];
			//HOGAR
			funnel.subscriptionData.hogarSelected = data['hogarSelected'];
			funnel.subscriptionData.homeAddress11 = data['homeAddress11'];
			funnel.subscriptionData.homeAddress12 = data['homeAddress12'];
			funnel.subscriptionData.homeAddress13 = data['homeAddress13'];
			funnel.subscriptionData.homeAddress14 = data['homeAddress14'];
			funnel.subscriptionData.homeAddress15 = data['homeAddress15'];
			funnel.subscriptionData.homeBanos1 = data['homeBanos1'];
			funnel.subscriptionData.homeBanos2 = data['homeBanos2'];
			funnel.subscriptionData.homeBanos3 = data['homeBanos3'];
			funnel.subscriptionData.homeBanos4 = data['homeBanos4'];
			funnel.subscriptionData.homeBanos5 = data['homeBanos5'];
			funnel.subscriptionData.homeCity1 = data['homeCity1'];
			funnel.subscriptionData.homeCity2 = data['homeCity2'];
			funnel.subscriptionData.homeCity3 = data['homeCity3'];
			funnel.subscriptionData.homeCity4 = data['homeCity4'];
			funnel.subscriptionData.homeCity5 = data['homeCity5'];
			funnel.subscriptionData.homeCountry1 = data['homeCountry1'];
			funnel.subscriptionData.homeCountry2 = data['homeCountry2'];
			funnel.subscriptionData.homeCountry3 = data['homeCountry3'];
			funnel.subscriptionData.homeCountry4 = data['homeCountry4'];
			funnel.subscriptionData.homeCountry5 = data['homeCountry5'];
			funnel.subscriptionData.homeRooms1 = data['homeRooms1'];
			funnel.subscriptionData.homeRooms2 = data['homeRooms2'];
			funnel.subscriptionData.homeRooms3 = data['homeRooms3'];
			funnel.subscriptionData.homeRooms4 = data['homeRooms4'];
			funnel.subscriptionData.homeRooms5 = data['homeRooms5'];
			funnel.subscriptionData.homeType1 = data['homeType1'];
			funnel.subscriptionData.homeType2 = data['homeType2'];
			funnel.subscriptionData.homeType3 = data['homeType3'];
			funnel.subscriptionData.homeType4 = data['homeType4'];
			funnel.subscriptionData.homeType5 = data['homeType5'];
			funnel.subscriptionData.homeZipCode1 = data['homeZipCode1'];
			funnel.subscriptionData.homeZipCode2 = data['homeZipCode2'];
			funnel.subscriptionData.homeZipCode3 = data['homeZipCode3'];
			funnel.subscriptionData.homeZipCode4 = data['homeZipCode4'];
			funnel.subscriptionData.homeZipCode5 = data['homeZipCode5'];
			funnel.subscriptionData.petsSelected = data['petsSelected'];
			funnel.subscriptionData.petName1 = data['petName1'];
			funnel.subscriptionData.petBreed1 = data['petBreed1'];
			funnel.subscriptionData.petWeight1 = data['petWeight1'];
			funnel.subscriptionData.petAge1 = data['petAge1'];
			funnel.subscriptionData.petVaccinationCard1 = data['petVaccinationCard1'];
			funnel.subscriptionData.petName2 = data['petName2'];
			funnel.subscriptionData.petBreed2 = data['petBreed2'];
			funnel.subscriptionData.petWeight2 = data['petWeight2'];
			funnel.subscriptionData.petAge2 = data['petAge2'];
			funnel.subscriptionData.petVaccinationCard2 = data['petVaccinationCard2'];
			funnel.subscriptionData.petName3 = data['petName3'];
			funnel.subscriptionData.petBreed3 = data['petBreed3'];
			funnel.subscriptionData.petWeight3 = data['petWeight3'];
			funnel.subscriptionData.petAge3 = data['petAge3'];
			funnel.subscriptionData.petVaccinationCard3 = data['petVaccinationCard3'];
			funnel.subscriptionData.petName4 = data['petName4'];
			funnel.subscriptionData.petBreed4 = data['petBreed4'];
			funnel.subscriptionData.petWeight4 = data['petWeight4'];
			funnel.subscriptionData.petAge4 = data['petAge4'];
			funnel.subscriptionData.petVaccinationCard4 = data['petVaccinationCard4'];
			funnel.subscriptionData.petName5 = data['petName5'];
			funnel.subscriptionData.petBreed5 = data['petBreed5'];
			funnel.subscriptionData.petWeight5 = data['petWeight5'];
			funnel.subscriptionData.petAge5 = data['petAge5'];
			funnel.subscriptionData.petVaccinationCard5 = data['petVaccinationCard5'];
			setComponent(funnel.perfilData);
			if (parseInt(funnel.subscriptionData.autoSelected) <= 0 && parseInt(funnel.subscriptionData.hogarSelected) > 0) {
				setCountHogar(countHogar + 1);
				setChevron(true);
			} else {
				setChevron(true);
			}

			setPerfilCount(funnel.perfilData.length);
			if (perfilCount === 1) {
				setMessagePayment(true);
			}
		});

		// For test only
		// funnel.perfilData = buildPerfilArray({ auto: 0, hogar: 1, motora: 0 });

		setFunnel(funnel);
		setShowStatus(false);
		return () => {
			setShowStatus(true);
		};
		// eslint-disable-next-line
	}, [setShowStatus]);

	useEffect(() => {
		setShowStatus(false);
		return () => {
			setShowStatus(true);
		};
	}, [setShowStatus, component]);

	const finishBtn = (data: any, props: any) => {
		const currentKey = props.key;
		// Verificamos si el key es igual a la cantidad del array
		// para terminar el proceso y pasar a Finalizar
		if (currentKey === perfilCount) {
			history.push('/finalizar');
			return;
		}
		component.map((el) => (el.display = false));
		const nextScreen = +currentKey + 1;
		setNextScreen(nextScreen);
		const findNext: any = component.find((item) => item.key === nextScreen);
		findNext.display = true;
		setMessagePayment(false);
		if (findNext.section === 'auto') {
			setCountAuto(countAuto + 1);
		}
		if (findNext.section === 'home') {
			setCountHogar(countHogar + 1);
		}
		if (findNext.section === 'motora') {
			setCountMotora(countMotora + 1);
		}
		if (findNext.section === 'pet') {
			setCountPet(countPet + 1);
		}

		if (findNext.display) {
			setChevron(true);
		} else {
			setChevron(true);
		}

		setComponent([...component]);
	};

	const buildScreens = (data: any) => {
		const btnLabel = component.length === nextScreen ? 'Finalizar' : 'Continuar';
		if (data.display && data.section === 'auto') {
			return (
				<FunnelPerfilVehiculo
					key={data.key}
					position={data.key}
					isLoading={setIsLoading}
					h3={data.h3}
					title={data.title}
					component={data}
					handleClick={finishBtn}
					totalScreen={component.length}
					countVehiculo={countAuto}
					currentScreen={nextScreen}
					chevron={chevron}
					btnLabel={btnLabel}
					messagePayment={messagePayment}
				/>
			);
		} else if (data.display && data.section === 'pet') {
			return (
				<FunnelPerfiMascota
					key={data.key}
					position={data.key}
					isLoading={setIsLoading}
					h3={data.h3}
					title={'Agrega tu mascota'}
					component={data}
					handleClick={finishBtn}
					countPet={countPet}
					totalScreen={component.length}
					currentScreen={nextScreen}
					chevron={chevron}
					btnLabel={btnLabel}
					messagePayment={messagePayment}
				/>
			);
		} else if (data.display && data.section === 'home') {
			return (
				<FunnelPerfilHogar
					key={data.key}
					position={data.key}
					isLoading={setIsLoading}
					h3={data.h3}
					title={data.title}
					component={data}
					handleClick={finishBtn}
					countHogar={countHogar}
					totalScreen={component.length}
					currentScreen={nextScreen}
					chevron={chevron}
					btnLabel={btnLabel}
					messagePayment={messagePayment}
				/>
			);
		} else {
			return <div></div>;
		}
	};

	return (
		<div className="App">
			<LoadingDialog isLoading={isLoading}></LoadingDialog>
			<div className="App-header">
				<div>
					{component.length > 0 &&
						component.map(function (d: any, index: number) {
							return <div key={'build' + index}>{buildScreens(d)}</div>;
						})}
				</div>
			</div>
		</div>
	);
}
