import React, { useState, useContext, useEffect } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';

import './productos-shared.scss';

import ServicesHeroProducts from 'components/services/hero-products/hero-products';
import Container from 'components/container/container';
import ServiceNuestrosClientes from 'components/services/nuestros-clientes/nuestros-clientes';

import AutoData from 'data/auto.json';
import Mascota from 'data/mascota.json';
import AutoImg from 'images/services/service-auto.png';
import MascotaImg from 'images/services/service-mascota.jpg';
import pago from 'images/svg/pago.svg';
import ServiceSubscribete from 'components/services/subscribete/subscribete';
import useHideOnScrolled from 'helpers/UseHideScroll';
import { AppContext } from 'helpers/Context';
import { getFunnelUrl } from 'helpers/FunnelRedirection';
import { Helmet } from 'react-helmet';
import Pricing from 'helpers/Pricing';
import DetailPlan from 'components/detail-plan/detail-plan';
import { pageEvent, pageViewEvent } from 'helpers/dataLaye';
import { ByConnect } from 'components/ByConnect/ByConnect';
import { GlobalContext } from 'context/GlobalState';
import { FunnelData } from 'interfaces/FunnelInterface';
import { PopupExistProcess } from 'components/Popup-exist-porcess/Popup-exist-porcess';
import CarIcon from 'images/home/servicios/car-icon.svg';
import mascota from 'images/home/servicios/mascota.svg';
import Toaster from 'helpers/Toaster';
import { getPromoActive } from 'helpers/PromoActived';

export default function ProductosShared() {
	const { branch, identify, setIdentifyStatus } = useContext(AppContext);
	const { funnel, setFunnel } = useContext(GlobalContext);
	const [funnelData, setFunnelData] = useState<FunnelData>(funnel);
	const [showPopup, setShowPopup] = useState<boolean>(false);
	const [total, setTotal] = useState<number>(0);
	const [planSelected, setPlanSelected] = useState<string>('');
	const [count, setCount] = useState(0);
	let params: { section: 'auto' | 'mascota' } = useParams();
	const { search } = useLocation();
	const history = useHistory();
	const paramsUrl = new URLSearchParams(search);

	useEffect(() => {
		if (params.section === 'auto') {
			pageViewEvent('Asistencias Carro', 'https://planesasistencia.connect.com.co/carro/visita');
		} else {
			pageViewEvent('Asistencias Mascotas', 'https://planesasistencia.connect.com.co/mascota/visita');
		}
		setPlan(params.section);
	}, [params]);
	useEffect(() => {
		if (!identify) {
			setIdentifyStatus(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		if (getPromoActive()) {
			setTimeout(() => {
				handlePropertyChange('plus');
				setShowPopup(true);
			}, 30000);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	let data: any = {};
	let heroImg = '';
	let pricing = 0;
	const linkTo = getFunnelUrl();
	const dataCountPricing = {
		auto: 1,
		pet: 1,
		home: 1,
		pago: 'mensual',
		branch: branch,
	};
	const setPlan = (productType: string) => {
		funnelData.step1.plan = productType === 'auto' ? '1-0-0-A-co-fa' : '0-1-0-A-co-fa';
		funnelData.step1.productType = params.section + 's';
		setFunnelData(funnelData);
		setFunnel(funnelData);
	};
	const allPricing = Pricing.calculate(dataCountPricing);
	switch (params.section) {
		case 'auto':
			data = AutoData;
			heroImg = AutoImg;
			pricing = allPricing.autoMensualPrice;
			break;

		case 'mascota':
			data = Mascota;
			heroImg = MascotaImg;
			pricing = allPricing.petMensualPrice;
			break;
	}
	const nuestrosClienteJson = {};

	const hidden = useHideOnScrolled();
	const closePopUp = () => {
		setShowPopup(false);
	};
	const calculateWithoutHook = async (
		vehicle: number,
		pet: number,
		home: number,
		anualMensual: string,
		branch: string
	) => {
		const data = {
			auto: vehicle,
			pet: pet,
			home: home,
			pago: anualMensual,
			branch: branch,
		};
		return Pricing.calculate(data);
	};
	const calculateFunnel = async (vehicle: number, pet: number, home: number, montlyAnual: string, tmpTotal: any) => {
		const planSelected = params.section === 'auto' ? `${vehicle}-0-0-A-co-fa` : `0-${vehicle}-0-A-co-fa`;
		setPlanSelected(planSelected);
		setTotal(params.section === 'auto' ? tmpTotal.autoAnualPrice : tmpTotal.petAnualPrice);
	};
	const handlePropertyChange = async (type: string) => {
		if (params.section == 'auto') { 
			pageEvent('asistencias-carro', 'paso-plan', `vehiculos`);
		} else {
			pageEvent('asistencias-mascota', 'paso-plan', `mascotas`);

		}
		switch (type) {
			case 'plus':
				if (count < 3) {
					setCount(count + 1);
					const tmpTotal =
						params.section == 'auto'
							? await calculateWithoutHook(count + 1, 0, 0, pago, branch)
							: await calculateWithoutHook(0, count + 1, 0, pago, branch);
					await calculateFunnel(count + 1, 0, 0, pago, tmpTotal);
				}
				break;
			case 'minus':
				if (count > 0) {
					setCount(count - 1);
					const tmpTotal =
						params.section == 'auto'
							? await calculateWithoutHook(count - 1, 0, 0, pago, branch)
							: await calculateWithoutHook(0, count - 1, 0, pago, branch);
					await calculateFunnel(count - 1, 0, 0, pago, tmpTotal);
				}
				break;
		}
	};

	const goToCheckout = () => {
		if (count === 0) {
			Toaster.error('Para continuar, escoja un plan');
			return;
		}
		history.push(`/info/${planSelected}/ASFALABELLA50K`);
	};
	return (
		<>
			<div className="App">
				<Helmet>
					<title>Consigue tu plan hoy - Connect Assistance</title>
					<meta
						name="description"
						content="Connect Assistance es la compañía #1 en Asistencia en Carretera y en el Hogar en Puerto Rico, Panamá, Costa Rica y Colombia"
					/>
					<meta
						property="og:description"
						content="Connect Assistance es la compañía #1 en Asistencia en Carretera y en el Hogar en Puerto Rico, Panamá, Costa Rica y Colombia"
					/>
				</Helmet>
				<div className="App-header ">
					<div style={{ paddingTop: '0.1px' }}>
						{hidden && (
							<div className="mobile">
								<div
									className={`productos-shared-header-sub-container productos-shared-header-sub-container-white productos-shared-header-sub-container-fixed`}
								>
									<div className="productos-shared-header-sub-left">
										<div className="productos-shared-header-sub-label">Consigue tu plan hoy</div>
									</div>
									<div className="productos-shared-header-sub-right">
										<Link to="/#servicios" className="btn" target="_top">
											<div className="btn">Cotizar y comprar</div>
										</Link>
									</div>
								</div>
							</div>
						)}
						<ServicesHeroProducts type={params.section} />
						<div>
							<div className="productos-shared-productos-buy-container">
								<ServiceSubscribete
									branch={branch}
									data={data}
									section={params.section}
									linkTo={linkTo}
									pricing={pricing}
								/>
							</div>
							<ByConnect />
							<div className="productos-shared-section-4">
								<div className="productos-shared-section-4-container">
									<div className="img__container">
										<img src={pago} alt="" />
									</div>
									<div className="text__container">
										<h1 className="title-green">¿Qué incluye tu plan?</h1>
										<h2>Un plan que se adapta a ti</h2>
										<p className="p__include__plan" dangerouslySetInnerHTML={{ __html: data.text }}></p>
									</div>
								</div>
								<div className="productos-shared-section-4-detail">
									<DetailPlan data={data.limitOfPlan}></DetailPlan>
								</div>
								<div className="productos-shared-section-4-container">
									<p className="full" dangerouslySetInnerHTML={{ __html: data.limitOfPlan.notes }}></p>
								</div>
							</div>
							<div className="productos-shared">
								<Container>
									<div className="productos-shared-container">
										<div className="productos-shared-section-3">
											<h1 className="title-green">¿Cómo funciona?</h1>
											<div>
												<h2 dangerouslySetInnerHTML={{ __html: data.h2_title }}></h2>
												<p>{data.h2_info}</p>
											</div>
											<div className="productos-shared-section-3-items">
												<div className="productos-shared-section-3-item">
													<div className="productos-shared-section-3-item-period">
														<div className="productos-shared-section-3-item-period-square"></div>
														<div className="productos-shared-section-3-item-period-line"></div>
													</div>
													<div className="productos-shared-section-3-item-info">
														<h3
															dangerouslySetInnerHTML={{
																__html: data.experienca_1_title,
															}}
														></h3>
														<p>{data.experienca_1_info}</p>
													</div>
												</div>
												<div className="productos-shared-section-3-item">
													<div className="productos-shared-section-3-item-period">
														<div className="productos-shared-section-3-item-period-square"></div>
														<div className="productos-shared-section-3-item-period-line"></div>
													</div>
													<div className="productos-shared-section-3-item-info">
														<h3
															dangerouslySetInnerHTML={{
																__html: data.experienca_2_title,
															}}
														></h3>
														<p>{data.experienca_2_info}</p>
													</div>
												</div>
												<div className="productos-shared-section-3-item">
													<div className="productos-shared-section-3-item-period">
														<div className="productos-shared-section-3-item-period-square"></div>
													</div>
													<div className="productos-shared-section-3-item-info">
														<h3
															dangerouslySetInnerHTML={{
																__html: data.experienca_3_title,
															}}
														></h3>
														<p>{data.experienca_3_info}</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</Container>
							</div>
						</div>
					</div>
					<ServiceNuestrosClientes template="secondary" data={nuestrosClienteJson} />
				</div>
			</div>
			{showPopup && (
				<PopupExistProcess
					type="info"
					title="¡Felicitaciones!"
					message="¡Aprovecha y ahorra el 40% de la compra!"
					labelButton="Aplicar cupón"
					closePopUp={goToCheckout}
					exitProcess={closePopUp}
					reverseCallToAction={true}
				>
					<div className="dates">VALIDO HASTA EL 24 DE JUNIO</div>
					<div className="popup__message">
						Recibe <strong>$50mil</strong> de descuento en la compra del <strong>plan de asistencia anual</strong>, con
						el código <strong>ASFALABELLA50K</strong>
					</div>
					<div className="cobertura-select-content">
						<div className="cobertura-select-items">
							<div className="cobertura-select-item-type-content">
								<img
									className="cobertura-auto"
									src={funnelData.step1.productType === 'autos' ? CarIcon : mascota}
									alt=""
								/>
							</div>
							<div className="cobertura-select-item-type-label">
								<p>{funnelData.step1.productType === 'autos' ? 'Carros' : 'Mascotas (perros o gatos)'}</p>
							</div>
							<div className="cobertura-select-item-qty-content">
								<Link to="#" onClick={async () => await handlePropertyChange('minus')} className=" minus-icon">
									<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M9.0906 17.595C13.693 17.595 17.4239 13.8641 17.4239 9.26168C17.4239 4.65931 13.693 0.928345 9.0906 0.928345C4.48822 0.928345 0.757263 4.65931 0.757263 9.26168C0.757263 13.8641 4.48822 17.595 9.0906 17.595Z"
											fill="#49AD33"
										/>
										<path
											d="M5.75726 9.26168H12.4239M17.4239 9.26168C17.4239 13.8641 13.693 17.595 9.0906 17.595C4.48822 17.595 0.757263 13.8641 0.757263 9.26168C0.757263 4.65931 4.48822 0.928345 9.0906 0.928345C13.693 0.928345 17.4239 4.65931 17.4239 9.26168Z"
											stroke="white"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</Link>
								<div className="cobertura-qty-label">{count}</div>
								<Link to="#" onClick={() => handlePropertyChange('plus')} className=" plus-icon">
									<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M9.09054 17.595C13.6929 17.595 17.4239 13.8641 17.4239 9.26168C17.4239 4.65931 13.6929 0.928345 9.09054 0.928345C4.48816 0.928345 0.757202 4.65931 0.757202 9.26168C0.757202 13.8641 4.48816 17.595 9.09054 17.595Z"
											fill="#49AD33"
										/>
										<path
											d="M9.09054 5.92834V9.26168M9.09054 9.26168V12.595M9.09054 9.26168H5.7572M9.09054 9.26168H12.4239M17.4239 9.26168C17.4239 13.8641 13.6929 17.595 9.09054 17.595C4.48816 17.595 0.757202 13.8641 0.757202 9.26168C0.757202 4.65931 4.48816 0.928345 9.09054 0.928345C13.6929 0.928345 17.4239 4.65931 17.4239 9.26168Z"
											stroke="white"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</Link>
							</div>
						</div>
					</div>
					<div className="popup__totals">
						<h5>Precio con cupón: ${Pricing.formatPrice(total - 50000, branch, 2)}</h5>
						<h3 className="line__through">
							Precio normal: <span>${Pricing.formatPrice(total, branch, 2)}</span>
						</h3>
						Ahorras: $ {Pricing.formatPrice(50000, branch, 2)}
					</div>
				</PopupExistProcess>
			)}
		</>
	);
}
