import React, { useContext } from 'react';

import './cambiosEnPrecio.scss';

import Container from 'components/container/container';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { AppContext } from 'helpers/Context';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

export default function CambiosEnPrecio() {
	const { t, i18n } = useTranslation();
	const { branch, setBranchStatus } = useContext(AppContext);

	let params: any = useParams();

	useEffect(() => {
		setBranchStatus(params.branch);
		i18n.changeLanguage(params.branch);
	}, [branch, i18n, setBranchStatus, params.branch]);

	const { identify, setIdentifyStatus } = useContext(AppContext);
	useEffect(() => {
		if (!identify) {
			
			setIdentifyStatus(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="App">
			<Helmet>
				<title>Actualización de Precios de Membresía</title>
				<meta
					name="description"
					content="Connect Assistance es la compañía #1 en Asistencia en Carretera y en el Hogar en Puerto Rico, Panamá, Costa Rica y Colombia"
				/>
				<meta
					property="og:description"
					content="Connect Assistance es la compañía #1 en Asistencia en Carretera y en el Hogar en Puerto Rico, Panamá, Costa Rica y Colombia"
				/>
			</Helmet>
			<div className="App-header">
				<Container>
					<div className="terminos-container">
						<h3 className="text-center ">Actualización de Precios de Membresía</h3>

						<p>Actualizado: 15/02/2021</p>
						<div
							className="margin-top-30"
							dangerouslySetInnerHTML={{
								__html: t('cambiosEnPrecio2021'),
							}}
						></div>
					</div>
				</Container>
			</div>
		</div>
	);
}
