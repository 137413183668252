import { utmData } from 'interfaces/FunnelInterface';
import api from './Api';
import * as constants from '../helpers/Constants';

const createLead = async (branch: string, utm: utmData) => {
	return await api.post('/sales/cSalesFunel', {
		branch: branch,
		utm: utm,
		accountId: constants.ACCOUNT_ID_CO,
		accountName: 'Falabella B2B2C',
		sourceType: 'Falabella B2B2C',
		platform: 'Falabella Website',
	});
};

const updateLead = async (data: any, utm: utmData) => {
	return await api.post('/sales/uSalesFunel', {
		_id: data._id,
		fields: { ...data, sourceType: 'Falabella B2B2C', platform: 'Falabella Website' },
		utm: utm,
	});
};

export { createLead, updateLead };
