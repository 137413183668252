import React, { ReactNode, useContext, useState } from 'react';
import './header.scss';

import CloseBtn from '../../../images/svg/close-btn.svg';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { GlobalContext } from '../../../context/GlobalState';
import { PopupExistProcess } from 'components/Popup-exist-porcess/Popup-exist-porcess';
import { FunnelData } from 'interfaces/FunnelInterface';

interface IProps {
	children?: ReactNode;
	className?: string;
	price?: string;
	progress?: string;
}

const FunnelHeader: React.FC<IProps> = ({ className, progress }) => {
	const { reset } = useContext(GlobalContext);
	const [isShowModal, setIsShowModal] = useState<boolean>(false);
	const { funnel } = useContext(GlobalContext);
	const [funnelData] = useState<FunnelData>(funnel);
	const closeBtnClick = () => {
		reset();
	};

	let history = useHistory();
	const onCloseFunnel = () => {
		if (Number(progress) < 4) {
			setIsShowModal((isShowModal) => !isShowModal);
		} else {
			history.push('/');
		}
	};
	const closeFunnel = () => {
		setIsShowModal(false);
		let section = '';
		console.log(funnelData);
		if (funnelData.step1.productType === 'autos') {
			section = 'auto';
		} else {
			section = 'mascota';
		}

		history.push(`/servicios-shared/${section}`);
	};
	return (
		<div>
			<div className="funnel-header-content desktop">
				<div className="funnel-header-content-left">
					<a href="https://www.segurosfalabella.com.co/asistencias/">
						<span className="funnel-header-content-left-logo" />
					</a>
				</div>
				<div className="funnel-header-content-right">
					<Link onClick={onCloseFunnel} to="#">
						<img className="funnel-header-content-right-close-btn" src={CloseBtn} onClick={closeBtnClick} alt="" />
					</Link>
				</div>
			</div>
			<div className={`funnel-header-container ${className}`}>
				<div className={`funnel-header-progress funnel-header-progress-${progress}`}></div>
			</div>
			{isShowModal && (
				<PopupExistProcess
					type="successful"
					title="¿Realmente desea salir?"
					message="La suscripción no se activa hasta que los datos estén completos"
					labelButton="Continuar"
					labelButtonCancel="Salir"
					closePopUp={onCloseFunnel}
					exitProcess={closeFunnel}
					reverseCallToAction={true}
				></PopupExistProcess>
			)}
		</div>
	);
};

export default FunnelHeader;
