import React, { useEffect, useState } from 'react';
import './Banner.scss';
import CloseBtnWhite from 'images/svg/close-btn-white.svg';
import { useHistory, useLocation } from 'react-router-dom';
import { getPromoActive } from 'helpers/PromoActived';
export const Banner: React.FC = () => {
	const history = useHistory();
	const [isVisible, setIsVisible] = useState(false);
	const [iSInvoke, setiSInvoke] = useState(true);
	const { pathname } = useLocation();
	useEffect(() => {
		if (!['/servicios-shared/auto', '/servicios-shared/mascota'].includes(pathname)) {
			setiSInvoke(false);
		} else {
			setiSInvoke(true);
		}
		if (getPromoActive()) {
			setIsVisible(true);
		} else {
			setIsVisible(false);
		}
	}, [pathname]);
	const closeBanner = () => {
		setIsVisible(false);
	};
	const goToBuyPlan = () => {
		if (iSInvoke) {
			const plan = pathname.indexOf('/auto') > -1 ? '1-0-0-A-co-fa' : '0-1-0-A-co-fa';
			history.push(`/info/${plan}/ASFALABELLA50K`);
		}
	};
	return isVisible ? (
		<div className="banner__container">
			<div className="banner__content" onClick={goToBuyPlan}>
				<div className="banner__info">
					<div className="banner__info__message">
						<div className="desktop">
							Utiliza el código <span>ASFALABELLA50K</span> en la compra de tu plan anual y recibe $50mil{' '}
							<small>valido hasta el 24 DE JUNIO</small>
						</div>
						<div className="mobile">
							FALABELLA TE REGALA $50mil <small style={{display: 'block'}}>de descuento en tu plan anual con el código</small>
							<span>ASFALABELLA50K</span>
						</div>
					</div>
					{iSInvoke && (
						<button className="banner__info__btn__buy">
							<span className="desktop">Adquirir descuento</span>
							<span className="mobile"> Adquirir</span>
						</button>
					)}
				</div>
			</div>
			<button className="banner__btn__close" onClick={closeBanner}>
				<img src={CloseBtnWhite} alt="Cerrar oferta" />
			</button>
		</div>
	) : (
		<></>
	);
};
