import React from 'react';
import { Select, MenuItem, FormControl, InputLabel, makeStyles, Theme, createStyles } from '@material-ui/core';

import './dropdown.scss';
import { DropDownIcon } from '../../../styles/DropDownIcon';

const DropDownInput = (props: any) => {
	const useStyles = makeStyles((theme: Theme) =>
		createStyles({
			formControl: {
				//minWidth: 120,
				display: 'flex',
				flexDirection: 'column',
			},
		})
	);
	const classes = useStyles();
	return (
		<FormControl variant="filled" className={classes.formControl} {...props}>
			<InputLabel>{props.label}</InputLabel>
			<Select IconComponent={DropDownIcon} onChange={props.onChange} {...props} inputRef={props.inputRef}>
				<MenuItem value="">
					<em>None</em>
				</MenuItem>
				{props.data.map(function (d: any, index: number) {
					return (
						<MenuItem key={index} value={d.label || d._id}>
							{d.label || d._id}
						</MenuItem>
					);
				})}
			</Select>
			{props.errorinput ? <div className="textfield-error-label">{props.errorlabel}</div> : null}
		</FormControl>
	);
};

export default DropDownInput;
