import React from 'react';
import segurosFalabellaLogo from 'images/svg/seguros-falabella-logo.svg';
import powerBy from 'images/svg/powered-by.svg';
import logocompleto from 'images/svg/logo-completo.svg';
import './footer-logos.scss';

const FooterLogos: React.FC = () => (
	<div className="mobile">
		<div className="footer__logos__container">
			<div className="nav__bottom">
				<div className="logo text-center">
					<img src={segurosFalabellaLogo} alt="Seguros Falabella/Connect Assistance" />
				</div>
			</div>
			<div className="nav__bottom__logos">
				<img src={powerBy} alt="power by connect" />
				<img src={logocompleto} alt="Logo Connect" />
			</div>
		</div>
	</div>
);

export default FooterLogos;
