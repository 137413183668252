import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './funnel-footer-mobile.scss';
import RightArrow from '../../../../images/svg/right-arrow.svg';
import CloseBtn from '../../../../images/svg/close-btn.svg';
import CloseBtnWhite from '../../../../images/svg/close-btn-white.svg';
import GrayLeftArrow from '../../../../images/svg/gray-left-arrow.svg';
import { PopupExistProcess } from 'components/Popup-exist-porcess/Popup-exist-porcess';

interface IProps {
	className?: string;
	template?: 'primary' | 'secondary';
	section?: string;
	active?: string;
	btnLabel?: string;
	linkTo?: string;
	classNameBtn?: string;
	showChevron?: boolean;
	totalScreen?: number;
	currentScreen?: number;
	messagePayment?: boolean;
}

const FunnelFooterMobile: React.FC<IProps> = ({
	className,
	section,
	active,
	showChevron,
	totalScreen,
	currentScreen,
	template,
}) => {
	let history = useHistory();
	const [isShowModal, setIsShowModal] = useState<boolean>(false);
	const secctions = ['cobertura', 'info', 'pago'];
	const handleOpenFunnel = () => {
		if (secctions.includes(active as string)) {
			setIsShowModal((isShowModal) => !isShowModal);
		} else {
			history.push('/');
		}
	};
	const closeFunnel = () => {
		setIsShowModal(false);
		history.push('/');
	};

	return (
		<>
			<div className={`${className} ${template === 'secondary' ? 'funnel-footer-mobile-secondary' : ''}`}>
				<div className={`funnel-footer-mobile-container`}>
					<div className="funnel-footer-mobile-info-div">
						{!showChevron && (
							<Link className="mobile" to="#" onClick={() => history.goBack()}>
								<img className="funnel-footer-mobile-left-arrow pointer" src={GrayLeftArrow} alt="" />
							</Link>
						)}
						{['steps', 'steps-activation'].includes(section as string) ? (
							<ul className="funnel-footer-mobile-ul">
								{section === 'steps' && (
									<>
										<li
											className={
												active === 'cobertura' ? 'funnel-footer-mobile-active' : 'funnel-footer-mobile-disabled'
											}
										>
											PLANES
										</li>
										<li>
											<img src={RightArrow} alt="" />
										</li>
										<li className={active === 'info' ? 'funnel-footer-mobile-active' : 'funnel-footer-mobile-disabled'}>
											INFO
										</li>
										<li>
											<img src={RightArrow} alt="" />
										</li>
									</>
								)}
								<li className={active === 'pago' ? 'funnel-footer-mobile-active' : 'funnel-footer-mobile-disabled'}>
									{section === 'steps' ? 'PAGO' : 'ACTIVACIÓN'}
								</li>
							</ul>
						) : (
							<ul className="funnel-footer-mobile-ul">
								<li className={`funnel-footer-mobile ${template === 'secondary' ? 'secondary' : ''}`}>{section}</li>
							</ul>
						)}
					</div>

					{section === 'Completar Perfil' && (
						<div className="funnel-footer-mobile-tracking">
							{currentScreen}
							<span className="tracking-divider">/</span>
							{totalScreen}
						</div>
					)}
					<Link onClick={handleOpenFunnel} to="#" className="funnel-footer-mobile-close-btn-link">
						<img
							className="funnel-footer-mobile-close-btn mobile"
							src={template === 'secondary' ? CloseBtnWhite : CloseBtn}
							alt=""
						/>
					</Link>
				</div>
			</div>
			{isShowModal && (
				<PopupExistProcess
					type="successful"
					title="¿Realmente desea salir?"
					message="La suscripción no se activa hasta que los datos estén completos"
					labelButton="Continuar"
					labelButtonCancel="Salir"
					closePopUp={handleOpenFunnel}
					exitProcess={closeFunnel}
					reverseCallToAction={true}
				></PopupExistProcess>
			)}
		</>
	);
};

export default FunnelFooterMobile;
