import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './info.scss';
import OrangeButton from 'components/inputs/orange-button/orange-button';
import FunnelHeader from 'components/funnel/header/header';
import FunnelResumen from '../resumen/resumen';
import FunnelFooterMobile from 'components/funnel/footer/mobile/funnel-footer-mobile';
import FunnelFooterDesktop from 'components/funnel/footer/desktop/funnel-footer-desktop';
import { AppContext } from 'helpers/Context';
import TextInput from 'components/inputs/textfield/textfield';
import { GlobalContext } from 'context/GlobalState';
import { FunnelData, TotalPrice } from 'interfaces/FunnelInterface';
import { useFormik } from 'formik';
import { updateLead, createLead } from 'services/LeadService';
import Modal from 'components/modal/modal';
import { getDeparments, getMunicipalities } from 'services/ProvinciasService';
import LoadingDialog from 'helpers/LoadingDialog/loadingDialog';
import DropDownInput from 'components/inputs/dropdown/dropdown';
import GoogleTagManager from 'helpers/GoogleTagManager';
import { useParams } from 'react-router-dom';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import DoneIcon from '@material-ui/icons/Done';

import Pricing from 'helpers/Pricing';
import { getCupon, getPresale } from 'services/SubscriptionService';
import Toaster from 'helpers/Toaster';
import * as constants from 'helpers/Constants';
import { Helmet } from 'react-helmet';
import { getLabels } from './models/info.constans';
import FooterLogos from 'components/footer-logos/footer-logos';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { pageEvent, pageEventDimensionUserId, pageEventFunnelStep, pageViewEvent } from 'helpers/dataLaye';
import logocompleto from 'images/svg/logo-completo.svg';
import powerBy from 'images/svg/powered-by.svg';
import RadioButton from 'components/inputs/radio-button/radio-button';
interface IFormInputs {
	nombre: string;
	apellido: string;
	telefono: string;
	email: string;
	direccion: string;
	estado: string;
	codigoPostal: string;
	numeroCedula: string;
	tipoCedula: string;
	canton: string;
	distrito: string;
	agent: string;
}

export default function FunnelInfo() {
	const history = useHistory();
	const { t } = useTranslation();
	const { setShowStatus, identify, setIdentifyStatus } = useContext(AppContext);
	const { funnel, setFunnel } = useContext(GlobalContext);
	const [funnelData, setFunnelData] = useState<FunnelData>(funnel);
	const [pais, setPais] = useState('');
	const [isSaleByAgent, setIsSaleByAgent] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [resumenType, setResumenType] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [cantones, setCantones] = useState(funnelData.cantones ? [...funnelData.cantones] : []);
	const [distritos, setDistritos] = useState(funnelData.distritos ? [...funnelData.distritos] : []);
	const [provincias, setProvincias] = useState(funnelData.provincies ? [...funnelData.provincies] : []);
	const tipoCedulaValues = [
		{ label: 'Cédula de ciudadanía' },
		{ label: 'Cédula de extranjería' },
		{ label: 'Pasaporte ' },
		{ label: 'Carnet Diplomático' },
	];
	const googleTag = GoogleTagManager;
	const [vehicleCount, setVehicleCount] = useState(funnelData.step1.cobertura.auto);
	const [petCount, setPetCount] = useState(funnelData.step1.cobertura.pet);
	const [homeCount, setHomeCount] = useState(funnelData.step1.cobertura.hogar);
	const [pago, setPago] = useState(funnelData.step1.pago);
	const [total, setTotal] = useState<TotalPrice>(funnelData.total);
	const [couponName, setCouponName] = useState<any>(null);
	const [hasDiscount, setHasDiscount] = useState(funnelData.step3.hasDiscount);
	const [idCoupon, setIdCoupon] = useState(funnelData.step3.couponId);
	const [priceSaved, setPriceSaved] = useState<any | null>(0);
	const [checkedA, setCheckedA] = useState(false);

	let sub = useParams();

	let { search } = useLocation();
	const paramsString = decodeURIComponent(search);
	let params = new URLSearchParams(paramsString);

	useEffect(() => {
		if (funnelData.step1.productType === 'autos') {
			pageViewEvent('Asistencias Carro', 'https://planesasistencia.connect.com.co/carro/crear-cuenta');
			pageEventFunnelStep('contratacion-asistencia', `web-asistencia-carro`, 'paso-crear-cuenta');
		} else {
			pageViewEvent('Asistencias Mascotas', 'https://planesasistencia.connect.com.co/mascota/crear-cuenta');
			pageEventFunnelStep('contratacion-asistencia', `web-asistencia-mascota`, 'paso-crear-cuenta');
		}
		if (!identify) {
			setIdentifyStatus(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const fetchFunnelId = async () => {
			if (funnelData.subscriptionData.leadMongoId === '') {
				const getFunnelId = await createLead('CO', funnel.utm);
				funnelData.subscriptionData.leadMongoId = getFunnelId.data.data.funelId;
				setFunnelData(funnelData);
				const data = {
					auto: funnelData.step1.cobertura.auto,
					pet: funnelData.step1.cobertura.pet,
					home: funnelData.step1.cobertura.hogar,
					pago: funnelData.step1.pago,
					branch: 'co',
				};
				setTotal(Pricing.calculate(data));
			}
		};

		fetchFunnelId();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [funnel]);

	useEffect(() => {
		const planId = sub['id'];
		if (planId && planId !== '' && planId !== undefined) {
			funnelData.step1.plan = sub['id'];
			const arrPlan = sub['id'].split('-');
			const autos = parseInt(arrPlan[0]);
			const pet = parseInt(arrPlan[1]);
			const homes = parseInt(arrPlan[2]);

			funnelData.step1.cobertura.pet = pet;
			funnelData.step1.cobertura.hogar = homes;
			funnelData.step1.cobertura.auto = autos;
			setVehicleCount(autos);
			setPetCount(pet);
			setHomeCount(homes);
			if (arrPlan[3] === 'M') {
				setPago('mensual');
				funnelData.step1.pago = 'mensual';
			} else {
				setPago('anual');
				funnelData.step1.pago = 'anual';
			}
			const data = {
				auto: vehicleCount,
				pet: petCount,
				home: homeCount,
				pago: pago,
				branch: 'co',
			};
			const allPricing = Pricing.calculate(data);
			funnelData.total = allPricing;
			setTotal(allPricing);
			setFunnelData(funnelData);
			setFunnel(funnelData);
		}
		if (sub['coupon'] && sub['coupon'] !== '' && sub['coupon'] !== undefined) {
			setCouponName(sub['coupon']);
			if (couponName) {
				handleCupon(couponName);
				funnelData.step3.coupon = couponName;
				funnelData.step3.hasDiscount = hasDiscount;
				funnelData.step3.discountAmount = priceSaved;
				funnelData.step3.couponId = idCoupon;
				setFunnelData(funnelData);
				setFunnel(funnelData);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [funnelData, pago, vehicleCount, petCount, homeCount, couponName]);

	const showModal = (type: string) => {
		setResumenType(type);
		setModalOpen(true);
	};

	const hideModal = () => {
		setModalOpen(false);
	};

	const handleChange = (event: any) => {
		if (event.target.checked) {
			if (funnelData.step1.productType === 'autos') { 
				pageEvent('asistencias-carro', 'paso-crear-cuenta', `acepta-tyc`);
			} else {
				pageEvent('asistencias-mascota', 'paso-crear-cuenta', `acepta-tyc`);
			}
		}
		setCheckedA(event.target.checked);
	};
	useEffect(() => {
		if (funnelData.step2.agent_code !== '') {
			setIsSaleByAgent(true);
		}
	});

	const initialValues: IFormInputs = {
		nombre: funnel.step2.nombre,
		apellido: funnel.step2.apellido,
		telefono: funnel.step2.telefono,
		email: funnel.step2.email,
		direccion: funnel.step2.direccion,
		estado: funnel.step2.estado,
		codigoPostal: funnel.step2.codigoPostal,
		numeroCedula: funnel.step2.numeroCedula,
		tipoCedula: funnel.step2.tipoCedula,
		canton: funnel.step2.canton,
		distrito: funnel.step2.distrito,
		agent: funnel.step2.agent_code,
	};

	const validate = (values: any) => {
		const errors: any = {};
		const phoneLenght = lengthPhone('co');
		if (!values.nombre) {
			errors.nombre = 'Campo Requerido';
		}
		if (!values.apellido) {
			errors.apellido = 'Campo Requerido';
		}
		if (!values.telefono) {
			errors.telefono = 'Campo Requerido';
		}

		const regex = /^[0-9]+$/;
		if (!regex.test(values.telefono)) {
			errors.telefono = 'Formato inválido (utilice solo números)';
		}

		if (values.telefono.length < phoneLenght) {
			errors.telefono = 'Teléfono muy corto';
		}

		if (values.telefono.length > phoneLenght) {
			errors.telefono = 'Teléfono muy largo';
		}

		if (!values.email) {
			errors.email = 'Campo Requerido';
		} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
			errors.email = 'Correo electrónico inválido';
		}

		if (!values.tipoCedula) {
			errors.tipoCedula = 'Campo Requerido';
		}
		if (!values.numeroCedula) {
			errors.numeroCedula = 'Campo Requerido';
		}

		if (!values.estado) {
			errors.estado = 'Campo Requerido';
		}
		if (!values.canton) {
			errors.canton = 'Campo Requerido';
		}

		return errors;
	};

	const formik = useFormik({
		initialValues,
		validate,
		onSubmit: (values) => {
			if (!checkedA) {
				Toaster.error('Por favor aceptar términos y condiciones para proceder');
				return;
			}
			const data = {
				nombre: formik.values.nombre,
				apellido: formik.values.apellido,
				telefono: formik.values.telefono,
				email: formik.values.email,
				direccion: formik.values.direccion,
				estado: formik.values.estado,
				canton: formik.values.canton,
				codigoPostal: formik.values.codigoPostal,
				numeroCedula: formik.values.numeroCedula,
				tipoCedula: formik.values.tipoCedula,
				distrito: formik.values.distrito,
				agent_code: formik.values.agent,
			};

			funnelData.step2 = data;
			setFunnelData(funnelData);
			setFunnel(funnelData);

			const amountTotal = getTotal();
			history.push('/pago');
		},
	});

	const onInputBlur = async ({ target }: any) => {
		let inputName = '';
		switch (target.name) {
			case 'nombre':
				inputName = 'firstName';
				break;
			case 'apellido':
				inputName = 'lastName';
				break;
			case 'telefono':
				inputName = 'phone';
				break;
			case 'email':
				inputName = 'email';
				break;
			default:
				break;
		}
		const updateData = {
			_id: funnelData.subscriptionData.leadMongoId,
			[inputName]: target.value,
			branch: 'co',
			plan: funnelData.step1.plan,
		};
		updateLead(updateData, funnel.utm);
	};

	const getProvincias = async () => {
		let dataService: any[] = [];
		setIsLoading(true);
		const data = await getDeparments();
		dataService = [...data];
		let result = dataService.map((person: any) => ({ _id: person.id, label: person.name }));
		result = result.sort((a, b) => (a.label > b.label ? 1 : -1));
		const departament = result.find((item: any) => item._id == '11') as { _id: any; label: any };
		result = result.filter((item: any) => item._id != '11');
		result.unshift(departament);
		funnelData.provincies = result;
		setFunnelData(funnelData);
		setProvincias(result);
		setIsLoading(false);
		return dataService;
	};

	const setDistrito = ({ target }: any, districtsList?: any) => {
		formik.setFieldValue('distrito', target.value);
		const distritosFilter: any = (districtsList ? districtsList : distritos).filter(
			(book: any) => target.value === book.label
		);
		funnelData.step2.codigoPostal = distritosFilter[0].id;
		formik.setFieldValue('codigoPostal', funnelData.step2.codigoPostal);
		setFunnelData(funnelData);
		setFunnel(funnelData);
	};

	const getCanton = async ({ target }: any, provinciasResponse?: any) => {
		formik.setFieldValue('estado', target.value);
		let provinciaArray = [];
		const departamentID = provinciasResponse
			? provinciasResponse.find((departament: any) => departament.id === target.value).id
			: provincias.find((departament: any) => departament.label === target.value)._id;
		const municipalities = await getMunicipalities(departamentID);
		provinciaArray = municipalities.map((municipaliti: any) => ({
			_id: municipaliti.id,
			label: municipaliti.name,
			distritos: null,
		}));
		provinciaArray = provinciaArray.sort((a: any, b: any) => (a.label > b.label ? 1 : -1));
		setCantones(provinciaArray);
		funnelData.cantones = provinciaArray;
		setFunnelData(funnelData);
		return provinciaArray;
	};

	const getDistrito = ({ target }: any, cantonesOptional?: any) => {
		formik.setFieldValue('canton', target.value);
		let districtsArray: any[] = [];
		if (cantonesOptional) {
			districtsArray = cantonesOptional.map((person: any) => ({
				id: person._id,
				label: person.label,
			}));
		}
		setDistritos(districtsArray);
		funnelData.distritos = districtsArray;
		setFunnelData(funnelData);
		return districtsArray;
	};

	const getTotal = () => {
		const p = funnel.step1.pago === 'anual' ? funnel.total.anualPrice : funnel.total.mensualPrice;
		let finalPrice = 0;
		if (p && p > 0) {
			if (funnel.step3.discountAmount && funnel.step3.discountAmount > 0) {
				finalPrice = p - funnel.step3.discountAmount;
			} else {
				finalPrice = p;
			}
		}
		return finalPrice?.toFixed(2);
	};

	useEffect(() => {
		setShowStatus(false);
		setPais('Colombia');
		const ps = params.get('ps');
		(async () => {
			let provincias: any[] = [];
			provincias = await getProvincias();
			if (params.get('ps')) {
				const handlePreSaleInfo = await handlePreSale(ps);
				if (handlePreSaleInfo && handlePreSaleInfo.status === 'success') {
					funnelData.preSale = true;
					funnelData.subscriptionData.agentName = handlePreSaleInfo.presale.agentName;
					setInfoUser(handlePreSaleInfo.presale);
					setInfoPropertis(handlePreSaleInfo.presale.properties);
					if (handlePreSaleInfo.presale.province) {
						const provinciasFilter = await getCanton(
							{ target: { value: handlePreSaleInfo.presale.province } },
							provincias
						);
						if (handlePreSaleInfo.presale.canton) {
							const districtsList = getDistrito(
								{ target: { value: handlePreSaleInfo.presale.canton } },
								provinciasFilter
							);
							if (handlePreSaleInfo.presale.zipCode) {
								const distritoFind = districtsList.find((p) => p.id.toString() === handlePreSaleInfo.presale.zipCode);
								setDistrito({ target: { value: distritoFind.label } }, districtsList);
							}
						}
					}
					formik.handleSubmit();
					if (handlePreSaleInfo.presale.cupon) {
						setCouponName(handlePreSaleInfo.presale.cupon);
						funnelData.step3.coupon = handlePreSaleInfo.presale.cupon;
						funnelData.step3.hasDiscount = true;
						setFunnelData(funnelData);
						setFunnel(funnelData);
					}
				} else if (handlePreSaleInfo && handlePreSaleInfo.status === 'error') {
					Toaster.error(handlePreSaleInfo.message);
				}
			}
		})();

		return () => {
			setShowStatus(true);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setShowStatus]);

	const handlePreSale = async (value: any) => {
		if (value && value !== undefined) {
			try {
				const { data } = await getPresale(value);
				return data;
			} catch (error) {
				Toaster.error('Error. Intentelo mas tarde');
			}
		}
	};

	const setInfoUser = (data: any) => {
		const values = {
			...formik.values,
			nombre: data.name ? data.name : '',
			apellido: data.lastname ? data.lastname : '',
			telefono: data.phone ? data.phone : '',
			email: data.email ? data.email : '',
			direccion: data.address ? data.address : '',
			numeroCedula: data.document ? data.document : '',
			tipoCedula: data.typeDocument ? data.typeDocument : '',
		};
		formik.setValues(values);
	};

	const setInfoPropertis = (data: any) => {
		if (typeof data === 'string') {
			data = JSON.parse(data);
		}
		if (data.autos.length > 0) {
			for (let index = 0; index < data.autos.length; index++) {
				funnelData.subscriptionData[`autoColor${index + 1}`] = data.autos[index].color;
				funnelData.subscriptionData[`autoMake${index + 1}`] = data.autos[index].make;
				funnelData.subscriptionData[`autoModel${index + 1}`] = data.autos[index].model;
				funnelData.subscriptionData[`autoPlate${index + 1}`] = data.autos[index].plate;
				funnelData.subscriptionData[`autoYear${index + 1}`] = data.autos[index].year;
			}
		}
		if (data.motorcycles.length > 0) {
			for (let index = 0; index < data.motorcycles.length; index++) {
				funnelData.subscriptionData[`motoraColor${index + 1}`] = data.motorcycles[index].color;
				funnelData.subscriptionData[`motoraMake${index + 1}`] = data.motorcycles[index].make;
				funnelData.subscriptionData[`motoraModel${index + 1}`] = data.motorcycles[index].model;
				funnelData.subscriptionData[`motoraPlate${index + 1}`] = data.motorcycles[index].plate;
				funnelData.subscriptionData[`motoraYear${index + 1}`] = data.motorcycles[index].year;
			}
		}
		if (data.homes.length > 0) {
			for (let index = 0; index < data.homes.length; index++) {
				funnelData.subscriptionData[`homeAddress1${index + 1}`] = data.homes[index].address;
				funnelData.subscriptionData[`homeAddress2${index + 1}`] = data.homes[index].address2;
				funnelData.subscriptionData[`homeZipCode${index + 1}`] = data.homes[index].zipCode;
			}
		}

		setFunnelData(funnelData);
	};

	const handleCupon = async (value: any) => {
		if (value && value.length > 4) {
			setIsLoading(true);

			const { data } = await getCupon(value, 'co', funnelData.step1.plan);

			if (data === undefined) {
				Toaster.error('Error. No existe el código de descuento');
				setIsLoading(false);
				setPriceSaved(0);
				return;
			}

			if (funnelData.step1.pago === 'mensual') {
				const restrictedMensual = constants.restrictedCouponsMensual?.split(',') || [];
				const hasRestrictedCouponMensual = restrictedMensual.indexOf(value) > -1;

				if (hasRestrictedCouponMensual) {
					funnelData.step3.validCoupon = false;
				} else {
					funnelData.step3.validCoupon = true;
				}
				setFunnelData(funnelData);
			}

			if (funnelData.step1.pago === 'anual') {
				const restrictedAnual = constants.restrictedCouponsAnual?.split(',') || [];
				const hasRestrictedCouponAnual = restrictedAnual.indexOf(value) > -1;

				if (hasRestrictedCouponAnual) {
					funnelData.step3.validCoupon = false;
				} else {
					funnelData.step3.validCoupon = true;
				}
				setFunnelData(funnelData);
			}
			console.log('data.cupon.coupon.metadata.readOnly', data.cupon.coupon.metadata.readOnly);
			if (data.cupon.coupon.metadata.readOnly) {
				funnelData.step3.couponIsHidden = true;
				setFunnelData(funnelData);
			}
			if (
				data.cupon === undefined ||
				(data.cupon.coupon.metadata.plan && data.cupon.coupon.metadata.plan !== funnelData.step1.plan)
			) {
				Toaster.error('Error. Código de descuento invalido');
				setIsLoading(false);
				setPriceSaved(0);
				return;
			}

			if (data.cupon.active === false) {
				Toaster.error('Error. No existe el código de descuento');
				setIsLoading(false);
				setPriceSaved(0);
				return;
			}
			//DESCUENTO FIJO
			if (data.cupon.coupon.amount_off) {
				const tmpPago: any =
					funnelData.step1.pago === 'mensual' ? total.mensualPrice.toFixed(2) : total.anualPrice.toFixed(2);
				if (data.cupon.coupon.amount_off / 100 > tmpPago) {
					Toaster.error('Codigo inválido');
					setIsLoading(false);
					return;
				}

				const tmpTotalSaved = data.cupon.coupon.amount_off / 100;
				setPriceSaved(tmpTotalSaved);
				setIsLoading(false);
				setHasDiscount(true);
				setIdCoupon(data.cupon.coupon.id);
				setIsLoading(false);
				funnelData.step3.hasDiscount = true;
				funnelData.step3.coupon = value;
				funnelData.step3.discountAmount = tmpTotalSaved;
				funnelData.step3.couponId = data.cupon.coupon.id;
				setFunnelData(funnelData);
				setFunnel(funnelData);
			} else {
				const disc = data.cupon.coupon.percent_off;
				const tmpPago =
					funnelData.step1.pago === 'mensual'
						? funnelData.total.mensualPrice.toFixed(2)
						: funnelData.total.anualPrice.toFixed(2);
				const tmpTotalSaved = calculatePriceSaved(disc, tmpPago);
				setPriceSaved(tmpTotalSaved);
				setIsLoading(false);
				setHasDiscount(true);
				setIdCoupon(data.cupon.coupon.id);
				funnelData.step3.hasDiscount = true;
				funnelData.step3.coupon = value;
				funnelData.step3.discountAmount = parseFloat(tmpTotalSaved);
				funnelData.step3.couponId = data.cupon.coupon.id;
				setFunnelData(funnelData);
				setFunnel(funnelData);
			}

			return data;
		} else {
			setPriceSaved(0);
		}
	};

	const calculatePriceSaved = (percent: any, num: any) => {
		const priceSaved: Number = (percent / 100) * num;
		return Number.parseFloat(priceSaved.toString()).toFixed(2);
	};

	const lengthPhone = (branch: string) => {
		return (
			{
				pr: 10,
				cr: 8,
			}[branch] || 10
		);
	};
	const handleIsSaleByAgent = (flag: boolean) => {
		setIsSaleByAgent(flag);
		if (flag) { 
			pageEvent('asistencias-carro', 'paso-crear-cuenta', `asesor-si`);
		} else {
			pageEvent('asistencias-mascota', 'paso-crear-cuenta', `asesor-no`);

		}
	};

	return (
		<div className="App">
			<Helmet>
				<title>Completa tu Información - Connect Assistance</title>
				<meta
					name="description"
					content="Connect Assistance es la compañía #1 en Asistencia en Carretera y en el Hogar en Puerto Rico, Panamá, Costa Rica y Colombia"
				/>
				<meta
					property="og:description"
					content="Connect Assistance es la compañía #1 en Asistencia en Carretera y en el Hogar en Puerto Rico, Panamá, Costa Rica y Colombia"
				/>
			</Helmet>
			<LoadingDialog isLoading={isLoading}></LoadingDialog>
			<div className="App-header">
				<FunnelFooterMobile className="mobile" section="steps" active="info" linkTo="pago" showChevron={true} />
				<FunnelHeader progress="2" />
			</div>
			<form onSubmit={formik.handleSubmit}>
				<div className="mobile">
					<FunnelResumen
						show="mobile"
						data={funnelData}
						pago={pago === 'mensual' ? total.mensualPrice : total.anualPrice}
						price={
							pago === 'mensual'
								? total.mensualPrice - funnelData.step3.discountAmount
								: total.anualPrice - funnelData.step3.discountAmount
						}
						discount={priceSaved}
						modalClick={showModal}
					/>
				</div>
				<div className="funnel__main">
					<div className="funnel__main__inner">
						<div className="funnel__cols">
							<div className="funnel__col funnel__col-1">
								<Modal show={modalOpen} handleClose={hideModal}>
									<p>{t(`resumen.${resumenType}.resumenTitulo`)}</p>
									{t(`resumen.${resumenType}.masDetalles`)}
								</Modal>
								<h1 className="info-h3">Crea tu cuenta</h1>
								<p className="info-p">
									Aquí inicia nuestro viaje juntos. <br className="mobile" /> ¡Estamos listos!
								</p>
								<div className="info__form__container">
									<TextInput
										required
										name="nombre"
										className="info-form-input"
										label="Nombre"
										onBlur={onInputBlur}
										onChange={formik.handleChange}
										value={formik.values.nombre}
										errorinput={formik.errors.nombre ? 1 : 0}
										errorlabel={formik.errors.nombre}
									/>
									<TextInput
										required
										name="apellido"
										className="info-form-input"
										label="Apellido"
										onBlur={onInputBlur}
										onChange={formik.handleChange}
										value={formik.values.apellido}
										errorinput={formik.errors.apellido ? 1 : 0}
										errorlabel={formik.errors.apellido}
									/>
									<TextInput
										required
										name="telefono"
										className="info-form-input"
										label="Número de teléfono"
										onBlur={onInputBlur}
										onChange={formik.handleChange}
										value={formik.values.telefono}
										errorinput={formik.errors.telefono ? 1 : 0}
										errorlabel={formik.errors.telefono}
										tooltip={
											'Este número lo vamos a utilizar para contactarte en caso de una emergencia o información importante de tu cuenta'
										}
									/>
									<TextInput
										required
										type="email"
										name="email"
										className="info-form-input"
										label="Correo Electrónico"
										onBlur={onInputBlur}
										onChange={formik.handleChange}
										value={formik.values.email}
										errorinput={formik.errors.email ? 1 : 0}
										errorlabel={formik.errors.email}
										tooltip={
											'Necesario para poder abrir la cuenta con connect. Este será tu usuario para ingresar a tu panel administrativo'
										}
									/>

									<div className="info-grow ">
										<div className="left">
											<DropDownInput
												required
												name="tipoCedula"
												data={tipoCedulaValues}
												onChange={formik.handleChange}
												className={`full-width`}
												label="Tipo de cédula"
												value={formik.values.tipoCedula}
												errorinput={formik.errors.tipoCedula ? 1 : 0}
												errorlabel={formik.errors.tipoCedula}
											></DropDownInput>
										</div>
										<div className="right">
											<TextInput
												required
												name="numeroCedula"
												className="full-width"
												label="Número de cédula"
												onChange={formik.handleChange}
												value={formik.values.numeroCedula}
												errorinput={formik.errors.numeroCedula ? 1 : 0}
												errorlabel={formik.errors.numeroCedula}
												tooltip={'Este será el número de cédula que se incluirá en tu contrato de servicio.'}
												onBlur={() => pageEventDimensionUserId(formik.values.numeroCedula)}
											/>
										</div>
									</div>
									<DropDownInput
										required
										name="estado"
										data={provincias}
										onChange={(e) => getCanton(e, null)}
										className={`full-width`}
										label={getLabels('co')?.province}
										value={formik.values.estado}
										errorinput={formik.errors.estado ? 1 : 0}
										errorlabel={formik.errors.estado}
									></DropDownInput>
									<DropDownInput
										required
										name="canton"
										data={cantones}
										onChange={(e) => getDistrito(e, null)}
										className={`full-width`}
										label={getLabels('co')?.canton}
										value={formik.values.canton}
										errorinput={formik.errors.canton ? 1 : 0}
										errorlabel={formik.errors.canton}
									></DropDownInput>
									<TextInput required disabled name="branch" className="full-width" label="Pais" value={pais} />
									<div className="info__form__agent">
										<span>¿Te atendió un asesor de ventas?</span>
										<div className="info__form__agent__option">
											<RadioButton
												handleClick={() => handleIsSaleByAgent(true)}
												className={`${isSaleByAgent ? 'active' : ''}`}
												price={undefined}
												checkedStatus={isSaleByAgent ? true : false}
												label={'si'}
											></RadioButton>
											<RadioButton
												handleClick={() => handleIsSaleByAgent(false)}
												className={`${!isSaleByAgent ? 'active' : ''}`}
												price={undefined}
												pricePerc={undefined}
												checkedStatus={!isSaleByAgent ? true : false}
												label={'No'}
											></RadioButton>
										</div>
									</div>
									{isSaleByAgent && (
										<TextInput
											required
											value={formik.values.agent}
											name="agent"
											className="full-width"
											label="Código del asesor"
											onChange={formik.handleChange}
											errorinput={formik.errors.agent ? 1 : 0}
											errorlabel={formik.errors.agent}
											tooltip={'Este será el número que identifica al vendedor.'}
										/>
									)}
								</div>
								<div className="pago-terminos-div">
									<FormControlLabel
										control={
											<Checkbox
												checked={checkedA}
												onChange={handleChange}
												name="checkedA"
												icon={<CheckBoxOutlineBlankOutlinedIcon className="fill-transparent border-fill-green" />}
												checkedIcon={<DoneIcon className="border-fill-green background-green" />}
											/>
										}
										label=""
									/>
									<div className="pago-terminos-label">
										He leído y{' '}
										<a
											className="underline"
											href={`https://web.segurosfalabella.com/co/autorizacion-tratamiento-informacion-soat`}
											target="_blank"
											rel="noopener noreferrer"
											onClick={() => ''}
										>
											acepto el tratamiento de datos personales
										</a>{' '}
										de la agencia de Seguros Falabella
									</div>
								</div>
							</div>
							<div className="funnel__col funnel__col-2 ctr">
								<div className="desktop">
									<FunnelResumen
										show="desktop"
										data={funnel}
										pago={pago === 'mensual' ? total.mensualPrice : total.anualPrice}
										price={
											pago === 'mensual'
												? total.mensualPrice - funnelData.step3.discountAmount
												: total.anualPrice - funnelData.step3.discountAmount
										}
										discount={funnelData.step3.discountAmount}
										modalClick={showModal}
									/>
									<div className="container__logos">
										<img src={powerBy} alt="power by connect" />
										<img src={logocompleto} alt="Logo Connect" />
									</div>
								</div>
							</div>
						</div>

						<OrangeButton handleClick={formik.handleSubmit} className="info-orange-btn full-width mobile">
							Continuar
						</OrangeButton>
						<FooterLogos />
						<FunnelFooterDesktop
							className="desktop"
							section="steps"
							active="info"
							handleClick={formik.handleSubmit}
							showChevron={true}
						/>
					</div>
				</div>
			</form>
		</div>
	);
}
