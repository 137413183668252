import React, { useEffect, useContext } from 'react';

// Accordian Module

import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton } from 'react-accessible-accordion';

import './politicas.scss';

import Container from 'components/container/container';
import OrangeTitle from 'components/orange-title/orange-title';
import { Link } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import { AppContext } from 'helpers/Context';
import { Helmet } from 'react-helmet';
interface Props {}

const Politicas: React.FC<Props> = () => {
	const { identify, setIdentifyStatus } = useContext(AppContext);
	const { branch } = useContext(AppContext);
	useEffect(() => {
		if (!identify) {
			
			setIdentifyStatus(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="App empleos">
			<Helmet>
				<title>Políticas - Connect Assistance</title>
				<meta
					name="description"
					content="Connect Assistance es la compañía #1 en Asistencia en Carretera y en el Hogar en Puerto Rico, Panamá, Costa Rica y Colombia"
				/>
				<meta
					property="og:description"
					content="Connect Assistance es la compañía #1 en Asistencia en Carretera y en el Hogar en Puerto Rico, Panamá, Costa Rica y Colombia"
				/>
			</Helmet>
			<div className="hero bg--light-gray-3 ctr tall">
				<div className="container narrow">
					<h1>
						<h1>Políticas y Condiciones</h1>
					</h1>
					<h3>Condiciones Generales de Productos.</h3>
				</div>
			</div>
			<nav className="jump-nav">
				<Container>
					<div className="inner">
						<div className="jump-nav__label">Condiciones para:</div>
						<ul>
							<li>
								<NavHashLink to="#connect" scroll={(el) => el.scrollIntoView({ behavior: 'smooth' })}>
									CONNECT
								</NavHashLink>
							</li>
							<li>
								<NavHashLink to="#one" scroll={(el) => el.scrollIntoView({ behavior: 'smooth' })}>
								Agencia de Seguros Falabella
								</NavHashLink>
							</li>
						</ul>
					</div>
				</Container>
			</nav>
			<div className="accordian__group no-focus-outline" id="connect">
				<Container>
					<div className="faq-pad">
						<OrangeTitle>CONNECT</OrangeTitle>
					</div>
					<Accordion allowZeroExpanded>
						{branch === 'co' ? (
							<AccordionItem>
								<AccordionItemHeading>
									<AccordionItemButton>
										<a
											className="blackLink"
											rel="noopener noreferrer"
											target="_blank"
											href="../files/CONNECT/Terminos-Asistencia-Vial-Colombia.pdf"
										>
											Condiciones de Plan de Asistencia Vial
										</a>
									</AccordionItemButton>
								</AccordionItemHeading>
							</AccordionItem>
						) : (
							<AccordionItem>
								<AccordionItemHeading>
									<AccordionItemButton>
										<a
											className="blackLink"
											rel="noopener noreferrer"
											target="_blank"
											href="../files/CONNECT/CRA_Contract_Bundle_Auto_2021.pdf"
										>
											Condiciones de Plan de Asistencia en Carretera
										</a>
									</AccordionItemButton>
								</AccordionItemHeading>
							</AccordionItem>
						)}
						{branch === 'pr' && (
							<>
								<AccordionItem>
									<AccordionItemHeading>
										<AccordionItemButton>
											<a
												className="blackLink"
												rel="noopener noreferrer"
												target="_blank"
												href="../files/CONNECT/CRA_Contract_Bundle_Hogar_2021.pdf"
											>
												Condiciones de Plan de Asistencia en el Hogar
											</a>
										</AccordionItemButton>
									</AccordionItemHeading>
								</AccordionItem>
								<AccordionItem>
									<AccordionItemHeading>
										<AccordionItemButton>
											<a
												className="blackLink"
												rel="noopener noreferrer"
												target="_blank"
												href="../files/CONNECT/Reglamento-Promocion-Connect-te-llena-el-tanque.pdf"
											>
												Reglamento de la promoción “Connect te llena el tanque”
											</a>
										</AccordionItemButton>
									</AccordionItemHeading>
								</AccordionItem>
							</>
						)}
					</Accordion>
				</Container>
			</div>
			{branch === 'co' && (
				<>
					<div className="accordian__group no-focus-outline" id="one">
						<Container>
							<div className="faq-pad">
								<OrangeTitle>Agencia de Seguros Falabella</OrangeTitle>
							</div>
							<Accordion allowZeroExpanded>
								<AccordionItem>
									<AccordionItemHeading>
										<AccordionItemButton>
											<a
												className="blackLink"
												rel="noopener noreferrer"
												target="_blank"
												href="../files/FALABELLA/CONDICIONADO_ASF_BLACK_FRIDAY.pdf"
											>
												Condiciones ASF Black friday
											</a>
										</AccordionItemButton>
									</AccordionItemHeading>
								</AccordionItem>
								<AccordionItem>
									<AccordionItemHeading>
										<AccordionItemButton>
											<a
												className="blackLink"
												rel="noopener noreferrer"
												target="_blank"
												href="../files/FALABELLA/CONDICIONADO_ASF_TODORIESGO_FALABELLA_6_LAVADOS.pdf"
											>
												Condicionados Todo Riesgo 6 Lavados
											</a>
										</AccordionItemButton>
									</AccordionItemHeading>
								</AccordionItem>

								<AccordionItem>
									<AccordionItemHeading>
										<AccordionItemButton>
											<a
												className="blackLink"
												rel="noopener noreferrer"
												target="_blank"
												href="../files/FALABELLA/CONDICIONADO_SERVICIOS_SOAT_MOTOS_VF.pdf"
											>
												Condiciones SOAT Motos
											</a>
										</AccordionItemButton>
									</AccordionItemHeading>
								</AccordionItem>
								<AccordionItem>
									<AccordionItemHeading>
										<AccordionItemButton>
											<a
												className="blackLink"
												rel="noopener noreferrer"
												target="_blank"
												href="../files/FALABELLA/CONDICIONADO_SOAT_ASISTENCIA_ALINEACION_Y_BALANCEO_VF.pdf"
											>
												Condiciones SOAT Alineación y Balanceo
											</a>
										</AccordionItemButton>
									</AccordionItemHeading>
								</AccordionItem>
								<AccordionItem>
									<AccordionItemHeading>
										<AccordionItemButton>
											<a
												className="blackLink"
												rel="noopener noreferrer"
												target="_blank"
												href="../files/FALABELLA/CONDICIONADO_ASF_TODORIESGO_FALABELLA_12_KIT.pdf"
											>
												Condicionado Todo Riesgo Falabella 12 Kit
											</a>
										</AccordionItemButton>
									</AccordionItemHeading>
								</AccordionItem>
								<AccordionItem>
									<AccordionItemHeading>
										<AccordionItemButton>
											<a
												className="blackLink"
												rel="noopener noreferrer"
												target="_blank"
												href="../files/FALABELLA/CONDICIONADO_ASF_SOAT_COMBO_OTROS_PERSONAS.pdf"
											>
												Condicionado ASF SOAT Combo Otros Personas
											</a>
										</AccordionItemButton>
									</AccordionItemHeading>
								</AccordionItem>
								<AccordionItem>
									<AccordionItemHeading>
										<AccordionItemButton>
											<a
												className="blackLink"
												rel="noopener noreferrer"
												target="_blank"
												href="../files/FALABELLA/CONDICIONADO_ASF_SOAT_COMBO_AUTO.pdf"
											>
												Condicionado ASF SOAT Combo Auto
											</a>
										</AccordionItemButton>
									</AccordionItemHeading>
								</AccordionItem>
								<AccordionItem>
									<AccordionItemHeading>
										<AccordionItemButton>
											<a
												className="blackLink"
												rel="noopener noreferrer"
												target="_blank"
												href="../files/FALABELLA/CONDICIONADO_ASF_SOAT_COMBO_AUTO_RECARGADO.pdf"
											>
												Condicionado ASF SOAT Combo Auto Recargado
											</a>
										</AccordionItemButton>
									</AccordionItemHeading>
								</AccordionItem>
								<AccordionItem>
									<AccordionItemHeading>
										<AccordionItemButton>
											<a
												className="blackLink"
												rel="noopener noreferrer"
												target="_blank"
												href="../files/FALABELLA/CONDICIONADO_ASF_SOAT_MOTO_OPCIONAL.pdf"
											>
												Condicionado ASF SOAT Moto Opcional
											</a>
										</AccordionItemButton>
									</AccordionItemHeading>
								</AccordionItem>
								<AccordionItem>
									<AccordionItemHeading>
										<AccordionItemButton>
											<a
												className="blackLink"
												rel="noopener noreferrer"
												target="_blank"
												href="../files/FALABELLA/CONDICIONADO_ASF_EMERGENCIAS_MEDICAS.pdf"
											>
												Condicionado ASF Emergencias Medicas
											</a>
										</AccordionItemButton>
									</AccordionItemHeading>
								</AccordionItem>
								<AccordionItem>
									<AccordionItemHeading>
										<AccordionItemButton>
											<a
												className="blackLink"
												rel="noopener noreferrer"
												target="_blank"
												href="../files/FALABELLA/CONDICIONADO_ASF_TODORIESGO_FALABELLA_MOTO_3_LAVADOS.pdf"
											>
												Condicionado ASF Todo Riesgo Moto 3 Lavados
											</a>
										</AccordionItemButton>
									</AccordionItemHeading>
								</AccordionItem>
							</Accordion>
						</Container>
					</div>
				</>
			)}
			<div className="hero ctr">
				<div className="container narrow">
					<h1>
						<OrangeTitle className="ctr">Contacto</OrangeTitle>
					</h1>
					<h2>¿Toadavía tienes preguntas?</h2>
					<p className="detailJob">No te preocupes. Estamos aquí para asistir.</p>
					<Link to="/contacto" className="button ctr">
						Contactanos
					</Link>
				</div>
			</div>
		</div>
	);
};
export default Politicas;
