import React, { useState, useContext } from 'react';
import './resumen.scss';
import { FunnelData } from '../../../interfaces/FunnelInterface';
import { AppContext } from '../../../helpers/Context';
import Pricing from 'helpers/Pricing';
import DetailPlan from 'components/detail-plan/detail-plan';
import Incluye from 'components/Incluye/incluye';
import { Link } from 'react-router-dom';
import AutoData from 'data/auto.json';
import mascotaData from 'data/mascota.json';
import { GlobalContext } from 'context/GlobalState';

interface IProps {
	className?: string;
	show?: String;
	data: FunnelData;
	price?: number;
	pago?: number;
	discount?: number;
	modalClick?: any;
	template?: 'secondary';
	isInformative?: boolean;
	specificPlan?: { li: string; detail: string }[];
	dataTable?: any;
}

const FunnelResumen: React.FC<IProps> = ({
	className,
	show,
	data,
	price,
	pago,
	discount,
	template,
	isInformative,
	specificPlan,
	dataTable,
}) => {
	price = Number(price);
	const { branch } = useContext(AppContext);
	const { funnel } = useContext(GlobalContext);
	const [funnelData] = useState<FunnelData>(funnel);
	const [handleShowDetail, setHandleShowDetail] = useState(false);
	const [isCheckboxStatus, setCheckboxStatus] = useState(true);
	const handleInputChange = (event: any) => {
		!isCheckboxStatus ? setCheckboxStatus(true) : setCheckboxStatus(false);
	};
	const showPlanDetail = () => {
		setHandleShowDetail((handleShowDetail) => !handleShowDetail);
		setCheckboxStatus((isCheckboxStatus) => !isCheckboxStatus);
	};

	const petData = {
		listLeftPlan: specificPlan ?? mascotaData.listLeftPlan,
		limitOfPlan: dataTable ?? mascotaData.limitOfPlan,
	};
	return (
		<div>
			<div className="mobile">
				<div className="wrap-collabsible">
					{!handleShowDetail ? (
						<>
							<input
								id="collapsible"
								className="toggle"
								type="checkbox"
								onChange={handleInputChange}
								checked={isCheckboxStatus}
							/>
							<label htmlFor="collapsible" className={`lbl-toggle mas-info orange fadeIn ${template}`}>
								Ver detalles del plan
							</label>
							<div className="collapsible-content fadeIn">
								<div className="content-inner">
									<div className={`funnel-resumen-content ${!handleShowDetail ? 'dark' : ''}`}>
										<div className="funnel-resumen-top-div">
											<div className="funnel-resumen-h4">
												{funnelData.step1.productType === 'autos'
													? `Asistencia vial para carro (${data?.step1.cobertura.auto})`
													: `Asistencia para mascotas (${data?.step1.cobertura.pet})`}
											</div>

											<div className="funnel-resumen-pago">
												{isInformative ? 'Costo del plan ' : 'Total a pagar '}
												<span>${Pricing.formatPrice(price, branch, 2)}</span>
											</div>
											{discount ? (
												<>
													<div className="funnel-resumen-pago-subtotal">
														Subtotal <span>${Pricing.formatPrice(pago as number, branch, 2)}</span>
													</div>
													<div className="funnel-resumen-pago-discount">
														Descuento ${Pricing.formatPrice(discount as number, branch, 0)}
													</div>
												</>
											) : (
												''
											)}
										</div>

										<Incluye
											template="primary"
											data={funnelData.step1.productType === 'autos' ? AutoData.listLeftPlan : petData.listLeftPlan}
										></Incluye>
										<Link onClick={showPlanDetail} to={'#'} className="funel-resumen-link-detail">
											Ver más detalles
										</Link>
									</div>
								</div>
							</div>
						</>
					) : (
						<>
							<div className="lbl-toggle close bold fadeIn" onClick={showPlanDetail}>
								Más detalles
							</div>
							<div className="funnel-resumen-content-detail scroll fadeIn">
								<DetailPlan
									isOnlyAnual={specificPlan ? true : false}
									data={funnelData.step1.productType === 'autos' ? AutoData.limitOfPlan : petData.limitOfPlan}
									template="secondary"
								></DetailPlan>
							</div>
						</>
					)}
				</div>
			</div>

			<div className="desktop">
				<div className={`funnel-resumen-content ${!handleShowDetail ? 'dark' : ''}`}>
					{!handleShowDetail ? (
						<>
							<div className="funnel-resumen-top-div">
								<div className="mas-info uppercase orange mobile">¿Qué incluye tu plan?</div>
								<div className="funnel-resumen-h4 margin-top-12">
									{funnelData.step1.productType === 'autos'
										? `Asistencia vial para auto (${data?.step1.cobertura.auto})`
										: `Asistencia para mascotas (${data?.step1.cobertura.pet})`}
								</div>
								<div className="funnel-resumen-pago">
									{isInformative ? 'Costo del plan ' : 'Total a pagar '}
									<span>${Pricing.formatPrice(price, branch, 2)}</span>
								</div>
								{discount ? (
									<>
										<div className="funnel-resumen-pago-subtotal">
											Subtotal <span>${Pricing.formatPrice(pago as number, branch, 2)}</span>
										</div>
										<div className="funnel-resumen-pago-discount">
											Descuento ${Pricing.formatPrice(discount as number, branch, 0)}
										</div>
									</>
								) : (
									''
								)}
							</div>
							<Incluye
								template="primary"
								data={funnelData.step1.productType === 'autos' ? AutoData.listLeftPlan : petData.listLeftPlan}
							></Incluye>
							<Link onClick={showPlanDetail} to={'#'} className="funel-resumen-link-detail">
								Ver más detalles
							</Link>
						</>
					) : (
						<>
							<div className="lbl-toggle close bold fadeIn white" onClick={showPlanDetail}>
								Más detalles
							</div>
							<div className="funnel-resumen-content-detail fadeIn">
								<DetailPlan
									isOnlyAnual={specificPlan ? true : false}
									data={
										funnelData.step1.productType === 'autos'
											? AutoData.limitOfPlan
											:  petData.limitOfPlan
									}
									template="secondary"
								></DetailPlan>
							</div>
						</>
					)}
				</div>
				{handleShowDetail && (
					<div className="funnel-resumen-notes">
						<p
							dangerouslySetInnerHTML={{
								__html:
									funnelData.step1.productType === 'autos' ? AutoData.limitOfPlan.notes : petData.limitOfPlan.notes,
							}}
						></p>
					</div>
				)}
			</div>
		</div>
	);
};

export default FunnelResumen;
