interface Count {
	auto: number;
	pet: number;
	home: number;
	pago: string;
	branch: string;
}

const getPrice = (data: Count) => {
	let autoAnualPrice = 0;
	let petAnualPrice = 0;
	let homeAnualPrice = 0;
	let autoMensualPrice = 0;
	let petMensualPrice = 0;
	let homeMensualPrice = 0;
	let autoPlusOne = 0;
	let motoPlusOne = 0;
	let homePlusOne = 0;

	switch (data.branch) {
		case 'pr':
			autoAnualPrice = 55;
			petAnualPrice = 55;
			homeAnualPrice = 60;
			autoMensualPrice = 5.99;
			petMensualPrice = 5.99;
			homeMensualPrice = 5.99;
			autoPlusOne = 27.5;
			motoPlusOne = 27.5;
			homePlusOne = 30;
			break;
		case 'cr':
			autoAnualPrice = 39;
			petAnualPrice = 39;
			autoMensualPrice = 4;
			petMensualPrice = 4;
			autoPlusOne = 19.5;
			motoPlusOne = 19.5;
			break;
		case 'co':
			autoAnualPrice = 303450;
			petAnualPrice = 242700;
			autoMensualPrice = 178500;
			petMensualPrice = 142800;
			autoPlusOne = 303450;
			motoPlusOne = 242700;
			break;
		case 'co-hcm':
			autoAnualPrice = 248100.72;
			petAnualPrice = 248100.72;
			autoMensualPrice = 248100.72;
			petMensualPrice = 248100.72;
			autoPlusOne = 248100.72;
			motoPlusOne = 248100.72;
			break;
		case 'co-hch':
			autoAnualPrice = 484360.20;
			petAnualPrice = 484360.20;
			autoMensualPrice = 484360.20;
			petMensualPrice = 484360.20;
			autoPlusOne = 484360.20;
			motoPlusOne = 484360.20;
			break;

		default:
			break;
	}

	if (data.auto >= 1) {
		// Hago validacion de si Home tiene mas de 1 ya que Home tiene el precio mas alto para el Bundle
		if (data.home >= 1) {
			autoAnualPrice = 0;
			autoAnualPrice += autoPlusOne * data.auto;
		} else if (data.auto > 1) {
			autoAnualPrice += data.auto >= 2 ? autoPlusOne * (data.auto - 1) : autoAnualPrice;
		} else if (data.auto === 0) {
			autoAnualPrice = 0;
		}
		autoMensualPrice = autoMensualPrice * data.auto;
	} else if (data.auto === 0) {
		autoAnualPrice = 0;
		autoMensualPrice = 0;
		autoPlusOne = 0;
	}

	if (data.pet >= 1) {
		if (data.auto >= 1 || data.home >= 1) {
			petAnualPrice = 0;
			petAnualPrice += motoPlusOne * data.pet;
		} else if (data.pet > 1) {
			petAnualPrice += data.pet >= 2 ? petAnualPrice * (data.pet - 1) : petAnualPrice;
		} else if (data.pet === 0) {
			petAnualPrice = 0;
		}
		petMensualPrice = petMensualPrice * data.pet;
	} else if (data.pet === 0) {
		petAnualPrice = 0;
		petMensualPrice = 0;
		autoPlusOne = 0;
	}

	if (data.home >= 1) {
		if (data.home > 1) {
			homeAnualPrice += data.home >= 2 ? homePlusOne * (data.home - 1) : homeAnualPrice;
		} else if (data.home === 0) {
			homeAnualPrice = 0;
		}
		homeMensualPrice = homeMensualPrice * data.home;
	} else if (data.home === 0) {
		homeAnualPrice = 0;
		homeMensualPrice = 0;
		autoPlusOne = 0;
	}

	const totalAnual = autoAnualPrice + petAnualPrice + homeAnualPrice;
	const mensualAnual = autoMensualPrice + petMensualPrice + homeMensualPrice;
	const anualPerc = Math.ceil(100 - (totalAnual * 100) / (mensualAnual * 2));

	return {
		autoAnualPrice: autoAnualPrice,
		petAnualPrice: petAnualPrice,
		homeAnualPrice: homeAnualPrice,
		autoMensualPrice: autoMensualPrice,
		petMensualPrice: petMensualPrice,
		homeMensualPrice: homeMensualPrice,
		anualPrice: autoAnualPrice + petAnualPrice + homeAnualPrice,
		anualPerc: anualPerc,
		mensualPrice: autoMensualPrice + petMensualPrice + homeMensualPrice,
		mensualPerc: 0,
	};
};

const calculate = (data: Count) => {
	const totalData = getPrice(data);
	return totalData;
};

const formatPrice = (value: number, branch: string, digits: number): string => {
	if (!value) {
		return '';
	}
	value = Number(value);
	const locales = {
		co: 'es-ES',
		pr: 'en-US',
		cr: 'en-US',
		pty: 'en-US',
	}[branch];
	const fractionDigits = branch === 'co' ? 0 : digits;
	return value.toLocaleString(locales, {
		minimumFractionDigits: fractionDigits,
		maximumFractionDigits: fractionDigits,
	});
};

export default { getPrice, calculate, formatPrice };
