import React from 'react';
import './ByConnect.scss';
import segurosFalabellaLogo from 'images/svg/seguros-falabella-logo.svg';
import logoConnect from 'images/svg/logo-completo.svg';

export const ByConnect: React.FC = () => {
	return (
		<div className="by__connect__container">
			<div className="by__connect__content">
				<div className="by__connect__logos ">
					<img className="falabella" src={segurosFalabellaLogo} alt="Logo Falabella" />
					<img className="connect" src={logoConnect} alt="Logo Connect" />
				</div>
				<div>
					<div className="by__connect__titles">
						Agencia de seguros Falabella <p>en alianza con Connect Assistance</p>
					</div>
					<p className="by__connect__text">
						Alianza enfocada en ayudar personas, resolviendo problemas ordinarios con soluciones extraordinarias a
						través del equipo correcto, tecnología e innovación centradas en el usuario.
					</p>
				</div>
			</div>
		</div>
	);
};
