import React, { useEffect, useState } from 'react';
import './Banner-bono.scss';
import CloseBtnWhite from 'images/svg/close-btn-white.svg';
import { useHistory, useLocation } from 'react-router-dom';
import { getPromoBonoActive } from 'helpers/PromoActived';
import { PopupExistProcess } from 'components/Popup-exist-porcess/Popup-exist-porcess';
export const BannerBono: React.FC = () => {
	const history = useHistory();
	const [isVisible, setIsVisible] = useState(false);
	const [showPopup, setShowPopup] = useState<boolean>(false);
	const [texts, setText] = useState({
		desktop: '',
		mobile: '',
		url: '',
		textPopup: '',
	});
	const { pathname } = useLocation();
	useEffect(() => {
		if ('/servicios-shared/auto' === pathname) {
			setText({
				desktop:
					'Compra una asistencia de movilidad y recibe un bono de gasolina de $50mil <small>valido hasta el 31 DE AGOSTO</small>',
				mobile: 'FALABELLA TE REGALA un bono de gasolina de $50mil por la compra de tu asistencia de movilidad',
				url: 'https://connect-assistant-public-assets.s3.amazonaws.com/falabella/TyC_ASISTENCIA_MOVILIDAD.pdf',
				textPopup:
					'Recibe un <strong> bono de gasolina </strong> de <strong>$50mil</strong> por la compra de <strong>una asistencia de movilidad</strong>',
			});
		} else {
			setText({
				desktop:
					'Compra una asistencia de mascota y recibe un bono Falabella de $50mil <small>valido hasta el 31 DE AGOSTO</small>',
				mobile: 'FALABELLA TE REGALA un bono de $50mil por la compra de tu asistencia de mascota',
				url: 'https://connect-assistant-public-assets.s3.amazonaws.com/falabella/TyC_ASISTENCIA_MASCOTA.pdf',
				textPopup:
					'Recibe un <strong> bono de Falabella </strong> de <strong>$50mil</strong> por la compra de <strong>una asistencia de mascota</strong>',
			});
		}
		if (getPromoBonoActive() && ['/servicios-shared/auto', '/servicios-shared/mascota'].includes(pathname)) {
			setIsVisible(true);
			setTimeout(() => {
				setShowPopup(true);
			}, 30000);
		} else {
			setIsVisible(false);
		}
	}, [pathname]);
	const closeBanner = () => {
		setIsVisible(false);
	};
	const goToBuyPlan = () => {
		history.push(`/cobertura`);
	};
	const closePopUp = () => {
		setShowPopup(false);
	};
	return isVisible ? (
		<>
			<div className="banner__container">
				<div className="banner__content" onClick={goToBuyPlan}>
					<div className="banner__info">
						<div className="banner__info__message">
							<div className="desktop" dangerouslySetInnerHTML={{ __html: texts.desktop }}></div>
							<div className="mobile" dangerouslySetInnerHTML={{ __html: texts.mobile }}></div>
							<div>
								<small>
									<a className="banner__bono__terms" href={texts.url} rel="noopener noreferrer" target="_blank">
										{' '}
										Aplican T&C
									</a>
								</small>
							</div>
						</div>
						<button className="banner__info__btn__buy">
							<span> Adquirir</span>
						</button>
					</div>
				</div>
				<button className="banner__btn__close" onClick={closeBanner}>
					<img src={CloseBtnWhite} alt="Cerrar oferta" />
				</button>
			</div>
			{showPopup && (
				<PopupExistProcess
					type="info"
					title=""
					message="¡Aprovecha y ahorra el 40% de la compra!"
					labelButton="Adquirir"
					closePopUp={goToBuyPlan}
					exitProcess={closePopUp}
					reverseCallToAction={true}
					terms={texts.url}
				>
					<h5 className="banner__text__titles">MEGA<span>OFERTAS</span></h5>
					<p className="banner__text__dates">VALIDO ENTRE EL 1 DE AGOSTO HASTA EL 31 DE AGOSTO DEL 2023</p>
					<div className="banner__text__info" dangerouslySetInnerHTML={{ __html: texts.textPopup }}></div>
				</PopupExistProcess>
			)}
		</>
	) : (
		<></>
	);
};
