import React, { useState, useContext } from 'react';
import './primary-content.scss';
import Container from '../container/container';
import { Link } from 'react-router-dom';
import carroHome from '../../images/carro_home.png';
import macotaHome from '../../images/mascota_home.png';
import rightArrow from '../../images/svg/right-arrow-fa.svg';
import leftArrow from '../../images/svg/left-arrow-fa.svg';
import carroHomeMobile from '../../images/carro_home_mobile.png';
import mascotaHomeMobile from '../../images/mascota_home_mobile.png';
import { pageEventFunnelStep } from 'helpers/dataLaye';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperInterface } from 'swiper/types';
import 'swiper/swiper.min.css';
import { GlobalContext } from 'context/GlobalState';
import { FunnelData } from 'interfaces/FunnelInterface';

const SlideContent: React.FC = () => {
	const [swiper, setSwiperInstance] = useState<SwiperInterface>();
	const { funnel, setFunnel } = useContext(GlobalContext);
	const [funnelData, setFunnelData] = useState<FunnelData>(funnel);

	const swiperData = [
		{
			title: 'Asistencia en carretera <br /> 100% digital',
			subTitle: 'Somos una plataforma 100% digital de Asistencia en carretera que se adapta y está atento a ti 24/7.',
			image: carroHome,
			imageMobile: carroHomeMobile,
			link: '/servicios-shared/auto',
			paymentLink: '/cobertura',
			plan: '1-0-0-A-co-fa',
			productType: 'autos',
		},
		{
			title: 'Asistencia para mascotas',
			subTitle: 'Descubre la mejor manera de mantener feliz y saludable a tu mascota',
			image: macotaHome,
			imageMobile: mascotaHomeMobile,
			link: '/servicios-shared/mascota',
			paymentLink: '/cobertura',
			plan: '0-1-0-A-co-fa',
			productType: 'mascotas',
		},
	];
	const setPlan = (planId: string, productType: 'autos' | 'mascotas') => {
		if (productType === 'autos') {
			pageEventFunnelStep('contratacion-asistencia', `web-asistencia-carro`, 'paso-pago');
		} else {
			pageEventFunnelStep('contratacion-asistencia', `web-asistencia-mascota`, 'paso-pago');
		}

		funnelData.step1.productType = productType;
		funnelData.step1.plan = planId;
		setFunnelData(funnelData);
		setFunnel(funnelData);
		const element = document.querySelector('#servicios');
		element?.scrollIntoView({ behavior: 'smooth' });
	};
	return (
		<div className="banner__primary ctr">
			<div className="arrow-slide arrow-slide-left" onClick={() => swiper?.slidePrev()}>
				<img src={leftArrow} alt="" />
			</div>
			<Swiper
				className="container__swiper"
				spaceBetween={10}
				centeredSlides={true}
				onSwiper={(swiper) => setSwiperInstance(swiper)}
				loop={true}
			>
				<Container>
					{swiperData.map((item, index) => (
						<SwiperSlide className={`slide-container`} key={'key' + index}>
							<picture className="slide-image">
								<source srcSet={item.image} media="(min-width: 600px)" />
								<img src={item.imageMobile} alt="MDN" />
							</picture>
							<div className="slide-content">
								<div>
									<h1
										dangerouslySetInnerHTML={{
											__html: item.title,
										}}
									></h1>
									<p className="slide-p-padding">{item.subTitle}</p>
									<Link
										to={item.link}
										className="button"
										onClick={() =>
											{
												if (item.productType === 'autos') {
													pageEventFunnelStep('contratacion-asistencia', `web-asistencia-carro`, 'paso-visita');
												} else {
													pageEventFunnelStep('contratacion-asistencia', `web-asistencia-mascota`, 'paso-visita');
												}
											}
											
										}
									>
										Conocer más
									</Link>
									<div className="slide-link">
										<Link
											to="/#servicios"
											className="mas-info underline"
											onClick={() => setPlan(item.plan, item.productType as 'autos' | 'mascotas')}
										>
											Cotizar y comprar
										</Link>
									</div>
								</div>
							</div>
						</SwiperSlide>
					))}
				</Container>
			</Swiper>
			<div className="arrow-slide arrow-slide-right" onClick={() => swiper?.slideNext()}>
				<img src={rightArrow} alt="" />
			</div>
		</div>
	);
};

export default SlideContent;
