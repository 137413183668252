import React, { useState, useEffect, useContext } from 'react';

import { useFormik } from 'formik';

import './contacto.scss';

import Container from 'components/container/container';
import TextInput from 'components/inputs/textfield/textfield';
import DropDownInput from 'components/inputs/dropdown/dropdown';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';

import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import Map from 'components/maps/maps';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Toaster from 'helpers/Toaster';
import { contactService } from 'services/ContactService';
import { AppContext } from 'helpers/Context';
import { Helmet } from 'react-helmet';
import OrangeButton from 'components/inputs/orange-button/orange-button';

interface IFormInputs {
	nombre: string;
	email: string;
	numeroTelefono: string;
	asunto: string;
	mensaje: string;
	branch: string;
}

export default function Contacto() {
	const { t } = useTranslation();
	const { branch, identify, setIdentifyStatus } = useContext(AppContext);
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [checked, setChecked] = useState(false);
	const [asuntoArray, setAsusntoArray] = useState(t('asuntoArray', { returnObjects: true }));

	useEffect(() => {
		if (!identify) {
			setIdentifyStatus(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setAsusntoArray(t('asuntoArray', { returnObjects: true }));
	}, [t, isLoading]);

	const initialValues: IFormInputs = {
		nombre: '',
		email: '',
		numeroTelefono: '',
		asunto: '',
		mensaje: '',
		branch: branch,
	};

	const validate = (values: any) => {
		const errors: any = {};
		if (!values.nombre) {
			errors.nombre = 'Campo Requerido';
		}

		if (!values.email) {
			errors.email = 'Campo Requerido';
		} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
			errors.email = 'Invalid email address';
		}

		if (!values.numeroTelefono) {
			errors.numeroTelefono = 'Campo Requerido';
		}

		if (!values.asunto) {
			errors.asunto = 'Campo Requerido';
		}

		if (!values.mensaje) {
			errors.mensaje = 'Campo Requerido';
		}

		return errors;
	};

	const formik = useFormik({
		initialValues,
		validate,
		onSubmit: async (values) => {
			setIsLoading(true);
			const data = {
				nombre: values.nombre,
				email: values.email,
				asunto: values.asunto,
				mensaje: values.mensaje,
				telefono: values.numeroTelefono,
				branch: branch,
			};

			const contactServiceResponse = await contactService(data);
			if (!contactServiceResponse) {
				Toaster.error('Se produjo un error.');
				setIsLoading(false);
				return;
			}

			setIsLoading(false);

			history.push('/');
		},
	});

	const handleChangeCheckbox = (event: any) => {
		setChecked(event.target.checked);
	};

	return (
		<div className="App">
			<Helmet>
				<title>Contacto - Connect Assistance</title>
				<meta
					name="description"
					content="Connect Assistance es la compañía #1 en Asistencia en Carretera y en el Hogar en Puerto Rico, Panamá, Costa Rica y Colombia"
				/>
				<meta
					property="og:description"
					content="Connect Assistance es la compañía #1 en Asistencia en Carretera y en el Hogar en Puerto Rico, Panamá, Costa Rica y Colombia"
				/>
			</Helmet>
			<div className="App-header">
				<Container>
					<div className="contacto__inner">
						<div className="container-left">
							<h1 className="title-green">Contacto</h1>
							<h1 className="h1">¡Hablemos!</h1>
							<p>Estamos a tu disposición para ayudarte y responder cualquier duda que puedas tener.</p>
							<form className={`contacto-form-container`} onSubmit={formik.handleSubmit}>
								<TextInput
									required
									name="nombre"
									className="contacto-form-container-input"
									id="outlined-basic"
									label="Nombre"
									onChange={formik.handleChange}
									value={formik.values.nombre}
									errorinput={formik.errors.nombre ? 1 : 0}
									errorlabel={formik.errors.nombre}
								/>

								<TextInput
									required
									type="email"
									name="email"
									className="contacto-form-container-input"
									id="outlined-basic"
									label="Email"
									onChange={formik.handleChange}
									value={formik.values.email}
									errorinput={formik.errors.email ? 1 : 0}
									errorlabel={formik.errors.email}
								/>

								<TextInput
									required
									name="numeroTelefono"
									className="contacto-form-container-input"
									id="outlined-basic"
									label="Número de telefono"
									onChange={formik.handleChange}
									value={formik.values.numeroTelefono}
									errorinput={formik.errors.numeroTelefono ? 1 : 0}
									errorlabel={formik.errors.numeroTelefono}
								/>

								<DropDownInput
									required
									className="full-width"
									data={asuntoArray}
									onChange={formik.handleChange}
									value={formik.values.asunto}
									label="Asunto"
									name="asunto"
									errorinput={formik.errors.asunto ? 1 : 0}
									errorlabel={formik.errors.asunto}
								></DropDownInput>

								<TextInput
									required
									wrapperClass="contacto-textarea"
									className="contacto-form-container-input"
									id="outlined-basic"
									label="Cuéntanos brevemente cómo podemos ayudarte..."
									name="mensaje"
									onChange={formik.handleChange}
									value={formik.values.mensaje}
									multiline={true}
									rows={8}
									errorinput={formik.errors.asunto ? 1 : 0}
									errorlabel={formik.errors.asunto}
								/>

								<div className="container-subscribete-container">
									<FormControlLabel
										control={
											<Checkbox
												icon={<CheckBoxOutlineBlankOutlinedIcon className="fill-transparent border-fill-green" />}
												checkedIcon={<DoneIcon className="border-fill-green background-green" />}
												checked={checked}
												onChange={handleChangeCheckbox}
												size="small"
											/>
										}
										label=""
									/>
									Suscríbete a nuestra newsletter
								</div>
								<OrangeButton handleClick={formik.handleSubmit} className="button pago-orange-btn button__submit">
									Envía
								</OrangeButton>
							</form>
						</div>
					</div>
				</Container>
				<div className="mapa">
					<Map wrapperClass="mapa" height lat={t('lat')} lng={t('lng')} />
				</div>
				<Container>
					<div className="paiz-contacto">
						<h2>Bogotá, Colombia</h2>
						<address>
							Carrera 14 #97-63, Piso 4 Bogotá
							<br />
							Cundinamarca, Colombia
							<br />
							<a href="tel:+57 601 15140473">+57 601 15140473</a>
						</address>
					</div>
				</Container>
			</div>
		</div>
	);
}
