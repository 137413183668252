import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';

import { AppContext } from 'helpers/Context';
import { GlobalContext } from 'context/GlobalState';
import { FunnelData, TotalPrice } from 'interfaces/FunnelInterface';

import './cobertura-activar.scss';
import OrangeButton from 'components/inputs/orange-button/orange-button';
import RadioButton from 'components/inputs/radio-button/radio-button';
import CarIcon from 'images/home/servicios/car-icon.svg';
import mascota from 'images/home/servicios/mascota.svg';
import FunnelHeader from 'components/funnel/header/header';
import FunnelFooterDesktop from 'components/funnel/footer/desktop/funnel-footer-desktop';
import FunnelFooterMobile from 'components/funnel/footer/mobile/funnel-footer-mobile';

import DoneIcon from '@material-ui/icons/Done';
import Pricing from 'helpers/Pricing';
import Toaster from 'helpers/Toaster';
import { createLead } from 'services/LeadService';
import { FormControlLabel } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import FunnelResumen from '../resumen/resumen';
import FooterLogos from 'components/footer-logos/footer-logos';
import { pageEvent, pageViewEvent } from 'helpers/dataLaye';
import * as constants from 'helpers/Constants';
import { getCupon } from 'services/SubscriptionService';
import { useFormik } from 'formik';
import LoadingDialog from 'helpers/LoadingDialog/loadingDialog';

const FunnelCoberturaActivarPlan = () => {
	const { funnel, setFunnel } = useContext(GlobalContext);
	const [funnelData, setFunnelData] = useState<FunnelData>(funnel);
	let sub = useParams();
	const producto = sub['producto'];
	funnelData.step1.plan = producto === 'autos' ? '1-0-0-A-co-fa' : '0-1-0-A-co-fa';
	funnelData.step1.productType = producto;

	const history = useHistory();
	const { setShowStatus, branch, identify, setIdentifyStatus } = useContext(AppContext);
	const [vehicleCount, setVehicleCount] = useState(producto === 'autos' ? 1 : 0);
	const [petCount, setPetCount] = useState(producto === 'mascotas' ? 1 : 0);
	const [homeCount] = useState(funnelData.step1.cobertura.hogar);
	const [pago, setPago] = useState(funnelData.step1.pago);
	const [total, setTotal] = useState<TotalPrice>(funnelData.total);
	const [checkVehicle, setCheckedVehicle] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [errorCoupon, setErrorCoupon] = useState(false);
	const [errorInput, setErrorInput] = useState(false);
	const [priceWithDiscount, setTotalWithDiscount] = useState<any | null>(null);
	const [priceSaved, setPriceSaved] = useState<any | null>(null);
	const [focusCoupon, setFocusButton] = useState(false);
	const [validCoupon, setValidCoupon] = useState(funnelData.step3.validCoupon);
	const [discount, setDiscount] = useState(funnelData.step3.discount);
	const [coupon, setCoupon] = useState<any>(null);
	const [idCoupon, setIdCoupon] = useState(funnelData.step3.couponId);
	const [hasDiscount, setHasDiscount] = useState(funnelData.step3.hasDiscount);
	const [idPromotionCode, setIdPromotionCode] = useState(funnelData.step3.promotionCodeId);
	const [infoDiscount, setInfoDiscount] = useState({
		mensual: {
			percentage: 0,
			valor: 0,
		},
		anual: { percentage: 0, valor: 0 },
	});
	useEffect(() => {
		if (funnelData.step1.productType === 'autos' ) {
			pageViewEvent('Asistencias Carro', 'https://planesasistencia.connect.com.co/carro/plan');
		} else {
			pageViewEvent('Asistencias Mascotas', 'https://planesasistencia.connect.com.co/mascota/plan');
		}
		if (!identify) {
			setIdentifyStatus(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const calculate = (country) => {
		const data = {
			auto: vehicleCount,
			pet: petCount,
			home: homeCount,
			pago: pago,
			branch: country,
		};
		setTotal(Pricing.calculate(data));
		funnelData.step1.cobertura.auto = vehicleCount;
		funnelData.step1.cobertura.pet = petCount;
		setFunnelData(funnelData);
	};

	const calculateWithoutHook = async (
		vehicle: number,
		pet: number,
		home: number,
		anualMensual: string,
		branch: string
	) => {
		const data = {
			auto: vehicle,
			pet: pet,
			home: home,
			pago: anualMensual,
			branch: branch,
		};
		return Pricing.calculate(data);
	};

	useEffect(() => {
		setShowStatus(false);

		if (pago) {
			calculate('co');
		}
		return () => {
			setShowStatus(true);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setShowStatus, pago, vehicleCount, petCount, homeCount]);

	useEffect(() => {
		const fetchFunnelId = async () => {
			const getFunnelId = await createLead(branch.toUpperCase(), funnel.utm);
			funnelData.subscriptionData.leadMongoId = getFunnelId.data.data.funelId;
			setFunnelData(funnelData);
		};
		fetchFunnelId();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [funnelData]);

	const handleVehicleChange = async (type: string) => {
		pageEvent('asistencias-carro', 'paso-plan', `vehiculos`);
		switch (type) {
			case 'plus':
				if (vehicleCount < 3) {
					setVehicleCount(vehicleCount + 1);
					const tmpTotal = await calculateWithoutHook(vehicleCount + 1, 0, 0, pago, branch);
					await calculateFunnel(vehicleCount + 1, 0, 0, pago, tmpTotal);
				}
				break;
			case 'minus':
				if (vehicleCount > 0) {
					setVehicleCount(vehicleCount - 1);
					const tmpTotal = await calculateWithoutHook(vehicleCount - 1, 0, 0, pago, branch);
					await calculateFunnel(vehicleCount - 1, 0, 0, pago, tmpTotal);
				}
				break;
		}
	};

	const handleMotoChange = async (type: string) => {
		pageEvent('asistencias-mascota', 'paso-plan', `mascotas`);
		switch (type) {
			case 'plus':
				if (petCount < 3) {
					setPetCount(petCount + 1);
					const tmpTotal = await calculateWithoutHook(vehicleCount, petCount + 1, homeCount, pago, branch);
					await calculateFunnel(0, petCount + 1, 0, pago, tmpTotal);
				}
				break;
			case 'minus':
				if (petCount > 0) {
					setPetCount(petCount - 1);
					const tmpTotal = await calculateWithoutHook(vehicleCount, petCount - 1, homeCount, pago, branch);
					await calculateFunnel(0, petCount - 1, 0, pago, tmpTotal);
				}
				break;
		}
	};

	const initialValues = {
		cupon: '',
	};

	const formik = useFormik({
		initialValues,
		onSubmit: async (values) => {
			console.log('values', values);
		},
	});

	const calculateFunnel = async (vehicle: number, pet: number, home: number, montlyAnual: string, tmpTotal: any) => {
		const planSelected = vehicle + '-' + pet + '-' + home + '-' + montlyAnual.charAt(0).toUpperCase() + '-co-fa';

		const data = {
			cobertura: {
				auto: vehicle,
				pet: pet,
				hogar: home,
			},
			pago: montlyAnual,
			plan: planSelected,
			productType: funnelData.step1.productType,
		};

		funnelData.total = tmpTotal;
		funnelData.step1 = data;

		setFunnelData(funnelData);
		setFunnel(funnelData);

		const amountTotal = getTotal();
	};

	const handleContinuarBtn = () => {
		const planSelected =
			vehicleCount + '-' + petCount + '-' + homeCount + '-' + pago.charAt(0).toUpperCase() + '-co-fa';
		const cubiertaTotal = vehicleCount + petCount + homeCount;

		if (cubiertaTotal === 0) {
			Toaster.error('Para continuar, escoja un plan');
			return;
		}

		if (vehicleCount > 0) {
			if (!checkVehicle) {
				Toaster.error('Por favor añadir propiedades del 1990+ para continuar');
				return;
			}
		}

		const data = {
			cobertura: {
				auto: vehicleCount,
				pet: petCount,
				hogar: homeCount,
			},
			pago: pago,
			plan: planSelected,
			productType: funnelData.step1.productType,
		};

		funnelData.total = total;
		funnelData.step1 = data;
		const amountTotal = getTotal();

		setFunnelData(funnelData);
		setFunnel(funnelData);
		history.push('/activar-plan-falabella/' + funnelData.step1.plan);
	};

	const checkVehicleYearBox = () => {
		if (vehicleCount >= 1) {
			return (
				<div className="cobertura-select-checkbox-container">
					<div className="title">
						{vehicleCount === 1 ? 'El auto es del 1990 o más nuevo' : 'Los autos son del 1990 o más nuevos'}
					</div>
					<div className="checkbox">
						<FormControlLabel
							control={
								<Checkbox
									checked={checkVehicle}
									onChange={handleAutoYearCheckbox}
									icon={<CheckBoxOutlineBlankOutlinedIcon className="fill-transparent border-fill-green" />}
									checkedIcon={<DoneIcon className="border-fill-green background-green" />}
								/>
							}
							label=""
						/>
					</div>
				</div>
			);
		} else {
			return null;
		}
	};
	const handleFocus = (strCoupon: string) => {
		formik.setFieldValue('cupon', strCoupon);
		if (strCoupon.length > 0) {
			setFocusButton(true);
		} else {
			setFocusButton(false);
		}
	};
	const handleOnChange = (strCoupon: string) => {
		setInfoDiscount({
			mensual: {
				percentage: 0,
				valor: 0,
			},
			anual: { percentage: 0, valor: 0 },
		});
		funnelData.step3.validCoupon = false;
		funnelData.step3.hasDiscount = false;
		funnelData.step3.coupon = '';
		funnelData.step3.discountAmount = 0;
		funnelData.step3.couponId = '';
		setTotalWithDiscount('');
		setDiscount('0');
		setPriceSaved(0);
		setHasDiscount(false);
		setIdCoupon('');
		setIdPromotionCode('');
		setFunnelData(funnelData);
		handleFocus(strCoupon);
	};

	const handleAutoYearCheckbox = (event: any) => {
		setCheckedVehicle(event.target.checked);
	};

	const calculatePriceSaved = (percent: any, num: any) => {
		const priceSaved: Number = (percent / 100) * num;
		return Number.parseFloat(priceSaved.toString()).toFixed(2);
	};

	const calculatePriceWithPercent = (percent: any, num: any) => {
		const priceTotal = ((percent * num) / 100).toFixed(2);
		return Number.parseFloat(priceTotal.toString()).toFixed(2);
	};
	const handleCupon = async (value: any) => {
		if (value.length > 4) {
			if (funnelData.step1.productType === 'autos') { 
				pageEvent('asistencias-carro', 'paso-plan', `aplicar-cupon`);
			} else {
				pageEvent('asistencias-mascota', 'paso-plan', `aplicar-cupon`);

			}
			setIsLoading(true);
			if (funnelData.step1.pago === 'mensual') {
				const hasRestrictedCouponMensual = cuponIsValid('mensual', value);
				if (hasRestrictedCouponMensual) {
					funnelData.step3.validCoupon = false;
					setFunnelData(funnelData);
					Toaster.error('El código ingresado es inválido');
					setIsLoading(false);
					setErrorCoupon(true);
					setErrorInput(true);
					setTotalWithDiscount('');
					setPriceSaved(0);
					setFocusButton(false);
					setValidCoupon(false);
					return;
				}
			}

			if (funnelData.step1.pago === 'anual') {
				const hasRestrictedCouponAnual = cuponIsValid('anual', value);
				if (hasRestrictedCouponAnual) {
					funnelData.step3.validCoupon = false;
					setFunnelData(funnelData);
					Toaster.error('El código ingresado es inválido');
					setIsLoading(false);
					setErrorCoupon(true);
					setErrorInput(true);
					setTotalWithDiscount('');
					setPriceSaved(0);
					setFocusButton(false);
					setValidCoupon(false);
					return;
				}
			}
			const { data } = await getCupon(value, branch, funnelData.step1.plan);
			if (data === undefined || data.cupon === undefined) {
				Toaster.error('El codigo ingresado no existe');
				setDiscount('');
				setIsLoading(false);
				setErrorCoupon(true);
				setPriceSaved(0);
				setErrorInput(true);

				return;
			}
			if (data.cupon.coupon.metadata.readOnly) {
				funnelData.step3.couponIsHidden = true;
				setFunnelData(funnelData);
			}

			if (data.cupon.active === false) {
				Toaster.error('Error. No existe el código de descuento');
				setIsLoading(false);
				setPriceSaved(0);
				return;
			}

			setCoupon(value);

			if (data.cupon.coupon.amount_off) {
				const tmpPago: any =
					funnelData.step1.pago === 'mensual' ? total.mensualPrice.toFixed(2) : total.anualPrice.toFixed(2);
				if (data.cupon.coupon.amount_off / 100 > tmpPago) {
					Toaster.error('Codigo inválido');
					setIsLoading(false);
					setErrorCoupon(true);
					setErrorInput(true);
					return;
				}

				setValidCoupon(true);
				setDiscount(data.cupon.coupon.amount_off);
				const tmpTotal = (tmpPago - data.cupon.coupon.amount_off / 100).toFixed(2);
				setTotalWithDiscount(tmpTotal);
				const tmpTotalSaved = data.cupon.coupon.amount_off / 100;
				funnelData.step3.validCoupon = true;
				funnelData.step3.hasDiscount = true;
				funnelData.step3.coupon = value;
				funnelData.step3.discountAmount = tmpTotalSaved;
				funnelData.step3.couponId = data.cupon.coupon.id;
				if (funnelData.step1.pago === 'mensual') {
					infoDiscount.mensual.percentage = ((data.cupon.coupon.amount_off / 100) * 100) / total.anualPrice;
					infoDiscount.mensual.valor = tmpTotalSaved;
				} else {
					infoDiscount.anual.percentage = ((data.cupon.coupon.amount_off / 100) * 100) / total.anualPrice;
					infoDiscount.anual.valor = tmpTotalSaved;
				}
				setInfoDiscount({ ...infoDiscount });
				setPriceSaved(tmpTotalSaved);
				setIsLoading(false);
				setHasDiscount(true);
				setIdCoupon(data.cupon.coupon.id);
				setIdPromotionCode(data.cupon.id);
				setErrorCoupon(false);
				setErrorInput(false);
				setIsLoading(false);
				setFunnelData(funnelData);
			} else {
				setValidCoupon(true);
				setDiscount(data.cupon.coupon.percent_off);

				const disc = data.cupon.coupon.percent_off;
				const tmpPago =
					funnelData.step1.pago === 'mensual' ? total.mensualPrice.toFixed(2) : total.anualPrice.toFixed(2);
				const tmpTotal = calculatePriceWithPercent(disc, tmpPago);
				const totalWithDiscount = parseFloat(tmpPago) - parseFloat(tmpTotal);
				setTotalWithDiscount(totalWithDiscount);
				const tmpTotalSaved = calculatePriceSaved(disc, tmpPago);
				funnelData.step3.validCoupon = true;
				funnelData.step3.hasDiscount = true;
				funnelData.step3.coupon = value;
				funnelData.step3.discountAmount = parseFloat(tmpTotalSaved);
				funnelData.step3.couponId = data.cupon.coupon.id;
				if (funnelData.step1.pago === 'mensual') {
					infoDiscount.mensual.percentage = data.cupon.coupon.percent_off;
					infoDiscount.mensual.valor = parseFloat(tmpTotalSaved);
				} else {
					infoDiscount.anual.percentage = data.cupon.coupon.percent_off;
					infoDiscount.anual.valor = parseFloat(tmpTotalSaved);
				}
				setInfoDiscount({ ...infoDiscount });
				setPriceSaved(tmpTotalSaved);
				setIsLoading(false);
				setHasDiscount(true);
				setIdCoupon(data.cupon.coupon.id);
				setIdPromotionCode(data.cupon.id);
				setErrorCoupon(false);
				setErrorInput(false);
				setFunnelData(funnelData);
			}

			return data;
		} else {
			setTotalWithDiscount('');
			setPriceSaved(0);
			setFocusButton(false);
		}
	};

	const cuponIsValid = (type: string, value: string) => {
		const restricted = type === 'mensual' ? constants.restrictedCouponsMensual : constants.restrictedCouponsAnual;
		const restrictedStrings = restricted?.split(',') || [];
		const hasRestrictedCoupon = restrictedStrings.indexOf(value) > -1;
		return hasRestrictedCoupon;
	};

	const getTotal = () => {
		const p = funnel.step1.pago === 'anual' ? funnel.total.anualPrice : funnel.total.mensualPrice;
		let finalPrice = 0;
		if (p && p > 0) {
			if (funnel.step3.discountAmount && funnel.step3.discountAmount > 0) {
				finalPrice = p - funnel.step3.discountAmount;
			} else {
				finalPrice = p;
			}
		}
		return finalPrice?.toFixed(2);
	};
	const setTypePago = (type: 'mensual' | 'anual') => {
		setPago(type);
		const label = type === 'mensual' ? 'Semestral' : 'Anual';
		if (funnelData.step1.productType === 'autos') { 
			pageEvent('asistencias-carro', 'paso-pago', `pago-${label.toLocaleLowerCase()}`);
		} else {
			pageEvent('asistencias-mascota', 'paso-pago', `pago-${label.toLocaleLowerCase()}`);

		}
		setPago(type);
		funnelData.step1.pago = type;
		if (type === 'mensual') {
			funnelData.step1.plan = vehicleCount + '-' + petCount + '-' + homeCount + '-M-co-fa';
		} else {
			funnelData.step1.plan = vehicleCount + '-' + petCount + '-' + homeCount + '-A-co-fa';
		}
		setFunnelData(funnelData);
		handleOnChange(formik.values.cupon);
		handleCupon(formik.values.cupon);
	};

	return (
		<div className="App">
			<LoadingDialog isLoading={isLoading}></LoadingDialog>
			<Helmet>
				<title>Cobertura - Connect Assistance</title>
				<meta name="description" content="Escoge el tipo de plan que necesitas" />
			</Helmet>
			<div className="App-header">
				<FunnelFooterMobile className="mobile" section="steps" active="cobertura" linkTo="pago" showChevron={true} />
				<FunnelHeader progress="1" />
			</div>
			<div className="mobile">
				<FunnelResumen
					show="mobile"
					data={funnelData}
					pago={pago === 'mensual' ? total.mensualPrice : total.anualPrice}
					price={
						pago === 'mensual'
							? total.mensualPrice - funnel.step3.discountAmount
							: total.anualPrice - funnel.step3.discountAmount
					}
					discount={priceSaved}
				/>
			</div>
			<div className="funnel__main">
				<div className="funnel__main__inner">
					<div className="funnel__cols">
						<div className="funnel__col funnel__col-1">
							<div className="step-header">
								<h1 className="cobertura-h1">Completa los detalles de tu plan</h1>
								<p className="cobertura-p text-left">
									Estamos listo para activar el plan de{' '}
									{funnelData.step1.productType === 'autos' ? 'carros' : 'mascotas'}.
								</p>
							</div>
							<div className="cobertura-select-content">
								<div className="cobertura-select-items">
									<div className="cobertura-select-item-type-content">
										<img
											className="cobertura-auto"
											src={funnelData.step1.productType === 'autos' ? CarIcon : mascota}
											alt=""
										/>
									</div>
									<div className="cobertura-select-item-type-label">
										<p>{funnelData.step1.productType === 'autos' ? 'Carros' : 'Mascotas (perros o gatos)'}</p>
									</div>
									<div className="cobertura-select-item-qty-content">
										<Link
											to="#"
											onClick={async () =>
												funnelData.step1.productType === 'autos'
													? await handleVehicleChange('minus')
													: handleMotoChange('minus')
											}
											className=" minus-icon"
										>
											<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path
													d="M9.0906 17.595C13.693 17.595 17.4239 13.8641 17.4239 9.26168C17.4239 4.65931 13.693 0.928345 9.0906 0.928345C4.48822 0.928345 0.757263 4.65931 0.757263 9.26168C0.757263 13.8641 4.48822 17.595 9.0906 17.595Z"
													fill="#49AD33"
												/>
												<path
													d="M5.75726 9.26168H12.4239M17.4239 9.26168C17.4239 13.8641 13.693 17.595 9.0906 17.595C4.48822 17.595 0.757263 13.8641 0.757263 9.26168C0.757263 4.65931 4.48822 0.928345 9.0906 0.928345C13.693 0.928345 17.4239 4.65931 17.4239 9.26168Z"
													stroke="white"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>
										</Link>
										<div className="cobertura-qty-label">
											{funnelData.step1.productType === 'autos' ? vehicleCount : petCount}
										</div>
										<Link
											to="#"
											onClick={() =>
												funnelData.step1.productType === 'autos'
													? handleVehicleChange('plus')
													: handleMotoChange('plus')
											}
											className=" plus-icon"
										>
											<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path
													d="M9.09054 17.595C13.6929 17.595 17.4239 13.8641 17.4239 9.26168C17.4239 4.65931 13.6929 0.928345 9.09054 0.928345C4.48816 0.928345 0.757202 4.65931 0.757202 9.26168C0.757202 13.8641 4.48816 17.595 9.09054 17.595Z"
													fill="#49AD33"
												/>
												<path
													d="M9.09054 5.92834V9.26168M9.09054 9.26168V12.595M9.09054 9.26168H5.7572M9.09054 9.26168H12.4239M17.4239 9.26168C17.4239 13.8641 13.6929 17.595 9.09054 17.595C4.48816 17.595 0.757202 13.8641 0.757202 9.26168C0.757202 4.65931 4.48816 0.928345 9.09054 0.928345C13.6929 0.928345 17.4239 4.65931 17.4239 9.26168Z"
													stroke="white"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>
										</Link>
									</div>
								</div>

								{funnelData.step1.productType === 'autos' && checkVehicleYearBox()}
							</div>
							<div className="cobertura-payment-content">
								<RadioButton
									handleClick={() => setTypePago('mensual')}
									className={`${pago === 'mensual' ? 'active' : ''}`}
									price={`${Pricing.formatPrice(total.mensualPrice - funnel.step3.discountAmount, branch, 2)}`}
									checkedStatus={pago === 'mensual' ? true : false}
									label={'Pago Semestral'}
									discount={
										!cuponIsValid('mensual', formik.values.cupon)
											? Pricing.formatPrice(infoDiscount.mensual.valor, branch, 2)
											: ''
									}
								></RadioButton>
								<RadioButton
									handleClick={() => setTypePago('anual')}
									className={`${pago === 'anual' ? 'active' : ''}`}
									price={`${Pricing.formatPrice(total.anualPrice - funnel.step3.discountAmount, branch, 2)}`}
									pricePerc={total.anualPerc + infoDiscount.anual.percentage}
									checkedStatus={pago === 'anual' ? true : false}
									label={'Pago Anual'}
									discount={
										!cuponIsValid('anual', formik.values.cupon)
											? Pricing.formatPrice(infoDiscount.anual.valor, branch, 2)
											: ''
									}
								>
									<div className="active-indicator"></div>
								</RadioButton>
							</div>
							{errorCoupon && <div className="textfield-error-label">Cupón inválido</div>}
							<OrangeButton handleClick={handleContinuarBtn} className="full-width funnel-advance mobile">
								Continuar
							</OrangeButton>
						</div>
						<div className="funnel__col funnel__col-2 ctr">
							<FooterLogos />
							<div className="desktop">
								<FunnelResumen
									show="desktop"
									data={funnelData}
									pago={pago === 'mensual' ? total.mensualPrice : total.anualPrice}
									price={
										pago === 'mensual'
											? total.mensualPrice - funnel.step3.discountAmount
											: total.anualPrice - funnel.step3.discountAmount
									}
									discount={priceSaved}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<FunnelFooterDesktop
				handleClick={handleContinuarBtn}
				section="Planes"
				active="cobertura"
				linkTo="info"
				showChevron={true}
			/>
		</div>
	);
};

export default FunnelCoberturaActivarPlan;
