import { resetData } from "./GlobalState";

export default (state: any, action: any) => {
	switch (action.type) {
		case 'SET_FUNNEL':
			return { ...state, funnel: action.data };
		case 'RESET':
			return { ...state, funnel: resetData };

		default:
			return state;
	}
};
