import React, { useEffect, useState } from 'react';
import './Banner-terms-and-conditions.scss';

const BannerTermsAndConditions: React.FC = () => {
	const [isVisible, setIsVisible] = useState(true);
	function sumarDias(fecha, dias) {
		fecha.setDate(fecha.getDate() + dias);
		return fecha;
	}
	useEffect(() => {
		const data = localStorage.getItem('asftermsandconditions') as string;
        if (data) {
            const dataParse = JSON.parse(data);
            const time = new Date(dataParse.time);
            var today = new Date();
            if (today.getTime() > time.getTime()) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        }
	}, []);

	const aceptAsfTermsAndConditions = () => {
		const data = {
			aceptAsfTermsAndConditions: true,
			time: sumarDias(new Date(), +30),
		};
		localStorage.setItem('asftermsandconditions', JSON.stringify(data));
		setIsVisible(false);
	};
	return isVisible ? (
		<div className="banner__terms__and__conditions__container">
			<div className="banner__terms__and__conditions__text">
				Al navegar en nuestro sitio aceptas el uso de cookies para mejorar tu experiencia. Revisa nuestra{' '}
				<a
					href="https://sfestaticos.blob.core.windows.net/regional/assets/Politica_de_cookies_Octubre_2023_996ba92d01.pdf?updated_at=2023-10-30T14:18:18.948Z"
					target="_blank"
					rel="noopener noreferrer"
				>
					Política de Cookies
				</a>{' '}
				y nuestra{' '}
				<a href="https://www.segurosfalabella.com.co/politica-informacion/" target="_blank" rel="noopener noreferrer">
					Política de tratamiento de Datos Personales
				</a>
				.
			</div>
			<button onClick={aceptAsfTermsAndConditions} className="banner__terms__and__conditions__button">
				Aceptar
			</button>
		</div>
	) : (
		<></>
	);
};

export default BannerTermsAndConditions;
