import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import SlideContent from 'components/primary-content/primary-content';
import ExperienciaConnect from 'components/experiencia-connect/experiencia-connect';
import Servicios from 'components/home/servicios/servicios';
import NuestrosClientes from 'components/home/nuestros-clientes/nuestros-clientes';

import ExpImg1 from 'images/svg/Tecnologia-Innovadora.svg';
import ExpImg2 from 'images/svg/Cubrimos-Cada-Rincon.svg';
import ExpImg3 from 'images/svg/A-tu-Lado.svg';
import { AppContext } from 'helpers/Context';
import { Helmet } from 'react-helmet';
import { Link, useLocation } from 'react-router-dom';

import './home.scss';
import { pageEvent, pageEventFunnelStep } from 'helpers/dataLaye';
import { ByConnect } from 'components/ByConnect/ByConnect';
import { GlobalContext } from 'context/GlobalState';
import { FunnelData } from 'interfaces/FunnelInterface';

export default function Home() {
	const title1 = 'Tecnología innovadora <br />tu servicio';
	const subTitle1 =
		'Gracias al uso de herramientas tecnológicas pioneras y al smart dispatch system, garantizamos un despacho rápido, ofreciéndote información de progreso en tiempo real.';
	const title2 = 'Calidad de servicio  <br />garantizado';
	const subTitle2 =
		'Nuestros clientes están profundamente satisfechos con nuestros servicios. Más del 95% de ellos nos dan opiniones muy positivas sobre la asistencia que reciben. Nos hemos comprometido a hacer todo lo posible para garantizar la excelencia en los servicios que ofrecemos';
	const title3 = 'A tu lado en todo <br />momento';
	const subTitle3 =
		'Nuestro equipo está a disponible 24/7 los 365 días del año para darte servicio cuando más lo necesites de manera segura, transparente y veloz.';
	const nuestrosClienteJson = {};

	const { identify, setIdentifyStatus } = useContext(AppContext);
	const { hash } = useLocation();

	const { funnel, setFunnel, reset } = useContext(GlobalContext);
	const [funnelData, setFunnelData] = useState<FunnelData>(funnel);
	let sub = useParams();

	let { search } = useLocation();
	const paramsString = decodeURIComponent(search);
	let params = new URLSearchParams(paramsString);

	useEffect(() => {
		const agentCode = params.get('ref');
		funnelData.step2.agent_code = agentCode || '';
		setFunnelData(funnelData);
		setFunnel(funnelData);
	}, [funnel]);

	useEffect(() => {
		reset();
		if (!identify) {
			setIdentifyStatus(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (hash) {
			setTimeout(() => {
				const element = document.querySelector('#servicios');
				element?.scrollIntoView({ behavior: 'smooth' });
			}, 500);
		}
	}, [hash]);

	const setPlan = (planId: string, productType: 'autos' | 'mascotas') => {
		if (productType === 'autos') {
			pageEventFunnelStep('contratacion-asistencia', `web-asistencia-carro`, 'paso-plan');
		} else {
			pageEventFunnelStep('contratacion-asistencia', `web-asistencia-mascota`, 'paso-plan');
		}
		funnelData.step1.productType = productType;
		funnelData.step1.plan = planId;
		setFunnelData(funnelData);
		setFunnel(funnelData);
	};

	return (
		<div className="App">
			<Helmet>
				<title>Connect Assistance</title>
				<meta
					name="description"
					content="Connect Assistance es la compañía #1 en Asistencia en Carretera y en el Hogar en Puerto Rico, Panamá, Costa Rica y Colombia"
				/>
				<meta
					property="og:description"
					content="Connect Assistance es la compañía #1 en Asistencia en Carretera y en el Hogar en Puerto Rico, Panamá, Costa Rica y Colombia"
				/>
			</Helmet>
			<SlideContent />
			<ByConnect />
			<div className="experiencia-connect">
				<div className="experiencia-connect-content">
					<ExperienciaConnect title={title1} subTitle={subTitle1} reverse={false} img={ExpImg1} />
					<ExperienciaConnect title={title2} subTitle={subTitle2} reverse={true} img={ExpImg2} />
					<ExperienciaConnect title={title3} subTitle={subTitle3} reverse={false} img={ExpImg3} />
				</div>
				<div className="container__btns">
					<Link to="/cobertura" className="button ctr" onClick={() => setPlan('1-0-0-A-co-fa', 'autos')}>
						Comprar plan de Autos
					</Link>
					<Link to="/cobertura" className="button ctr" onClick={() => setPlan('0-1-0-A-co-fa', 'mascotas')}>
						Comprar plan de Mascotas
					</Link>
				</div>
			</div>

			<NuestrosClientes data={nuestrosClienteJson} />
			<Servicios id="servicios" />
		</div>
	);
}
