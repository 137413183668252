import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useHideOnScrolled = (topValue = 790) => {
	const [hidden, setHidden] = useState(false);
	const { hash } = useLocation();

	const handleScroll = () => {
		if (hash) {
			return;
		}
		const top = window.pageYOffset || document.documentElement.scrollTop;
		setHidden(top > topValue);
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);
	return hidden;
};

export default useHideOnScrolled;
