import React from 'react';
import './detail-plan.scss';
interface Props {
	data: InfoTable;
	template?: 'primary' | 'secondary';
	isOnlyAnual?: boolean;
}
export interface InfoTable {
	services: {
		header: {
			title: string;
			subTitle: string;
		};
		title_section: string;
		list: {
			service: string;
		}[];
	};
	plan_semestral: {
		header: {
			title: string;
			subTitle: string;
		};
		titles: {
			one: string;
			two: string;
		};
		list: {
			event: string;
			limit: string;
		}[];
	};
	plan_anual: {
		header: {
			title: string;
			subTitle: string;
		};
		titles: {
			one: string;
			two: string;
		};
		list: {
			event: string;
			limit: string;
		}[];
	};
}

export interface Header {
	title: string;
}

const DetailPlan: React.FC<Props> = ({ data, template, isOnlyAnual }) => {
	return (
		<div className={`detail__plan__container`}>
			{data && (
				<>
					<div className="detail__plan__container__section small">
						<div className="detail__plan__header">
							<div className="titles">
								<div>{data.services.header.title}</div>
								<div className="titles--primary">{data.services.header.subTitle}</div>
							</div>
							<div className="section">Servicios</div>
						</div>
						{data.services.list.map((item, index) => (
							<div
								key={`service-${index}`}
								className="detail__plan__body"
								dangerouslySetInnerHTML={{ __html: item.service }}
							></div>
						))}
					</div>
					{!isOnlyAnual && (
						<div className="detail__plan__container__section">
							<div className="detail__plan__header">
								<div className="titles">
									<div>{data.plan_semestral.header.title}</div>
									<div className="titles--primary">{data.plan_semestral.header.subTitle}</div>
								</div>
								<div className="section section--columns ">
									<div className="column" dangerouslySetInnerHTML={{ __html: data.plan_semestral.titles.one }}></div>
									<div className="divider">
										<div></div>
									</div>
									<div className="column" dangerouslySetInnerHTML={{ __html: data.plan_semestral.titles.two }}></div>
								</div>
							</div>
							{data.plan_semestral.list.map((item, index) => (
								<div key={`service-evento-${index}`} className="detail__plan__body body--row">
									<div className="column" dangerouslySetInnerHTML={{ __html: item.event }}></div>
									<div className="divider">
										<div></div>
									</div>
									<div
										className="column"
										key={`service-limit-${index}`}
										dangerouslySetInnerHTML={{ __html: item.limit }}
									></div>
								</div>
							))}
						</div>
					)}
					<div className="detail__plan__container__section">
						<div className="detail__plan__header">
							<div className="titles">
								<div>{data.plan_anual.header.title}</div>
								<div className="titles--primary">{data.plan_anual.header.subTitle}</div>
							</div>
							<div className="section section--columns ">
								<div className="column" dangerouslySetInnerHTML={{ __html: data.plan_anual.titles.one }}></div>
								<div className="divider">
									<div></div>
								</div>
								<div className="column" dangerouslySetInnerHTML={{ __html: data.plan_anual.titles.two }}></div>
							</div>
						</div>
						{data.plan_anual.list.map((item, index) => (
							<div key={`service-evento-${index}`} className="detail__plan__body body--row">
								<div className="column" dangerouslySetInnerHTML={{ __html: item.event }}></div>
								<div className="divider">
									<div></div>
								</div>
								<div
									className="column"
									key={`service-limit-${index}`}
									dangerouslySetInnerHTML={{ __html: item.limit }}
								></div>
							</div>
						))}
					</div>
				</>
			)}
		</div>
	);
};

export default DetailPlan;
