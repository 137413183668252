import React, { useState, useContext } from 'react';
import './servicios.scss';
import CarIcon from '../../../images/home/servicios/car-icon.svg';
import mascota from '../../../images/home/servicios/mascota.svg';
import { Link } from 'react-router-dom';
import { getFunnelUrl } from '../../../helpers/FunnelRedirection';
import { GlobalContext } from 'context/GlobalState';
import { FunnelData } from 'interfaces/FunnelInterface';
import { IProductType } from 'interfaces/producti-type.inteface';

const Servicios: React.FC<any> = ({ id }) => {
	const linkTo = getFunnelUrl();
	const { funnel, setFunnel } = useContext(GlobalContext);
	const [funnelData, setFunnelData] = useState<FunnelData>(funnel);
	const setPlan = (productType: IProductType) => {
		funnelData.step1.plan = productType === 'autos' ? '1-0-0-A-co-fa' : '0-1-0-A-co-fa';
		funnelData.step1.productType = productType;
		setFunnelData(funnelData);
		setFunnel(funnelData);
	};
	return (
		<div className="servicios" id={id}>
			<h1 className="title-green ctr">Servicios</h1>
			<div className="servicios__inner">
				<div className="servicios__left ctr-mobile">
					<h2>Tenemos el plan ideal para tí</h2>
					<p>
						Encuentra el plan que se adapte perfectamente a tus necesidades, sin importar si se trata de proteger a tu
						mascota o tu auto. Nuestros planes están hechos específicamente para cubrir cada detalle sin dejar nada por
						fuera, garantizando que recibas una solución profesional y completa para cuidar de lo que más te importa.
					</p>
				</div>
				<div className="servicios__right">
					<Link to={linkTo} onClick={() => setPlan('autos')}>
						<div className="servicios__item auto margin">
							<div className="servicios__item__img">
								<img src={CarIcon} alt="Auto" />
							</div>
							<h4>Asistencia para autos</h4>
						</div>
					</Link>
					<Link to={linkTo} onClick={() => setPlan('mascotas')}>
						<div className="servicios__item auto">
							<div className="servicios__item__img">
								<img src={mascota} alt="Auto" />
							</div>
							<h4>Mascotas (perros o gatos)</h4>
						</div>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default Servicios;
