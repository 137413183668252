import React, { useEffect, useContext } from 'react';
import './politicasSeguridadInformatica.scss';

import OrangeTitle from 'components/orange-title/orange-title';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
interface Props {}

const PoliticasSeguridadInformatica: React.FC<Props> = () => {
	return (
		<div className="App empleos">
			<Helmet>
				<title>Políticas - Connect Assistance</title>
				<meta
					name="description"
					content="Connect Assistance es la compañía #1 en Asistencia en Carretera y en el Hogar en Puerto Rico, Panamá, Costa Rica y Colombia"
				/>
				<meta
					property="og:description"
					content="Connect Assistance es la compañía #1 en Asistencia en Carretera y en el Hogar en Puerto Rico, Panamá, Costa Rica y Colombia"
				/>
			</Helmet>
			<div className="container">
				<div style={{ marginTop: '100px' }}></div>
				<h2>Política de Seguridad Informática.</h2>
				<p>
					<strong>OBJETIVOS</strong>
				</p>
				<p>
					- La pol&iacute;tica de seguridad inform&aacute;tica (PSI) es un instrumento de la organizaci&oacute;n para
					concientizar a cada uno de sus miembros y colaboradores sobre la importancia de la informaci&oacute;n
					confidencial que es pieza determinante e indispensable para el &eacute;xito del negocio.
				</p>
				<p>
					Esta pol&iacute;tica es un medio de comunicaci&oacute;n con los usuarios, jefes y/o gerentes. La PSI establece
					el canal formal de actuaci&oacute;n del personal, con relaci&oacute;n a los recursos y servicios
					inform&aacute;ticos y que hace reconocer la informaci&oacute;n como uno de sus principales activos as&iacute;
					como, un motor de intercambio y desarrollo en el &aacute;mbito de sus negocios. Se establece la
					obligaci&oacute;n de vigilancia del personal por el uso y limitaciones de los recursos y servicios
					inform&aacute;ticos cr&iacute;ticos de la compa&ntilde;&iacute;a.
				</p>
				<p>
					<strong>DEC&Aacute;LOGO DE SEGURIDAD</strong>
				</p>
				<p>
					CONNECT ASSISTANCE considera la informaci&oacute;n, activos y colaboradores elementos fundamentales para el
					desarrollo de su negocio, en ese sentido vela por su protecci&oacute;n y seguridad integral.
				</p>
				<ol>
					<li>
						Toda Informaci&oacute;n referente al negocio, como por ejemplo, contratos internos, documentos de clientes,
						mapa de proveedores, el software, la lista de clientes y datos de contacto, informaci&oacute;n financiera,
						comercial y legal, m&eacute;todos, procedimientos e instrucciones, pol&iacute;ticas del negocio,
						informaci&oacute;n de sus empleados y colaboradores, y en general toda informaci&oacute;n que apoye el
						&eacute;xito del negocio, son un activo de car&aacute;cter confidencial y es propiedad intelectual de
						CONNECT ASSISTANCE quien se encarga de definir los roles y niveles de acceso a la misma.
					</li>
					<li>
						Como cualquier activo, la informaci&oacute;n debe tener un due&ntilde;o responsable asignado de acuerdo a
						los roles sobre los procesos, encargado de velar por su confidencialidad, adecuado uso y
						distribuci&oacute;n. En su caso, tenga en cuenta que Usted no podr&aacute; realizar reproducciones de
						ninguna clase de la informaci&oacute;n. Usted solo se encuentra autorizado para la consulta con fines
						propios del negocio de CONNECT ASSISTANCE.
					</li>
					<li>
						Todo colaborador es responsable por velar por la seguridad de las personas, activos e informaci&oacute;n de
						la corporaci&oacute;n. Por seguridad de la informaci&oacute;n, los colaboradores no podr&aacute;n ingresar a
						las instalaciones de CONNECT ASSISTANCE cualquier dispositivo de almacenamiento conocido o por conocer,
						tales como y sin limitar, memorias USB, CD / DVD - RW, IPOD&rsquo;S, Tel&eacute;fonos Celulares.
					</li>
					<li>
						Todo Colaborador est&aacute; obligado a cumplir las pol&iacute;ticas, normas y lineamientos de seguridad que
						dicte la corporaci&oacute;n.
					</li>
					<li>
						Los colaboradores que tienen acceso a la informaci&oacute;n confidencial del negocio tienen la
						responsabilidad de guardar absoluta reserva de la misma evitando su difusi&oacute;n verbal,
						electr&oacute;nica o escrita a personal no autorizado en especial en actividades no laborales y/o sitios
						externos a las instalaciones de la empresa y deben emplear su perfil usuario y contrase&ntilde;a de forma
						personal e intransferible.
					</li>
					<li>
						Los colaboradores del negocio que tienen acceso a los sistemas de informaci&oacute;n y a las herramientas
						que permiten distribuci&oacute;n de la misma como: correo, carpetas compartidas en red, fax, fotocopiadora,
						impresi&oacute;n, correo electr&oacute;nico etc&hellip; deben usar estos medios con m&aacute;xima prudencia
						y total responsabilidad evitando enviar informaci&oacute;n sin autorizaci&oacute;n a destinatarios no
						autorizados.
					</li>
					<li>
						Los colaboradores de CONNECT ASSISTANCE son contratados invocando el principio de confianza en el personal y
						como tal deben abstenerse de acceder a informaci&oacute;n a la que no han sido autorizados as&iacute; como
						distribuir informaci&oacute;n a personas no autorizadas.
					</li>
					<li>
						Los colaboradores de CONNECT ASSISTANCE que tienen a cargo la custodia de informaci&oacute;n del negocio
						representada en medios verbales, escritos y/o electr&oacute;nicos debe garantizar que estos activos reposan
						en personal confiable y ambientes f&iacute;sicos y/o l&oacute;gicos seguros, exigiendo los elementos que
						considere necesarios para salvaguardar los activos de informaci&oacute;n.
					</li>
					<li>
						CONNECT ASSISTANCE facilita los mecanismos que garanticen la seguridad integral de las personas, activos e
						informaci&oacute;n, incluyendo planes de contingencia y establece los planes que permitan la continuidad de
						la operaci&oacute;n de la empresa en caso de falla mayor o desastre.
					</li>
					<li>
						CONNECT ASSISTANCE deber&aacute; mantener actualizados los roles de los colaboradores dentro de la
						corporaci&oacute;n que garanticen que el acceso a la informaci&oacute;n esta monitoreado por un due&ntilde;o
						del activo y un grupo de usuarios que acceden a &eacute;l sin causar riesgos a la confidencialidad,
						integridad y disponibilidad de los activos de informaci&oacute;n.&rdquo;
					</li>
					<li>
						<strong></strong> <strong>BUEN USO DEL CORREO ELECTR&Oacute;NICO</strong>
					</li>
				</ol>
				<p>
					CONNECT ASSISTANCE pone a disposici&oacute;n de sus colabores la herramienta de Correo Electr&oacute;nico con
					el fin de apoyar el intercambio y difusi&oacute;n apropiada de la informaci&oacute;n relacionada
					exclusivamente con el negocio. El correo electr&oacute;nico es de propiedad exclusiva de CONNECT ASSISTANCE y
					el empleado es consciente que la Corporaci&oacute;n o la empresa podr&aacute; hacer verificaciones en los
					correos electr&oacute;nicos, con el fin de evaluar la aplicaci&oacute;n de la pol&iacute;tica de
					confidencialidad.
				</p>
				<ol type="A" start={1}>
					<li>
						<strong></strong> <strong>Responsabilidades de los usuarios:</strong>
					</li>
				</ol>

				<ol>
					<li>Utilizar los servicios de forma responsable y racional para uso de las labores inherentes a su cargo.</li>
					<li>Este servicio es personal e intransferible. No facilitar su cuenta de correo a otra persona.</li>
					<li>Acceder al correo desde fuera de la oficina s&oacute;lo si ha sido autorizado.</li>
					<li>
						Verificar que los destinatarios de sus correos sean los indicados para evitar que llegue informaci&oacute;n
						a personas equivocadas.
					</li>
					<li>Firmar el correo, indicando: nombre, apellido, cargo, &aacute;rea, tel&eacute;fono y fax.</li>
					<li>
						Evitar las opciones de &ldquo;responder a todos&rdquo; y &ldquo;responder a todos con historia&rdquo; a
						menos que sea realmente necesario.
					</li>
					<li>Eliminar los anexos cuando responde sus correos.</li>
					<li>Evitar la distribuci&oacute;n masiva de los mensajes que contienen anexos.</li>
					<li>No difundir los correos cadenas, masivos o "bola de nieve".</li>
					<li>
						Cuando se vaya a retirar de su puesto de trabajo, a&uacute;n de forma moment&aacute;nea, desconectar la
						sesi&oacute;n de correo con el fin de evitar accesos de personas no autorizadas a su buz&oacute;n.
					</li>
					<li>Activar el ausente de oficina cuando se presente esta condici&oacute;n.</li>
					<li>Utilizar racionalmente el espacio de almacenamiento asignado y activar el archivado local.</li>
					<li>
						Eliminar sin abrir, los correos cuyo remitente o asunto sea sospechoso, para evitar el ataque de virus o
						hackers. Informar sobre cualquier se&ntilde;al de correos que puedan tener posible presencia de virus a
						jmarin@connect.cr.
					</li>
					<li>
						No leer ni remitir mensajes que no le correspondan por destinatario, &aacute;rea o nivel jer&aacute;rquico
						en la organizaci&oacute;n, Informar a quien envi&oacute; el mensaje sobre el error de destinatario.
					</li>
					<li>Cambiar o solicitar el cambio peri&oacute;dicamente de la contrase&ntilde;a de la cuenta de correo.</li>
					<li>No emplear desde la red privada correos en servidores p&uacute;blicos como HOTMAIL, YAHOO, etc.</li>
				</ol>
				<br></br>
				<ol type="A" start={2}>
					<li>
						<strong> Responsabilidades de los Jefes directos, Gerentes, Directores o responsables de</strong>{' '}
						<strong>usuarios para con el correo de sus colaboradores:</strong>
					</li>
				</ol>
				<ol>
					<li>
						Para los colaboradores cuyos cargos no tienen definido el uso del correo electr&oacute;nico, el jefe debe
						autorizar con el mayor criterio el uso de la herramienta y solicitarla directamente a su departamento de
						sistemas.
					</li>
					<li>Autorizar el acceso a un buz&oacute;n por parte de un tercero en casos especiales.</li>
					<li>Con el mayor criterio, autorizar el ingreso de sus colaboradores al correo desde fuera de la oficina.</li>
					<li>
						Supervisar el cumplimiento de las pol&iacute;ticas y buenas pr&aacute;cticas de uso de la herramienta.
					</li>
					<li>
						Coordinar con el &aacute;rea de Sistemas la difusi&oacute;n de correos de inter&eacute;s general en forma
						masiva y distribuirlos bajo el criterio y pol&iacute;ticas de la Corporaci&oacute;n.
					</li>
					<li>
						Informar al departamento de sistemas cuando se requiera copiar o guardar por m&aacute;s de (un) 1 mes el
						correo de alg&uacute;n colaborador que se ha retirado de la compa&ntilde;&iacute;a o ha cambiado de cargo.
					</li>
					<li>
						Informar al departamento de sistemas, cualquier novedad en cuanto al retiro o traslado de usuarios,
						indicando qu&eacute; debe hacerse con el correo de la persona que deja de usar la herramienta.
					</li>
					<li>
						Dar a conocer masivamente y fomentar las pol&iacute;ticas y buenas pr&aacute;cticas acerca del correo
						electr&oacute;nico.
					</li>
					<li>
						Revisar la conducta del empleado al infringir derechos de autor, propiedad intelectual o industrial,
						env&iacute;o de informaci&oacute;n obscena, pornogr&aacute;fica, injuriosa, calumniosa o que se constituya
						en una amenaza a la integridad de las personas y aliente conductas que puedan traducirse en ofensas o puedan
						comprometer la seguridad y confidencialidad de la informaci&oacute;n de CONNECT ASSISTANCE.
					</li>
					<li>Establecer y divulgar las sanciones establecidas en caso de incumplimiento de las pol&iacute;ticas.</li>
				</ol>
				<br></br>
				<ol type="A" start={3}>
					<li>
						<strong> Responsabilidades de Tecnolog&iacute;a:</strong>
					</li>
				</ol>
				<ol>
					<li>
						Efectuar la administraci&oacute;n, control y auditor&iacute;a de los servicios que preste con
						relaci&oacute;n al correo.
					</li>
					<li>
						Emitir informes de incidentes a las direcciones de las &aacute;reas afectadas, con copia al Corporativo de
						Sistemas.
					</li>
					<li>Emitir y coordinar la difusi&oacute;n de las buenas pr&aacute;cticas en el uso del correo.</li>
					<li>
						Asignar los nombres de buzones de correo de acuerdo al est&aacute;ndar vigente de la corporaci&oacute;n
						(primer letra del nombre m&aacute;s apellido) @CONNECT.COM.CO
					</li>
					<li>
						Asignar las cuentas con la cuota de almacenamiento est&aacute;ndar y configurarlas en los clientes de correo
						un buz&oacute;n sincronizado.
					</li>
					<li>
						<strong></strong> Re direccionar correos de colaboradores que han salido de la compa&ntilde;&iacute;a, con
						el fin de garantizar copia de seguridad de la informaci&oacute;n, a quien sea autorizado por gerencia para
						ser el nuevo responsable de la data.
					</li>
				</ol>
				<br></br>
				<ol type="A" start={4}>
					<li>
						<strong> Responsables de su aplicaci&oacute;n:</strong>
					</li>
				</ol>
				<p>
					Son responsables de su aplicaci&oacute;n todos los colaboradores de CONNECT ASSISTANCE y terceros
					especialmente autorizados para el uso del correo de la Corporaci&oacute;n.
				</p>
				<p>
					Basados en el establecimiento de estas pol&iacute;ticas y los valores Corporativos de CONNECT ASSISTANCE, es
					expectativa de la compa&ntilde;&iacute;a, el apoyo y cumplimiento de la presente pol&iacute;tica por cada una
					de las personas responsables, tal como se especifica tambi&eacute;n en los contratos de trabajo con los
					colaboradores y los acuerdos de confidencialidad con terceros
				</p>
				<p>
					<strong>II: BUEN USO DE CONTRASE&Ntilde;AS</strong>
				</p>
				<p>
					A365 habilita el acceso a sus aplicativos y servicios para apoyar el desarrollo de los procesos y el manejo
					apropiado de su informaci&oacute;n, utilizando solicitudes formales para todo lo que tenga que ver con
					solicitud de asignaci&oacute;n o actualizaci&oacute;n de usuarios, autorizaciones y contrase&ntilde;as,
					teniendo en cuenta las siguientes responsabilidades:
				</p>
				<ol type="A" start={1}>
					<li>
						<strong></strong> <strong>Responsabilidades de los usuarios:</strong>
					</li>
				</ol>
				<ol>
					<li>Utilizar los usuarios y contrase&ntilde;as de forma responsable.</li>
					<li>
						El usuario asignado a cualquier aplicativo o servicio es de uso estrictamente personal e intransferible y de
						car&aacute;cter exclusivo para ejercer labores inherentes a su cargo. Para el caso de terceros, &eacute;ste
						servicio se rige bajo las cl&aacute;usulas de confidencialidad del contrato pactado con CONNECT ASSISTANCE.
					</li>
					<li>
						Cuando se vaya a retirar de su puesto de trabajo de forma moment&aacute;nea, deber&aacute; salir o bloquear
						el acceso a los sistemas en los que est&eacute; trabajando.
					</li>
					<li>
						Informar al administrador del sistema sobre cualquier irregularidad o acceso al cual considere que usted no
						deber&iacute;a tener permiso por efectos de proceso o de confidencialidad de la informaci&oacute;n.
					</li>
					<li>
						Cambiar las contrase&ntilde;as frecuentemente y de forma segura, es decir dif&iacute;ciles de descifrar.
					</li>
					<li>
						Debe ser precavido con la protecci&oacute;n (custodia) de las contrase&ntilde;as. No deben dejarlas escritas
						o visibles a otras personas.
					</li>
					<li>
						Si requiere cambio de contrase&ntilde;a, en caso de olvido o bloqueo, debe solicitarlo personalmente al
						&aacute;rea de sistemas. Ning&uacute;n usuario est&aacute; autorizado a pedir cambios de contrase&ntilde;a a
						nombre de otro usuario.
					</li>
					<li>
						Cambiar la contrase&ntilde;a, cuando ha sido revelada o cambiada por terceros por motivos de fuerza mayor,
						tan pronto se haya superado la situaci&oacute;n de excepci&oacute;n.
					</li>
				</ol>
				<br></br>
				<ol type="A" start={2}>
					<li>
						<strong>
							Responsabilidades de los Jefes inmediatos o responsables de usuarios, con respecto a la asignaci&oacute;n
							de usuarios y contrase&ntilde;as de sus colaboradores:
						</strong>
					</li>
				</ol>
				<ol>
					<li>
						Los jefes inmediatos deben asegurarse que cada colaborador tenga las autorizaciones requeridas de acuerdo a
						sus funciones. As&iacute; mismo son responsables de que el colaborador deje de tener dichas autorizaciones
						cuando se produce alg&uacute;n cambio en sus funciones.
					</li>
					<li>
						En caso de reemplazos por traslado, vacaciones, incapacidad o casos especiales, el jefe inmediato debe
						solicitar un nuevo perfil de usuario para la persona que asumir&aacute; el cargo.
					</li>
					<li>Por ning&uacute;n motivo se debe continuar usando el perfil del usuario ausente.</li>
					<li>
						Los Jefes inmediatos deben autorizar a un colaborador para que pueda acceder a los sistemas del negocio
						desde fuera de la oficina, en casos excepcionales. (En la medida de lo posible Sistemas evaluar&aacute; la
						factibilidad de lograr esto, dependiendo del tipo de informaci&oacute;n a revisar.)
					</li>
					<li>Supervisar el cumplimiento de las pol&iacute;ticas y de las buenas pr&aacute;cticas asociadas.</li>
				</ol>
				<br></br>
				<ol type="A" start={3}>
					<li>
						<strong></strong> <strong>Responsabilidades de Tecnolog&iacute;a:</strong>
					</li>
				</ol>
				<ol>
					<li>
						Los administradores de seguridad (plataformas, aplicativos o servicios) del &aacute;rea de
						Tecnolog&iacute;a, deben garantizar la administraci&oacute;n, control y auditor&iacute;a de
						asignaci&oacute;n y uso de perfiles de usuario y suministrar registros de utilizaci&oacute;n de los mismos
						en caso de ser necesario.
					</li>
					<li>
						Los administradores de seguridad deben mantener en total reserva su contrase&ntilde;a. S&oacute;lo su jefe
						inmediato debe tener copia de &eacute;sta en sobre cerrado y lugar seguro. Si la contrase&ntilde;a es
						revelada por motivos de fuerza mayor debe ser cambiada inmediatamente superada la situaci&oacute;n.
					</li>
					<li>
						Establecer los est&aacute;ndares para definiciones de nombres de usuarios y forma de registrar la
						informaci&oacute;n b&aacute;sica de los usuarios, con el fin de unificar mantener y facilitar la
						administraci&oacute;n.
					</li>
					<li>
						Emitir informes de incidentes en asuntos de contrase&ntilde;as a las direcciones de las &aacute;reas
						afectadas con copia a la gerencia de Sistemas.
					</li>
					<li>Informar a los jefes inmediatos en caso de detectar pr&eacute;stamos de contrase&ntilde;as.</li>
					<li>
						En caso de terceros que presten soporte, se debe reportar y llevar un estricto control de los accesos a los
						sistemas, en especial los de producci&oacute;n con previa firma de contrato de confidencialidad.
					</li>
					<li>Mantener en total reserva los usuarios y contrase&ntilde;as.</li>
					<li>Configurar (activar) un protector de pantalla con contrase&ntilde;a en los PC de los usuarios.</li>
					<li>
						Reportar Semanalmente a la Gerencia de Sistemas las actividades sobresalientes, problemas, errores, fallas
						en sistemas, Bases de Datos, comunicaciones, seguridad, etc. Enviar los reportes de incidencia copiando
						siempre a la gerencia de sistemas.
					</li>
					<li>
						Llevar una bit&aacute;cora de eventos por Hardware, Software y Comunicaciones diaria que ser&aacute;
						revisada en cualquier momento por la Gerencia de Sistemas.
					</li>
				</ol>
				<br></br>
				<ol type="A" start={4}>
					<li>
						<strong></strong> <strong>Responsables de su aplicaci&oacute;n</strong>
					</li>
				</ol>
				<p>
					Son responsables de su aplicaci&oacute;n todos los colaboradores de CONNECT ASSISTANCE y aquellos terceros a
					quienes se les ha autorizado el acceso a uno o varios aplicativos y servicios de CONNECT ASSISTANCE, por medio
					de la asignaci&oacute;n de un usuario y contrase&ntilde;a respectiva.
				</p>
				<p>
					Basados en el establecimiento de estas pol&iacute;ticas y los valores Corporativos de CONNECT ASSISTANCE, es
					expectativa de la compa&ntilde;&iacute;a, el apoyo y cumplimiento de la presente pol&iacute;tica por cada una
					de las personas definidas como responsables aqu&iacute;, tal como se especifica tambi&eacute;n en los
					contratos de trabajo con los colaboradores y los acuerdos de confidencialidad con terceros.
				</p>
				<p>
					Dentro de las medidas de seguridad existentes se establece que el cambio de contrase&ntilde;as en Servidores,
					as&iacute; como equipos de comunicaci&oacute;n, telefon&iacute;a y Seguridad, competen a la Gerencia de
					Sistemas, por ning&uacute;n motivo se permitir&aacute; el cambio de contrase&ntilde;as a alguien distinto,
					dichas contrase&ntilde;as ser&aacute;n resguardadas por la gerencia de sistemas.
				</p>
				<p>
					<strong>III. BUENAS PR&Aacute;CTICAS EN EL USO DE ACCESO REMOTO</strong>
				</p>
				<ol>
					<li>Indicar a que servicio espec&iacute;fico est&aacute; autorizado ingresar.</li>
					<li>
						El &aacute;rea de Tecnolog&iacute;a debe garantizar la administraci&oacute;n, control y auditor&iacute;a del
						buen uso de este servicio y suministrar logs de uso del mismo en caso de ser requerido por una acci&oacute;n
						de auditor&iacute;a. As&iacute; mismo los Administradores de plataformas de los servicios autorizados a
						acceder v&iacute;a acceso remoto deben mantener logs del sistema a los accesos de este tipo sobre los
						sistemas del negocio.
					</li>
					<li>
						La administraci&oacute;n de usuarios en tecnolog&iacute;a debe garantizar cambio frecuente de
						contrase&ntilde;a, perfil diferente al de esta, claves complejas y desactivaci&oacute;n por no uso.
					</li>
					<li>
						Las personas de terceros autorizadas a recibir este servicio deber&aacute;n diligenciar el acuerdo de buen
						uso del servicio de acceso remoto para terceros amparado bajo las cl&aacute;usulas de confidencialidad
						pactadas en el contrato vigente con estas firmas. En el formato se debe indicar a que servicio
						espec&iacute;fico est&aacute; autorizado ingresar.
					</li>
					<li>
						En caso de que este servicio deba ser retirado a un usuario que ya lo percibe por traslado, cambio de
						funciones, retiro, etc... El Gerente de &aacute;rea debe informar la novedad a tecnolog&iacute;a para
						deshabilitar el servicio.
					</li>
					<li>
						Si termina la vigencia de un contrato con un tercero, de inmediato el jefe responsable del servicio debe
						enviar la novedad a Sistemas.
					</li>
					<li>
						CONNECT ASSISTANCE a nombre de cada gerente aprobador determinar&aacute; si el pago de llamada
						(tr&aacute;fico telef&oacute;nico) durante el uso del servicio se carga al usuario o lo asume la
						compa&ntilde;&iacute;a.
					</li>
				</ol>
				<br></br>
				<ol type="I" start={4}>
					<li>
						<strong> POL&Iacute;TICAS EN EL ALMACENAMIENTO DE ARCHIVOS CON INFORMACI&Oacute;N DEL NEGOCIO</strong>
					</li>
				</ol>
				<ol>
					<li>
						Toda carpeta compartida para almacenar informaci&oacute;n de un &aacute;rea en particular o un grupo de
						trabajo y/o proyecto debe tener un due&ntilde;o quien ser&aacute; el responsable por solicitar este servicio
						al &aacute;rea de tecnolog&iacute;a. La lista de usuarios autorizados a acceder esta carpeta y el rol de
						acceso (Lectura, escritura, etc...) debe ser solicitado al &aacute;rea de tecnolog&iacute;a indicando la
						novedad: Incorporar un usuario, cambiarle su rol sobre la carpeta o retirarle el acceso a la misma. Esto es
						muy importante mantener actualizado ante la rotaci&oacute;n de personal por ingresos, traslados o retiros.
					</li>
					<li>
						Sistemas debe garantizar que no existan carpetas de dominio p&uacute;blico. S&oacute;lo las aprobadas por la
						gerencia de Sistemas.
					</li>
					<li>
						Todo archivo (XLS, XLSX, DOC, DOCX, ZIP, PPT, ETC...) almacenado en disco interno de su Pc y/o en carpetas
						compartidas de la red y que contenga informaci&oacute;n sensible del negocio debe ser almacenado con
						contrase&ntilde;a para evitar accesos no autorizados o fuga de informaci&oacute;n y entregar dicha clave a
						la gerencia de Sistemas.
					</li>
					<li>
						A todo archivo almacenado en carpetas compartidas de red, el &aacute;rea de sistemas le tomar&aacute; copia
						de seguridad de acuerdo a las pol&iacute;ticas definidas en la toma de BACKUPS.
					</li>
					<li>
						Todo archivo que repose sin ser modificado por un espacio mayor a 3 meses en las carpetas compartidas
						ser&aacute; respaldado por el &aacute;rea de tecnolog&iacute;a al dispositivo instalado en la filial
						(DVD&rsquo;S, CD&rsquo;S; CINTAS) y eliminado del acceso compartido para optimizar el uso de los recursos.
					</li>
					<li>
						Los administradores de servidores de archivos deben guardar total confidencialidad acerca de la
						informaci&oacute;n almacenada en los discos de red de los usuarios y en ning&uacute;n caso podr&aacute;n
						acceder a esta informaci&oacute;n, salvo previa consulta y aprobaci&oacute;n de la Gerencia. El usuario no
						est&aacute; autorizado para compartir carpetas de su disco interno de su PC.
					</li>
					<li>
						El usuario de Pc debe coordinar con Sistemas la copia de seguridad que requiera de sus archivos locales para
						garantizar la disponibilidad de esta informaci&oacute;n ante da&ntilde;o y o robo de equipos en especial
						port&aacute;tiles.
					</li>
					<li>
						El usuario que emplee el servicio de transferencia de archivos SFTP, para enviar informaci&oacute;n sensible
						y de gran tama&ntilde;o debe comprimir, empaquetar y colocar contrase&ntilde;a al documento d&aacute;ndola a
						conocer exclusivamente al destinatario. El &aacute;rea de tecnolog&iacute;a debe eliminar del SFTP archivos
						con m&aacute;s de una (1) semana de vigencia para mantener la confidencialidad de la informaci&oacute;n,
						as&iacute; mismo la asignaci&oacute;n de usuarios y claves para acceder a los servicios de SFTP debe
						garantizar que no se usen &ldquo;contrase&ntilde;as obvias&rdquo;.
					</li>
					<li>
						En ning&uacute;n disco local o de red el usuario podr&aacute; guardar y/o descargar informaci&oacute;n que
						no corresponda a las labores inherentes a su cargo. En especial Software y/o archivos bajados de Internet
						como mp3, juegos, fotograf&iacute;as, videos etc... que incluso pueden contener peligrosos virus. El
						&aacute;rea de Sistemas podr&aacute; auditar en cualquier momento el cumplimiento de esta medida.
					</li>
				</ol>
				<br></br>
				<ol type="I" start={5}>
					<li>
						<strong>
							POL&Iacute;TICA DE BUEN USO DE PCS, MEDIOS DE ALMACENAMIENTO AUXILIAR Y MEDIOS MAGN&Eacute;TICOS
						</strong>
					</li>
				</ol>
				<ol>
					<li>
						Las computadoras como activos de la compa&ntilde;&iacute;a que contienen informaci&oacute;n del negocio
						deben tener asignado un responsable quien debe velar por el buen uso del recurso y la confidencialidad de la
						informaci&oacute;n all&iacute; contenida y/o compartida. Dispositivos de almacenamiento auxiliares como
						c&aacute;maras digitales, celulares, discos removibles, perif&eacute;ricos de almacenamiento USB,
						Computadoras de mano o agendas electr&oacute;nicas no se encuentran autorizadas para el ingreso a la
						compa&ntilde;&iacute;a salvo autorizaci&oacute;n expresa y escrita de su Jefe Inmediato.
					</li>
					<li>
						NO est&aacute; autorizado salvar informaci&oacute;n de la compa&ntilde;&iacute;a en equipos de propiedad de
						los colaboradores o visitantes. La informaci&oacute;n es propiedad de CONNECT ASSISTANCE solo puede ser
						almacenada en dispositivos autorizados por la misma. Casos particulares deben ser revisados con la Gerencia
						de Sistemas.
					</li>
					<li>
						El Jefe de &aacute;rea es responsable de asignar el due&ntilde;o de la m&aacute;quina que deben ser
						solicitadas al &aacute;rea de Sistemas con la relaci&oacute;n del SW que requiere el puesto de trabajo y las
						debidas aprobaciones. Sistemas configurar&aacute; el Hardware que sea necesario y que cumpla con las
						funciones asignadas a esa m&aacute;quina. Dispositivos de almacenamiento auxiliares por defecto no se
						deber&aacute;n habilitar.
					</li>
					<li>
						El contenido de la informaci&oacute;n almacenada por el usuario en la PC o dispositivos auxiliares asignados
						bajo su responsabilidad debe ser exclusivamente de las labores inherentes a su cargo.
					</li>
					<li>
						Ning&uacute;n usuario podr&aacute; retirar y/o enviar informaci&oacute;n de CONNECT ASSISTANCE en medios de
						almacenamiento auxiliares o magn&eacute;ticos, escritos y/o servicios de mensajer&iacute;a o transferencia
						electr&oacute;nica sin estar debidamente autorizado por escrito por su Jefe Directo y con copia a la
						Gerencia de Sistemas.
					</li>
					<li>
						Tecnolog&iacute;a debe entregar los PC&acute;S y en especial los port&aacute;tiles con las claves de
						prendido, de disco duro y del protector de pantalla activadas para garantizar la confidencialidad de la
						informaci&oacute;n en caso de perdida y/o robo. Debe el usuario activar su contrase&ntilde;a para el
						refrescador de pantalla para cuando se ausente de su puesto de trabajo por lapsos peque&ntilde;os de tiempo.
						Para ausencias prolongadas o salidas de oficina el PC debe quedar apagado.
					</li>
					<li>
						El retiro de port&aacute;tiles de las instalaciones debe obedecer exclusivamente a labores inherentes al
						cargo y con la debida autorizaci&oacute;n de su jefe inmediato y siempre que exista un respaldo de su
						informaci&oacute;n en las instalaciones de la Empresa.
					</li>
					<li>
						El uso de las unidades quemadoras de CD / DVD no ser&aacute; masivo y debe estar centralizado en lo posible
						en las &aacute;reas de Sistemas. El usuario que requiera de este servicio espor&aacute;dicamente
						deber&aacute; ser aprobado por su Jefe directo que garantice que la informaci&oacute;n a grabar en CD / DVD
						est&aacute; debidamente autorizada.
					</li>
					<li>
						El &aacute;rea de Tecnolog&iacute;a debe ejercer estricto control en el uso de medios que permitan la
						reproducci&oacute;n de informaci&oacute;n (grabador CD, DVD, Cinta, etc&hellip;).
					</li>
					<li>
						El &aacute;rea de sistemas debe asegurarse tener desactivada toda unidad de disquetes, USB o CD / DVD a
						excepci&oacute;n de los puestos en que los usuarios por procesos requieran de este dispositivo previa
						solicitud del Gerente Responsable.
					</li>
					<li>
						Sistemas debe velar por que el antivirus este activo en toda Computadora y que se encuentre actualizado. Si
						el usuario detecta que no est&aacute; actualizado o este opera mal debe reportarlo de inmediato.
					</li>
					<li>
						No est&aacute; permitido que un tercero conecte su PC / Laptop a la red corporativa de CONNECT ASSISTANCE,
						por cualquiera de los medios disponibles como: la red LAN Ethernet, Radiofrecuencia, Wireless, Red
						m&oacute;vil, etc. En caso de ser estrictamente necesario esta actividad deber&aacute; ser coordinada y
						realizada con el &aacute;rea de Sistemas para definir y controlar este acceso as&iacute; como el estado de
						virus del Pc del visitante. Esta norma aplica de Igual forma para equipos de propiedad de los empleados.
					</li>
					<li>
						El &aacute;rea de seguridad de instalaciones debe registrar el ingreso y retiro de equipos de c&oacute;mputo
						de visitantes o de empleados indic&aacute;ndoles a sus portadores la norma anterior.
					</li>
					<li>
						Todo retiro de Pc&acute;s, reportes, medios magn&eacute;ticos, etc... deben ser autorizados por escrito por
						su jefe inmediato y las &aacute;reas de sistemas y administrativa, explicando las razones del retiro y
						registr&aacute;ndolo en la bit&aacute;cora.
					</li>
				</ol>
				<br></br>
				<ol type="I" start={6}>
					<li>
						<strong> POL&Iacute;TICAS EN EL USO DE LAS IMPRESORAS</strong>
					</li>
				</ol>
				<ol>
					<li>
						Toda funcionario de CONNECT ASSISTANCE debe comprometerse con la tendencia del &ldquo;0 papel&rdquo;, es
						decir, que &eacute;l envi&oacute; de documentos a imprimir debe ser absolutamente justificado.
					</li>
					<li>
						Todo funcionario de CONNECT ASSISTANCE debe comprometerse con la confidencialidad que se debe guardar con la
						informaci&oacute;n del negocio evitando su exposici&oacute;n y/o fuga en los sitios de impresi&oacute;n.
					</li>
					<li>
						Todo archivo enviado a la impresora que contenga informaci&oacute;n sensible del negocio debe ser Retirado
						de la bandeja de salida de forma inmediata por el usuario due&ntilde;o del reporte.
					</li>
					<li>
						El &aacute;rea de Sistemas velar&aacute; por el buen uso del recurso; cuya responsabilidad principal
						ser&aacute; recolectar al fin del d&iacute;a las impresiones que han sido abandonadas en la m&aacute;quina
						para efectuar una Auditor&iacute;a y generar un reporte al Gerente de Sistemas con en n&uacute;mero de hojas
						abandonadas, el tipo de Informaci&oacute;n impresa y pasar esta papeler&iacute;a a destrucci&oacute;n.
					</li>
					<li>
						Los archivos que env&iacute;e el usuario a impresi&oacute;n deben contener exclusivamente informaci&oacute;n
						relacionada con labores inherentes a su cargo.
					</li>
					<li>
						Tecnolog&iacute;a deber&aacute; garantizar a trav&eacute;s de estad&iacute;sticas el uso de este recurso y
						mediante auditor&iacute;as peri&oacute;dicas, el buen uso del mismo.
					</li>
				</ol>
				<br></br>
				<ol type="I" start={8}>
					<strong>VII. BUENAS PR&Aacute;CTICAS EN EL USO DE INTERNET</strong>
				</ol>
				<ol>
					<li>
						El acceso a Internet es un servicio controlado, autorizado y auditado y s&oacute;lo tendr&aacute;n acceso a
						los servicios quienes a trav&eacute;s de solicitud escrita indicando el uso que se dar&aacute; a esta
						herramienta y bajo la responsabilidad del Jefe inmediato (primer aprobador) y del Gerente del &aacute;rea
						(segundo Aprobador) obtengan la correspondiente autorizaci&oacute;n.
					</li>
					<li>
						Es responsabilidad del usuario NO difundir ni facilitar su usuario y contrase&ntilde;a de red a otra
						persona. Esta clave es personal e intransferible.
					</li>
					<li>
						Es responsabilidad del usuario utilizar los servicios de forma racional y para uso exclusivo de las labores
						inherentes a su cargo.
					</li>
					<li>
						La informaci&oacute;n consultada en cualquier horario de trabajo a trav&eacute;s de Internet debe apoyar
						directamente las funciones relacionadas con el campo de responsabilidad laboral del usuario y o servir como
						herramienta para desempe&ntilde;ar sus funciones.
					</li>
					<li>
						Es responsabilidad del usuario verificar que siempre est&eacute; activo el antivirus y de revisar la
						Informaci&oacute;n que baje de Internet a su m&aacute;quina a trav&eacute;s del correo o del navegador antes
						de ser abierta y/o distribuida.
					</li>
					<li>
						Es responsabilidad del usuario NO difundir informaci&oacute;n que NO apoye directamente actividades
						laborales.
					</li>
					<li>Es responsabilidad del usuario NO navegar por sitios no deseados o de dudosa calidad.</li>
					<li>
						Es responsabilidad del usuario consultar al &aacute;rea de Sistemas en cualquier actividad que involucre
						bajar aplicaciones freeware, shareware, demos y/o software licenciados para pruebas, evaluaciones,
						demostraciones. Cualquier instalaci&oacute;n de este tipo sin el visto bueno mencionado, no est&aacute;
						autorizado debido a que las leyes que hoy rigen el licenciamiento de software son de estricto cumplimiento
						por parte de la compa&ntilde;&iacute;a. cualquier acci&oacute;n de este tipo que sea emprendida contra la
						empresa por esta condici&oacute;n mencionada ser&aacute; responsabilidad del usuario.
					</li>
					<li>
						Es responsabilidad del usuario reportar de inmediato al &aacute;rea de sistemas cualquier anomal&iacute;a
						que detecte en el uso de esta herramienta.
					</li>
					<li>
						Es responsabilidad del usuario cuando se retire por un per&iacute;odo de tiempo considerable de su puesto de
						trabajo, estando conectado a la red, desconectar la sesi&oacute;n con el fin de evitar accesos de personas
						no autorizadas.
					</li>
					<li>
						Es responsabilidad del &aacute;rea de sistemas brindar la administraci&oacute;n, control y auditor&iacute;a
						de los servicios que preste con relaci&oacute;n a Internet as&iacute; como de emitir reportes del uso que
						las personas autorizadas del &aacute;rea le est&eacute;n dando a estas herramientas registrando
						b&aacute;sicamente: tiempo de uso, p&aacute;ginas visitadas, etc... con el fin de tomar las acciones
						necesarias para garantizar la seguridad, buen uso del servicio y el mejor desempe&ntilde;o de los recursos.
					</li>
					<li>
						En cualquier momento la Gerencia de Sistemas podr&aacute; auditar cualquier equipo de la Empresa para
						verificar que los citados puntos anteriores no sean violados
					</li>
				</ol>
				<br></br>
				<ol type="I" start={7}>
					<strong>VII. SANCIONES</strong>
				</ol>
				<p>
					A continuaci&oacute;n se detalla una tabla de sanciones por incumplimiento de las normas definidas en este
					documento.
				</p>
				<p>
					<strong>ACTIVIDAD ILICITA</strong>
				</p>
				<p>
					<strong>SANCI&Oacute;N</strong>
				</p>
				<p>
					<strong>Primera Vez</strong>
				</p>
				<p>
					<strong>Reincidencia</strong>
				</p>
				<ol>
					<li>Utilizar cuentas de acceso a RED / Correo Electr&oacute;nico / Sistemas diferentes a las propias.</li>
					<li>
						Se enviar&aacute; un memor&aacute;ndum a los usuarios implicados en esta actividad, al que uso los datos de
						acceso y al que comparti&oacute; estos datos que son car&aacute;cter personal. Este documento pasar&aacute;
						al FILE del personal.
					</li>
					<li>
						Un segundo memor&aacute;ndum al usuario reincidente, con una llamada de atenci&oacute;n advirtiendo que una
						tercera falta implica el despido de la empresa.
					</li>
					<li>Instalaci&oacute;n de software sin autorizaci&oacute;n previa.</li>
					<li>
						Al usuario se le sancionar&aacute; con un memor&aacute;ndum. Este documento se adjuntar&aacute; al FILE del
						personal.
					</li>
					<li>
						Un segundo memor&aacute;ndum al usuario reincidente, con una llamada de atenci&oacute;n advirtiendo que una
						tercera falta implica el despido de la empresa.
					</li>
					<li>
						Instalaci&oacute;n / ejecuci&oacute;n de programas que intenten obtener informaci&oacute;n y privilegios de
						acceso al equipo, sistema, correo.
					</li>
					<li>
						Se solicitar&aacute; a la Jefatura el despido inmediato del usuario que cometi&oacute; la falta. Sistemas TC
						deber&aacute; hacer un reporte sobre la incidencia detectada.
					</li>
					<li>Cambio en la configuraci&oacute;n en los equipos asignados.</li>
					<li>Se har&aacute; una llamada de atenci&oacute;n verbal sobre la falta cometida.</li>
					<li>Se emitir&aacute; un memor&aacute;ndum al usuario que cometi&oacute; la falta.</li>
					<li>
						Env&iacute;o de correos de car&aacute;cter publicitario, propaganda que atenten con la integridad
						f&iacute;sica o moral de las personas.
					</li>
					<li>
						Al usuario se le sancionar&aacute; con un memor&aacute;ndum. Este documento se adjuntar&aacute; al FILE del
						personal.
					</li>
					<li>
						Un segundo memor&aacute;ndum al usuario reincidente, con una llamada de atenci&oacute;n advirtiendo que una
						tercera falta implica el despido de la empresa.
					</li>
					<li>
						Utilizaci&oacute;n de los recursos con fines de Ocio / Esparcimiento (acceso a internet, m&uacute;sica,
						juegos, etc.)
					</li>
					<li>Se har&aacute; una llamada de atenci&oacute;n verbal sobre la falta cometida.</li>
					<li>Se emitir&aacute; un memor&aacute;ndum al usuario que cometi&oacute; la falta.</li>
					<li>Uso de las impresoras para temas distintos al laboral.</li>
					<li>Se har&aacute; una llamada de atenci&oacute;n verbal sobre la falta cometida.</li>
					<li>Se emitir&aacute; un memor&aacute;ndum al usuario que cometi&oacute; la falta.</li>
					<li>Intentos de copia de informaci&oacute;n sobre medios &oacute;pticos, unidades extra&iacute;bles, etc.</li>
					<li>
						Al usuario se le sancionar&aacute; con un memor&aacute;ndum. Este documento se adjuntar&aacute; al FILE del
						personal.
					</li>
					<li>
						Un segundo memor&aacute;ndum al usuario reincidente, con una llamada de atenci&oacute;n advirtiendo que una
						tercera falta implica el despido de la empresa.
					</li>
					<li>Retir&oacute; de activos de la empresa sin autorizaci&oacute;n de la Gerencia.</li>
					<li>Si se comprueba hurto de activos se pedir&aacute; el retiro inmediato de la empresa.</li>
				</ol>
				<p>
					<span>&nbsp;</span>
				</p>
			</div>
		</div>
	);
};
export default PoliticasSeguridadInformatica;
