import React from 'react';

export interface AppContext {
	showStatus: boolean;
	setShowStatus: (currentUrl: boolean) => void;
	setBranchStatus: (currentUrl: string) => void;
	branch: string;
	identify: boolean;
	setIdentifyStatus: (currentUrl: boolean) => void;
}

export const URL_DEFAULT_VALUE = {
	showStatus: true,
	setShowStatus: () => {},
	setBranchStatus: () => {},
	branch: 'co',
	identify: false,
	setIdentifyStatus: () => {},
};

export const AppContext = React.createContext<AppContext>(URL_DEFAULT_VALUE);
