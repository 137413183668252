import React, { useContext, useEffect, useState } from 'react';
import '../perfil-vehiculo/perfil-vehiculo.scss';
import './perfil-mascota.scss';
import OrangeButton from 'components/inputs/orange-button/orange-button';

import FunnelHeader from 'components/funnel/header/header';
import { makeStyles } from '@material-ui/core';
import FunnelFooterDesktop from 'components/funnel/footer/desktop/funnel-footer-desktop';
import FunnelFooterMobile from 'components/funnel/footer/mobile/funnel-footer-mobile';
import { AppContext } from 'helpers/Context';
import TextInput from 'components/inputs/textfield/textfield';
import { useTranslation } from 'react-i18next';
import LoadingDialog from 'helpers/LoadingDialog/loadingDialog';
import { useFormik } from 'formik';
import { GlobalContext } from 'context/GlobalState';
import { updateItems } from 'services/PerfilService';
import Toaster from 'helpers/Toaster';
import FunnelResumen from '../resumen/resumen';
import Modal from 'components/modal/modal';
import FooterLogos from 'components/footer-logos/footer-logos';
import logocompleto from 'images/svg/logo-completo.svg';
import powerBy from 'images/svg/powered-by.svg';
import InputFile from 'components/inputs/Input-file/Input-file';
import { uploadFile } from 'services/uploadFile';
import { pageEventFunnelStep, pageViewEvent } from 'helpers/dataLaye';
import { FunnelData } from 'interfaces/FunnelInterface';

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			marginTop: theme.spacing(0),
			width: '100%',
		},
		'& label.Mui-focused': {
			color: '#313B46',
		},
	},

	tablilla: {
		width: '252px',
		float: 'left',
		'@media (max-width: 767px)': {
			width: '100%',
		},
	},
	fullWidth: {
		width: '100%',
	},
}));

interface IFormInputs {
	petName: string;
	petBreed: string;
	petWeight: string;
	petAge: string;
	petVaccinationCard: any;
}

export default function FunnelPerfiMascota(props: any) {
	const { t } = useTranslation();
	const { setShowStatus, identify, setIdentifyStatus } = useContext(AppContext);
	const { funnel } = useContext(GlobalContext);
	const [isLoading, setIsLoading] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [resumenType, setResumenType] = useState('');
	const [fileURLs, setFileURLs] = useState('');
	const [funnelData] = useState<FunnelData>(funnel);
	const today = new Date();
	const minDate = `${today.getFullYear() - 30}-${
		today.getMonth() <= 10 ? '0' + today.getMonth() : today.getMonth()
	}-${today.getDate()}`;
	const maxDate = `${today.getFullYear()}-${
		today.getMonth() <= 10 ? '0' + today.getMonth() : today.getMonth()
	}-${today.getDate()}`;

	useEffect(() => {
		pageViewEvent('Asistencias Mascotas', 'https://planesasistencia.connect.com.co/mascota/agregar-mascota');
		pageEventFunnelStep('contratacion-asistencia', `web-asistencia-mascota`, 'paso-agregar-vehiculo');
		if (!identify) {
			setIdentifyStatus(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setShowStatus(false);
		return () => {
			setShowStatus(true);
		};
	}, [setShowStatus]);

	const showModal = (type: string) => {
		setResumenType(type);
		setModalOpen(true);
	};
	const hideModal = () => {
		setModalOpen(false);
	};
	const initialValues: IFormInputs = {
		petName:
			props.countPet === 1
				? funnel.subscriptionData.petName1
				: props.countVehiculo === 2
				? funnel.subscriptionData.petName2
				: props.countVehiculo === 3
				? funnel.subscriptionData.petName3
				: props.countVehiculo === 4
				? funnel.subscriptionData.petName4
				: funnel.subscriptionData.petName5,
		petBreed:
			props.countPet === 1
				? funnel.subscriptionData.petBreed1
				: props.countVehiculo === 2
				? funnel.subscriptionData.petBreed2
				: props.countVehiculo === 3
				? funnel.subscriptionData.petBreed3
				: props.countVehiculo === 4
				? funnel.subscriptionData.petBreed4
				: funnel.subscriptionData.petBreed5,
		petWeight:
			props.countPet === 1
				? funnel.subscriptionData.petWeight1
				: props.countVehiculo === 2
				? funnel.subscriptionData.petWeight2
				: props.countVehiculo === 3
				? funnel.subscriptionData.petWeight3
				: props.countVehiculo === 4
				? funnel.subscriptionData.petWeight4
				: funnel.subscriptionData.petWeight5,
		petAge:
			props.countPet === 1
				? funnel.subscriptionData.petAge1
				: props.countVehiculo === 2
				? funnel.subscriptionData.petAge2
				: props.countVehiculo === 3
				? funnel.subscriptionData.petAge3
				: props.countVehiculo === 4
				? funnel.subscriptionData.petAge4
				: funnel.subscriptionData.petAge5,
		petVaccinationCard:
			props.countPet === 1
				? funnel.subscriptionData.petVaccinationCard1
				: props.countVehiculo === 2
				? funnel.subscriptionData.petVaccinationCard2
				: props.countVehiculo === 3
				? funnel.subscriptionData.petVaccinationCard3
				: props.countVehiculo === 4
				? funnel.subscriptionData.petVaccinationCard4
				: funnel.subscriptionData.petVaccinationCard5,
	};

	const validate = (values: any) => {
		const errors: any = {};
		if (!values.petName) {
			errors.petName = 'Campo Requerido';
		}
		if (!values.petBreed) {
			errors.petBreed = 'Campo Requerido';
		}
		if (!values.petWeight) {
			errors.petWeight = 'Campo Requerido';
		} else if (!/^([0-9])*$/.test(values.petWeight)) {
			errors.petWeight = 'Debe ser un número';
		}
		if (!values.petAge) {
			errors.petAge = 'Campo Requerido';
		}

		console.log(errors);
		return errors;
	};

	const cargarArchivo = (data: any) => {
		formik.setFieldError('petVaccinationCard', '');
		setFileURLs(data);
	};

	const formik = useFormik({
		initialValues,
		validate,
		onSubmit: async (values) => {
			try {
				const key = props.countPet;
				const section = props.component.section;
				setIsLoading(true);
				if (fileURLs[0]) {
					const fileUpload = new FormData();
					fileUpload.append(`_id`, funnel.subscriptionData.mongoId);
					fileUpload.append(`indexProperty`, key);
					fileUpload.append(`file`, fileURLs[0]);
					await uploadFile(fileUpload);
				}
				const data = {
					_id: funnel.subscriptionData.mongoId,
					fields: {
						[`${section}Name${key}`]: formik.values.petName,
						[`${section}Breed${key}`]: formik.values.petBreed,
						[`${section}Weight${key}`]: formik.values.petWeight,
						[`${section}petBirthDate${key}`]: formik.values.petAge,
					},
				};
				const updateItem = await updateItems(data);
				if (updateItem.status && updateItem.data.status === false) {
					Toaster.error('Error');
					setIsLoading(false);
					return;
				}
				props.handleClick(formik.values, props.component);
			} catch (error) {
				console.log('error', error);
				Toaster.error('Error al subir el archivo.');
				setIsLoading(false);
			}
		},
	});

	const classes = useStyles();

	return (
		<div className="App">
			<LoadingDialog isLoading={isLoading}></LoadingDialog>
			<div className="App-header">
				<FunnelFooterMobile
					className="mobile"
					section="Tu pago ha sido procesado con éxito"
					linkTo="finalizar"
					showChevron={props.chevron}
					totalScreen={props.totalScreen}
					currentScreen={props.currentScreen}
					template={'primary'}
				/>
				<FunnelHeader progress="0" />
			</div>
			<div className="funnel__main">
				<div className="funnel__main__inner">
					<Modal show={modalOpen} handleClose={hideModal}>
						<p>{t(`resumen.${resumenType}.resumenTitulo`)}</p>
						{t(`resumen.${resumenType}.masDetalles`)}
					</Modal>
					<form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
						<div className="perfil-vehiculo-content funnel__cols">
							<div className="perfl-1-direction-column funnel__col funnel__col-1">
								<div className="perfil-vehiculo-left-content">
									<p className="text-charge">Tu pago ha sido procesado con éxito</p>
									<h1>Agrega tu mascota</h1>
									<p className="text-detail">Para finalizar agregue la información de la mascota que desea cubrir.</p>
									<div className="perfil-form-content-mascotas">
										<TextInput
											required
											name="petName"
											className={classes.tablilla}
											label={'Nombre de mascota'}
											onChange={formik.handleChange}
											value={formik.values.petName}
											errorinput={formik.errors.petName ? 1 : 0}
											errorlabel={formik.errors.petName}
										></TextInput>
										<TextInput
											required
											name="petBreed"
											className={classes.tablilla}
											label={'Raza de mascota'}
											onChange={formik.handleChange}
											value={formik.values.petBreed}
											errorinput={formik.errors.petBreed ? 1 : 0}
											errorlabel={formik.errors.petBreed}
										></TextInput>
										<TextInput
											required
											name="petWeight"
											className={classes.tablilla}
											label="Peso de mascota (in Kg)"
											onChange={formik.handleChange}
											value={formik.values.petWeight}
											errorinput={formik.errors.petWeight ? 1 : 0}
											errorlabel={formik.errors.petWeight}
										></TextInput>
										<TextInput
											required
											type="date"
											name="petAge"
											label="Fecha de nacimiento"
											className={classes.tablilla}
											InputProps={{
												inputProps: {
													min: minDate,
													max: maxDate,
												},
											}}
											onChange={formik.handleChange}
											value={formik.values.petAge}
											errorinput={formik.errors.petAge ? 1 : 0}
											errorlabel={formik.errors.petAge}
										></TextInput>
										<InputFile errorlabel={formik.errors.petVaccinationCard} setFileURLs={cargarArchivo}></InputFile>
										<OrangeButton className="perfil-vehiculo-orange-btn full-width mobile">
											{props.btnLabel}
										</OrangeButton>
										<div className="perfil-vehiculo-legal-content">
											<p>
												Una vez hayas añadido la información a tu perfil, tu suscripción quedará activa y lista para su
												uso luego de 72 horas.
												<br />
												<br />
												Si en el futuro necesitas cambiar o actualizar tu información, contáctanos y te ayudamos a
												realizar los cambios.
											</p>
										</div>
										<FooterLogos />
									</div>
								</div>
							</div>

							<div className="perfil-vehiculo-direction-column funnel__col funnel__col-2 ctr">
								<div className="desktop">
									<FunnelResumen
										show="desktop"
										data={funnel}
										pago={
											funnelData.step1.pago === 'mensual' ? funnelData.total.mensualPrice : funnelData.total.anualPrice
										}
										price={
											funnelData.step1.pago === 'mensual'
												? funnelData.total.mensualPrice - funnelData.step3.discountAmount
												: funnelData.total.anualPrice - funnelData.step3.discountAmount
										}
										discount={funnelData.step3.discountAmount}
										modalClick={showModal}
									/>
									<div className="container__logos">
										<img src={powerBy} alt="power by connect" />
										<img src={logocompleto} alt="Logo Connect" />
									</div>
								</div>
							</div>
						</div>
						<FunnelFooterDesktop
							className="desktop"
							section="Completar Perfil"
							linkTo="finalizar"
							btnLabel={props.btnLabel}
							totalScreen={props.totalScreen}
							currentScreen={props.currentScreen}
							component={props.component}
							handleClick={formik.handleSubmit}
							showChevron={props.chevron}
						/>
					</form>
				</div>
			</div>
		</div>
	);
}
