import React, { useEffect, useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import './footer.scss';
import instgram from '../../images/svg/instagram.svg';
import facebook from '../../images/svg/facebook.svg';
import poweredBy from '../../images/svg/powered-by-white.svg';
import segurosFalabellaLogo from 'images/svg/falabella-white-logo.svg';
import { AppContext } from '../../helpers/Context';
import { useTranslation } from 'react-i18next';
import { FunnelData } from 'interfaces/FunnelInterface';
import { GlobalContext, resetData } from 'context/GlobalState';

export default function Footer() {
	const { t, i18n } = useTranslation();

	let { search } = useLocation();
	const paramsString = decodeURIComponent(search);
	let params = new URLSearchParams(paramsString);
	const { funnel, setFunnel, reset } = useContext(GlobalContext);

	const [funnelData, setFunnelData] = useState<FunnelData>(funnel);

	useEffect(() => {
		i18n.changeLanguage('co');
	}, [i18n]);

	return (
		<AppContext.Consumer>
			{({ showStatus }) => {
				if (showStatus) {
					return (
						<footer>
							<div className="footer__inner">
								<div className="nav__cols">
									<nav>
										<h5>Ayuda</h5>
										<ul>
											<li>
												<a className="link--green" href={t('emailLink')}>
													{t('email')}
												</a>
											</li>
											<li>
												<a className="link--green" href={t('phoneLink')}>
													{t('phone')}
												</a>
											</li>
										</ul>
									</nav>
									<nav>
										<h5>Sobre Connect</h5>
										<ul>
											<li>
												<Link to="/sobre-nosotros">Más información</Link>
											</li>
											<li>
												<Link to="/politicas-seguridad-informatica">Política de Seguridad</Link>
											</li>
											<li>
												<Link to="/politicas-habeas-data">Política de Habeas Data</Link>
											</li>
										</ul>
									</nav>
									<nav>
										<h5>Términos y Condiciones</h5>
										<ul>
											<li>
												<a rel="noopener noreferrer" target="_blank" href={process.env.REACT_APP_TERMS_AUTO}>
													Términos y condiciones asistencia para autos
												</a>
											</li>
											<li>
												<a rel="noopener noreferrer" target="_blank" href={process.env.REACT_APP_TERMS_PETS}>
													Términos y condiciones asistencia para mascotas
												</a>
											</li>
										</ul>
									</nav>
									<nav>
										<h5>Tratamiento de datos</h5>
										<ul>
											<li>
												<a
													rel="noopener noreferrer"
													target="_blank"
													href="https://www.segurosfalabella.com.co/autorizacion-de-tratamiento-de-datos"
												>
													Autorización de tratamiento de datos
												</a>
											</li>
										</ul>
									</nav>
									<nav className="hidden">
										<h5>Síguenos</h5>
										<ul className="social">
											<li>
												<a href={`${t('instagram')}`} target="_blank" rel="noopener noreferrer">
													<img className="ft-social" src={instgram} alt="Instagram" />
												</a>
											</li>
											<li>
												<a href={`${t('facebook')}`} target="_blank" rel="noopener noreferrer">
													<img className="ft-social" src={facebook} alt="Facebook" />
												</a>
											</li>
										</ul>
									</nav>
									<nav>
										<div className="ft-copyright">
											© 2011 - 2021 Connect Assistance.
											<br /> Todos los derechos reservados. | Política de Privacidad
										</div>
										<div className="logos__dark__container">
											<img src={segurosFalabellaLogo} alt="Logo Falabella" />
											<img className="logo__connect" src={poweredBy} alt="Logo Connect" />
										</div>
									</nav>
								</div>
							</div>
						</footer>
					);
				}
				return '';
			}}
		</AppContext.Consumer>
	);
}
