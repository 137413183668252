import React, { ReactNode } from 'react';
import './Popup-exist-porcess.scss';
import CloseBtn from 'images/svg/close-btn.svg';

type TypeState = 'warning' | 'error' | 'successful' | 'info';
interface IPropsPopupExistProcess {
	type: TypeState;
	title: string;
	message: string;
	labelButton?: string;
	labelButtonCancel?: string;
	closePopUp: () => void;
	exitProcess?: () => void;
	children?: ReactNode;
	iconIsHidden?: boolean;
	reverseCallToAction?: boolean;
	isLoading?: boolean;
	terms?: string;
}
export const PopupExistProcess = (props: IPropsPopupExistProcess) => {
	return (
		<div className="pop__up__overlay">
			<div className="pop__up__container">
				<div className={`popup__exist_porcess ${props.type}`}>
					{!props.labelButtonCancel && (
						<button className="popup__exist_porcess__btn__close" onClick={props.exitProcess}>
							<img src={CloseBtn} alt="Cerrar Modal" />
						</button>
					)}
					<h5>{props.title}</h5>
					{props.children ? (
						props.children
					) : (
						<p className="popup__exist_porcess__text" dangerouslySetInnerHTML={{ __html: props.message }}></p>
					)}

					<div className={`popup__exist_porcess__container__btns ${!props.labelButtonCancel ? 'margin--left' : ''}`}>
						{props.labelButtonCancel && (
							<button className={`popup__exist_porcess__btn ${props.type} ghost`} onClick={props.exitProcess}>
								{props.labelButtonCancel}
							</button>
						)}
						<button
							className={`popup__exist_porcess__btn ${props.type}`}
							onClick={!props.isLoading ? props.closePopUp : () => ''}
						>
							{!props.isLoading ? props.labelButton : <div className="loader small"></div>}
						</button>
					</div>
					{props.terms && (
						<a
							className="popup__exist_porcess__container__terms"
							href={props.terms}
							rel="noopener noreferrer"
							target="_blank"
						>
							Aplican T&C
						</a>
					)}
				</div>
			</div>
		</div>
	);
};
