import React, { ReactNode } from 'react';

import './radio-button.scss';

interface IProps {
	children?: ReactNode;
	className?: string;
	price?: string;
	label?: string;
	pricePerc?: number;
	discount?: string;
	checkedStatus?: boolean;
	handleClick?: any;
}

const RadioButton: React.FC<IProps> = ({
	children,
	className,
	label,
	price,
	pricePerc,
	discount,
	handleClick,
}) => (
	<div className={`radio-button ${className}`} onClick={handleClick}>
		<div className="radio">
			<div className={`radio-indicator ${className}`}></div>
			<div className="radio-label">{label}</div>
		</div>
		{price ? <div className="radio-qty ">${price}</div> : ''}
		{pricePerc ? <div className="radio-discount">{Math.floor(pricePerc)}% de descuento</div> : ''}
		{discount ? <div className="radio-discount">${discount} cupón</div> : ''}
		{ price&& <div className="radio-discount iva">IVA incluido</div>}
		{children}
	</div>
);

export default RadioButton;
