import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import './header-desktop.scss';
import { getFunnelUrl } from '../../../helpers/FunnelRedirection';
import segurosFalabellaLogo from '../../../images/svg/seguros-falabella-logo.svg';
import closeBtn from '../../../images/svg/close-btn.svg';
import { useTranslation } from 'react-i18next';
import useHideOnScrolled from 'helpers/UseHideScroll';
import { GlobalContext } from 'context/GlobalState';
import { FunnelData } from 'interfaces/FunnelInterface';
import { IProductType } from 'interfaces/producti-type.inteface';
import { NavHashLink } from 'react-router-hash-link';
import { getPromoActive } from 'helpers/PromoActived';

export default function HeaderDesktop() {
	const hidden = useHideOnScrolled(50);
	const { t } = useTranslation();
	const linkTo = getFunnelUrl();
	const { funnel, setFunnel, reset } = useContext(GlobalContext);
	const [funnelData, setFunnelData] = useState<FunnelData>(funnel);
	const [isNavEmergencyVisible, setIsNavEmergencyVisible] = useState(true);
	const emergenciaClose = () => {
		setIsNavEmergencyVisible(false);
	};
	const setPlan = (productType: IProductType) => {
		funnelData.step1.plan = productType === 'autos' ? '1-0-0-A-co-fa' : '0-1-0-A-co-fa';
		funnelData.step1.productType = productType;
		setFunnelData(funnelData);
		setFunnel(funnelData);
	};
	return (
		<div className="header__container__desktop desktop">
			{isNavEmergencyVisible && (
				<div className={`emergencia__desktop toast ${hidden && !getPromoActive() ? 'on--scroll' : ''}`}>
					<div className="emergencia__desktop__title">Ya tienes tu asistencia, solicita ayuda aquí</div>
					<div className="emergencia__desktop__links">
						<a href={t('phoneLink')} className="icon-btn phone">
							Chat
						</a>
						<a target="_blank" rel="noopener noreferrer" href={t('phoneLinkWhatsapp')} className="icon-btn chat">
							Chat
						</a>
						<div className="link close" onClick={emergenciaClose}>
							<img src={closeBtn} alt="close" />
						</div>
					</div>
				</div>
			)}
			<header className="desktop">
				<Link
					to={{ pathname: 'https://www.segurosfalabella.com.co/asistencias' }}
					className="logo header__col left"
					target="_top"
				>
					<img src={segurosFalabellaLogo} alt="Connect Assistance" />
				</Link>
				<div className="header__col right">
					<Link className="button__link" to={'/servicios-shared/mascota'} onClick={() => setPlan('mascotas')}>
						Mascotas
					</Link>
					<Link className="button__link" to={'/servicios-shared/auto'} onClick={() => setPlan('autos')}>
						Asistencia para autos
					</Link>
					<NavHashLink className="button" to="/#servicios" scroll={(el) => el.scrollIntoView({ behavior: 'smooth' })}>
						Comprar plan
					</NavHashLink>
				</div>
			</header>
		</div>
	);
}
