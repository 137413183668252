import React from 'react';
import { Link } from 'react-router-dom';
import './subscribete.scss';
import PricingHelpers from 'helpers/Pricing';
import Incluye from 'components/Incluye/incluye';
import { pageEvent, pageEventFunnelStep } from 'helpers/dataLaye';
import logoAuto from 'images/services/logo-auto.svg';
import logoMascota from 'images/services/logo-mascota.svg';
interface Props {
	section?: string;
	linkTo: string;
	pricing: number;
	branch: string;
	data: any;
}

const ServiceSubscribete: React.FC<Props> = ({ data, linkTo, pricing, branch, section }) => {
	const setPageEvent = () => {
		if ( section === 'auto') {
			pageEventFunnelStep('contratacion-asistencia', `web-asistencia-carro`, 'paso-plan');
		} else {
			pageEventFunnelStep('contratacion-asistencia', `web-asistencia-mascota`, 'paso-plan');
		}
	};

	return (
		<div className="productos-buy-container">
			<div className="productos-buy-container-header">
				<img className="img" src={section === 'auto' ? logoAuto : logoMascota} alt="MDN" />
				<h3 className="productos-buy-container-header-h3">{data.infoHeader.title}</h3>
				<p>{data.infoHeader.message}</p>
			</div>
			<div className="productos-buy-content">
				<div className="productos-buy-content-padding top">
					<h3 className="productos-buy-content-h3">Consigue tu plan hoy</h3>
					<p className="productos-buy-content-info">Es fácil y toma solo minutos en completar.</p>
					<div className="productos-buy-content-line line"></div>
					<Incluye template="secondary" data={data.listLeftPlan}></Incluye>
					<div className="productos-buy-content-divider"></div>
					<div className="productos-buy-content-price">
						Planes desde <span>${PricingHelpers.formatPrice(pricing, branch, 2)}</span> /semestral.
					</div>

					<Link to={linkTo} className="button ctr" onClick={setPageEvent}>
						Comprar plan
					</Link>
				</div>
			</div>
		</div>
	);
};

export default ServiceSubscribete;
